<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{ this.$lang.FanCalculation.ReportBtn }}

            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
        </hero-bar>

        <div class="columns">
            <div class="column is-1" style="padding-left: 0.75rem; background-color: #FFFFFF; width: 10%; display: flex; min-height: 100vh; flex-direction: column;">
                <div style="padding-left: 1.5rem; padding-top: 1.4rem">
                    <verticalStep-bar />
                </div>
            </div>
            <div class="column">
                <embed :src="pdfsrc" type="application/pdf" width="100%" height="900px" />
            </div>
        </div>

        <b-notification :closable="false">
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </b-notification>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';

    export default {
        name: 'FanPdfPage',
        components: {
            VerticalStepBar
        },
        data() {
            return {
                isActive: true,
                isLoading: false,
                pdfsrc: ''
            }
        },
        mounted() {
            this.setCurrentDate();
            this.getFanPdf(this.$store.state.projectId);
            this.$store.state.activeStep = 5;
        },
        methods: {            
            getFanPdf(proId) {
                this.isLoading = true;
                api.panels.getFanPdf(this.$lang.Format.Lang, this.$store.state.memberId, proId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isLoading = false;
                        this.pdfsrc = process.env.VUE_APP_PDFURL + '/PdfFanReports' + '/Fan-Report' + proId + '.pdf';
                        console.log(this.pdfsrc);
                        if (this.$store.state.memberId == 'TestMember') {
                            this.pdfsrc += '#toolbar=0';
                        } else {
                            this.pdfsrc = this.pdfsrc.replace("#toolbar=0", "");
                        }
                    }
                });
            },            
            setCurrentDate() {
                var currDate = new Date();
                var day = String(currDate.getDate()).padStart(2, '0');
                var month = String(currDate.getMonth() + 1).padStart(2, '0');
                var year = currDate.getFullYear();
                this.currentDate = day + "/" + month + "/" + year;
                this.currentDate2 = year + "-" + month + "-" + day;
            },           
            previous() {
                this.$store.isPageOpenedFromStepBar = false;
                router.push('/fan_Calc');
            }
        },

    };
</script>