import axios from 'axios';
import helpers from '@/mixins/helpers';
import store from '@/store';
import { DialogProgrammatic as Dialog } from 'buefy';

axios.defaults.baseURL = process.env.VUE_APP_API_ROOT;

axios.interceptors.response.use(
	function (response) {
		if (response.status == 200) {
			helpers.methods.handleDates(response.data);
		}

		return response;
	},
	function (error) {
		if (error.response.status == 401) {
			store.commit('logout');

			Dialog.alert({
				title: 'Hata',
				message: error.response.data.error_description,
				confirmText: 'Tamam',
				type: 'is-danger',
				hasIcon: true,
				onConfirm: () => {
					window.location = '/';
				}
			});

			return;
		}

		return Promise.reject(error);
	}
);

axios.interceptors.request.use(
	function (request) {
		helpers.methods.replaceDates(request.data);

		return request;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export { axios };

export function getClientId() {
	return process.env.VUE_APP_API_CLIENTID;
}

export function getToken() {
	return store.state.token;
}
