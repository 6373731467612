<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            <span class="heroTitle">{{this.$lang.PanelInfos.Title_2}} </span> &nbsp; <span class="fillText" style="font-size:16px">{{this.$lang.Menus.FillFields}}</span>

            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-field v-if="isAeCalculated">
                    <p class="control">
                        <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
                    </p>
                </b-field>
                <b-field v-if="!isAeCalculated">
                    <p class="control">
                        <b-button class="button is-primary" @click="getAeValue_3" style="float: right;">{{this.$lang.PanelInfos.CalculateBtn}}</b-button>
                    </p>
                </b-field>
            </p>
        </hero-bar>


        <div id="app" style="padding-left: 1.75rem; padding-top: 1.4rem">
            <div class="columns">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-2 verticalStep">
                        <verticalStep-bar />
                    </div>
                    <div class="column is-10">
                        <div class="columns is-desktop">
                            <div class="column is-8 panelInfos">
                                <b-field horizontal :label="this.$lang.PanelInfos.PanelType" :type="{ 'is-danger': hasError_panelType }" :message="this.emptyWarning_panelType">
                                    <b-field class="field is-grouped">
                                        <b-field>
                                            <b-radio v-model="panelTypeRadio"
                                                     native-value="value_1"
                                                     @input="updateAll(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ paneltypelist[0] }}</b-field>
                                        </b-field>
                                        <b-field v-if="paneltypelist.length > 1" style="margin-left: 16px">
                                            <b-radio v-model="panelTypeRadio"
                                                     native-value="value_2"
                                                     @input="updateAll(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ paneltypelist[1] }}</b-field>
                                        </b-field>
                                    </b-field>
                                </b-field>

                                <b-field horizontal :label="this.$lang.PanelInfos.PanelIP" :type="{ 'is-danger': hasError_ip }" :message="this.emptyWarning_ip">
                                    <b-field class="field is-grouped">
                                        <b-field>
                                            <b-radio v-model="panelIpRadio"
                                                     native-value="value_1"
                                                     @input="getPanelDimensions(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ paneliplist[0] }}</b-field>
                                        </b-field>
                                        <b-field v-if="paneliplist.length > 1" style="margin-left: 31px">
                                            <b-radio v-model="panelIpRadio"
                                                     native-value="value_2"
                                                     @input="getPanelDimensions(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ paneliplist[1] }}</b-field>
                                        </b-field>
                                    </b-field>
                                </b-field>

                                <b-field horizontal :label="this.$lang.PanelInfos.PanelDimension" :type="{ 'is-danger': hasError_panelDimensions }" :message="this.emptyWarning_panelDimensions">
                                    <b-field class="field is-grouped">
                                        <b-select v-if="paneldimensionslist" v-model="panelDimension" @input="getS_airValue(); enableManuelSave();">
                                            <option v-for="option in paneldimensionslist"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-field>
                                </b-field>

                                <b-field class="panelSurface" horizontal is-multiline :label="this.$lang.PanelInfos.PanelSurface" :type="{ 'is-danger': hasError_virtualSurface }" :message="this.emptyWarning_virtualSurface">
                                    <div class="columns is-multiline is-mobile">
                                        <div class="column locations field is-grouped">
                                            <b-radio v-model="virtualSurface"
                                                     native-value="value_1"
                                                     @input="getLocationVirtualSurfaceOptions_2(); enableManuelSave();">
                                            </b-radio>
                                            <b-field v-if="virtualsurfaces.length > 3" class="img1" style="width: 2%;">
                                                <b-image src="/img/PanelLocations/DikiliTip_1.png"></b-image>
                                                {{ virtualsurfaces[0] }}
                                            </b-field>
                                            <b-field v-if="virtualsurfaces.length <= 3" class="img2" style="width: 5%;">
                                                <b-image src="/img/PanelLocations/DuvarTipi_1.png"></b-image>
                                                {{ virtualsurfaces[0] }}
                                            </b-field>
                                            <p class="px-3">

                                            </p>
                                        </div>
                                        <div class="column locations field is-grouped">
                                            <b-radio v-model="virtualSurface"
                                                     native-value="value_2"
                                                     @input="getLocationVirtualSurfaceOptions_2(); enableManuelSave();">
                                            </b-radio>
                                            <b-field v-if="virtualsurfaces.length > 3" class="img1" style="width: 2%;">
                                                <b-image src="/img/PanelLocations/DikiliTip_2.png"></b-image>
                                                {{ virtualsurfaces[1] }}
                                            </b-field>
                                            <b-field v-if="virtualsurfaces.length <= 3" class="img2" style="width: 5%;">
                                                <b-image src="/img/PanelLocations/DuvarTipi_2.png"></b-image>
                                                {{ virtualsurfaces[1] }}
                                            </b-field>
                                            <p class="px-3">

                                            </p>
                                        </div>
                                        <div class="column locations field is-grouped">
                                            <b-radio v-model="virtualSurface"
                                                     native-value="value_3"
                                                     @input="getLocationVirtualSurfaceOptions_2(); enableManuelSave();">
                                            </b-radio>
                                            <b-field v-if="virtualsurfaces.length > 3" class="img1" style="width: 2%;">
                                                <b-image src="/img/PanelLocations/DikiliTip_3.png"></b-image>
                                                {{ virtualsurfaces[2] }}
                                            </b-field>
                                            <b-field v-if="virtualsurfaces.length <= 3" class="img2" style="width: 5%; margin-right: 20px;">
                                                <b-image src="/img/PanelLocations/DuvarTipi_3.png"></b-image>
                                                {{ virtualsurfaces[2] }}
                                            </b-field>
                                            <p class="px-3">

                                            </p>
                                        </div>
                                        <div class="column locations field is-grouped">
                                            <b-radio v-model="virtualSurface"
                                                     native-value="value_4"
                                                     @input="getLocationVirtualSurfaceOptions_2(); enableManuelSave();"
                                                     v-if="virtualsurfaces.length > 3">
                                            </b-radio>
                                            <b-field style="width: 2%" class="img1" v-if="virtualsurfaces.length > 3">
                                                <b-image src="/img/PanelLocations/DikiliTip_4.png"></b-image>
                                                {{ virtualsurfaces[3] }}
                                            </b-field>
                                            <p class="px-3">

                                            </p>
                                        </div>
                                        <div class="column locations field is-grouped">
                                            <b-radio v-model="virtualSurface"
                                                     native-value="value_5"
                                                     @input="getLocationVirtualSurfaceOptions_2(); enableManuelSave();"
                                                     v-if="virtualsurfaces.length > 3">
                                            </b-radio>
                                            <b-field style="width: 2%;" class="img1" v-if="virtualsurfaces.length > 3">
                                                <b-image src="/img/PanelLocations/DikiliTip_5.png"></b-image>
                                                {{ virtualsurfaces[4] }}
                                            </b-field>
                                            <p class="px-3">

                                            </p>
                                        </div>
                                        <div class="column locations field is-grouped">
                                            <b-radio v-model="virtualSurface"
                                                     native-value="value_6"
                                                     @input="getLocationVirtualSurfaceOptions_2(); enableManuelSave();"
                                                     v-if="virtualsurfaces.length > 3">
                                            </b-radio>
                                            <b-field class="img1" style="width: 2%; margin-right: 20px;" v-if="virtualsurfaces.length > 3">
                                                <b-image src="/img/PanelLocations/DikiliTip_6.png"></b-image>
                                                {{ virtualsurfaces[5] }}
                                            </b-field>
                                        </div>
                                    </div>
                                </b-field>

                                <b-field horizontal v-if="this.Sair != 'N/A'">
                                    <template #label>
                                        <span v-html="$lang.PanelInfos.Sair"></span>
                                    </template>
                                    <b-input v-model="Sair" style="width: 20%; padding-top: 0.5rem" @input="setAeNotCalculated(); enableManuelSave();"></b-input>
                                </b-field>

                                <b-field horizontal :label="this.$lang.PanelInfos.SeperationNumber" v-if="this.seperationnumbers.length != 1">
                                    <b-field class="field is-grouped">
                                        <b-field>
                                            <b-radio v-model="seperationNumber"
                                                     native-value="0"
                                                     @input="setAeNotCalculated(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ seperationnumbers[0] }}</b-field>
                                        </b-field>
                                        <b-field v-if="seperationnumbers.length > 1">
                                            <b-radio v-model="seperationNumber"
                                                     native-value="1"
                                                     @input="setAeNotCalculated(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ seperationnumbers[1] }}</b-field>
                                        </b-field>
                                        <b-field v-if="seperationnumbers.length > 2">
                                            <b-radio v-model="seperationNumber"
                                                     native-value="2"
                                                     @input="setAeNotCalculated(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ seperationnumbers[2] }}</b-field>
                                        </b-field>
                                        <b-field v-if="seperationnumbers.length > 3">
                                            <b-radio v-model="seperationNumber"
                                                     native-value="3"
                                                     @input="setAeNotCalculated(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ seperationnumbers[3] }}</b-field>
                                        </b-field>
                                        <b-field v-if="seperationnumbers.length > 4">
                                            <b-radio v-model="seperationNumber"
                                                     native-value="4"
                                                     @input="setAeNotCalculated(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ seperationnumbers[4] }}</b-field>
                                        </b-field>
                                        <b-field v-if="seperationnumbers.length > 5">
                                            <b-radio v-model="seperationNumber"
                                                     native-value="5"
                                                     @input="setAeNotCalculated(); enableManuelSave();">
                                            </b-radio>
                                            <b-field>{{ seperationnumbers[5] }}</b-field>
                                        </b-field>
                                    </b-field>
                                </b-field>

                            </div>
                            <div class="column is-4 panelBox">
                                <div class="box" style="width: 260px; background-color: #878787;">
                                    <b-field class="box" style="width: 230px; height: 50px; padding: 15px; ">
                                        <b-field class="field is-grouped" :label="this.$lang.PanelInfos.Ae_total" style="width: 210px;">
                                            <b-field style="margin-left: 4px"> {{ total_Ae_field }} </b-field>
                                        </b-field>
                                    </b-field>
                                    <b-field class="inline">
                                        <div class="flex__container">
                                            <b-field class="box" style="font-size: 11px; width: 230px; height: 50px; padding: 15px; display: block; margin-right: 0.1rem; margin-left: auto;">
                                                <p class="content" style="text-align: right"><b> {{this.$lang.PanelInfos.Ae_up}} </b>: {{ ae_value_field_up }}</p>
                                            </b-field>
                                            <b-field class="box" style="font-size: 11px; width: 230px; height: 50px; padding: 15px; display: block; margin-right: 0.1rem; margin-left: auto; ">
                                                <p class="content" style="text-align: right"><b> {{this.$lang.PanelInfos.Ae_front}} </b>: {{ ae_value_field_front }}</p>
                                            </b-field>
                                            <b-field class="box" style="font-size: 11px; width: 230px; height: 50px; padding: 15px; display: block; margin-right: 0.1rem; margin-left: auto; ">
                                                <p class="content" style="text-align: right"><b> {{this.$lang.PanelInfos.Ae_back}} </b>: {{ ae_value_field_back }}</p>
                                            </b-field>
                                            <b-field class="box" style="font-size: 11px; width: 230px; height: 50px; padding: 15px; display: block; margin-right: 0.1rem; margin-left: auto; ">
                                                <p class="content" style="text-align: right"><b> {{this.$lang.PanelInfos.Ae_left}} </b>: {{ ae_value_field_left }}</p>
                                            </b-field>
                                            <b-field class="box" style="font-size: 11px; width: 230px; height: 50px; padding: 15px; display: block; margin-right: 0.1rem; margin-left: auto; ">
                                                <p class="content" style="text-align: right"><b> {{this.$lang.PanelInfos.Ae_right}} </b>: {{ ae_value_field_right }}</p>
                                            </b-field>
                                        </div>
                                    </b-field>
                                </div>
                            </div>
                        </div>

                        <div class="card-content">
                            <div> <b> {{ this.$lang.PanelInfos.Info_3 }} </b> </div>
                            {{ this.$lang.PanelInfos.Info_4 }}
                            <ul class="panelList1">
                                <li class="panelListItem1"> {{ this.$lang.PanelInfos.Info_5 }} </li>
                                <li class="panelListItem1"> {{ this.$lang.PanelInfos.Info_6 }} </li>
                                <li class="panelListItem1"> {{ this.$lang.PanelInfos.Info_7 }} </li>
                                <li class="panelListItem1"> {{ this.$lang.PanelInfos.Info_8 }} </li>
                                <li class="panelListItem1">
                                    {{ this.$lang.PanelInfos.Info_9 }}
                                    <ul class="panelList2">
                                        <li class="panelListItem2">{{ this.$lang.PanelInfos.Info_10 }}</li>
                                        <li class="panelListItem2">{{ this.$lang.PanelInfos.Info_11 }}</li>
                                        <li class="panelListItem2">{{ this.$lang.PanelInfos.Info_12 }}</li>
                                        <li class="panelListItem2">{{ this.$lang.PanelInfos.Info_13 }}</li>
                                    </ul>
                                </li>
                                <li class="panelListItem1"> {{ this.$lang.PanelInfos.Info_14 }} </li>
                                <li class="panelListItem1"> {{ this.$lang.PanelInfos.Info_15 }} </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .test {
        padding-left: 100px;
    }
</style>
<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';

    const paneltypelist = [];
    const panelType = '';
    const paneldimensionslist = [];
    const panelDimension = '';
    const paneliplist = [];
    const panelIP = '';
    const Sair = '';
    const virtualsurfaces = [];
    const virtualsurfaceoptions = [];
    const virtualSurfaceOption = 0;
    const seperationnumbers = [];
    const seperationNumber = 0;

    export default {
        name: 'PanelInfos',
        components: {
            VerticalStepBar,
        },
        data() {
            return {
                isActive: true,
                isInfoOpened: false,
                label_panelType: this.$lang.PanelInfos.PanelType,
                label_panelDimension: this.$lang.PanelInfos.PanelDimension,
                label_panelIP: this.$lang.PanelInfos.PanelIP,
                label_virtualSurface: this.$lang.PanelInfos.PanelSurface,
                label_Sair: this.$lang.PanelInfos.Sair,
                label_virtualSurfaceOption: this.$lang.PanelInfos.VirtualSurface,
                label_seperationNumber: this.$lang.PanelInfos.SeperationNumber,
                label_total_Ae: this.$lang.PanelInfos.Ae_total,
                paneltypelist,
                panelType,
                panelTypeRadio: 'value_1',
                panelTypeRadioList: ['value_1', 'value_2'],
                panelTypeIndex: 0,
                paneldimensionslist,
                panelDimension,
                ip_Id: 0,
                paneliplist,
                panelIP,
                panelIpRadio: 'value_1',
                panelIpRadioList: ['value_1', 'value_2'],
                Sair,
                surfaceId: 0,
                surfaceId_2: 0,
                virtualsurfaces,
                virtualSurface: 'value_1',
                radios_surface: ['value_1', 'value_2', 'value_3', 'value_4', 'value_5', 'value_6', 'value_7'],
                virtualsurfaceoptions,
                virtualSurfaceOption,
                seperationnumbers,
                seperationNumber,
                total_Ae: 0,
                ae_values: [],
                ae_value_field_up: '',
                ae_value_field_front: '',
                ae_value_field_back: '',
                ae_value_field_left: '',
                ae_value_field_right: '',
                total_Ae_field: '',
                isAeCalculated: false,
                canGoNextPage: false,
                hasError_panelType: false,
                emptyWarning_panelType: '',
                hasError_panelDimensions: false,
                emptyWarning_panelDimensions: '',
                hasError_ip: false,
                emptyWarning_ip: '',
                hasError_virtualSurface: false,
                emptyWarning_virtualSurface: ''
            }
        },
        mounted() {
            this.getPanelTypes();
            this.getPanelDimensions();
            this.getPanelProtectionClassOptions();
            this.getLocationVirtualSurfaceOptions();
            this.getLocationVirtualSurfaceOptions_2();
            this.getHorizontalSeperationNumbers();

            if (this.$store.state.panelDimension != null && this.$store.state.panelDimension != '') {
                this.getAeValue_2();
            }

            this.virtualSurfaceOption = this.$store.state.virtualSurfaceOption;
            this.surfaceId_2 = this.$store.state.virtualSurfaceIndex;
            if (this.panelTypeIndex == 0) {
                this.surfaceId = this.surfaceId_2;
            } else {
                if (this.surfaceId_2 < 6) {
                    this.surfaceId = this.surfaceId_2 / 2;
                } else {
                    this.surfaceId = 7;
                }
            }

            this.$store.state.activeStep = 1;
            this.$store.state.isFooterFixed = true;
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/projectInfos');
            },
            submit() {
                this.controlInputs();

                if (this.canGoNextPage) {
                    if (this.$store.state.isPrevProject) {
                        this.getAeValue_2();
                    } else {
                        this.getAeValue();
                    }

                    setTimeout(() => { this.nextPageFunc(); }, 1000);
                }
            },
            enableManuelSave() {
                this.$store.state.canManuelSave = true;
            },
            setSavePayload() {
                var surfaceInfo = '';
                surfaceInfo = this.virtualsurfaces[this.surfaceId - 1];
                var ip = this.paneliplist.indexOf(this.panelIP);
                if (ip == -1) {
                    ip = 0;
                }
                ip += 1;
                this.ip_Id = ip;

                var payload = {
                    panelType: this.panelType, panelTypeIndex: this.panelTypeIndex, panelDimension: this.panelDimension, panelIP: this.panelIP, selectedIP_Id: this.ip_Id, Sair: this.Sair, surfaceExplanation: surfaceInfo, virtualSurface: this.virtualSurface, virtualSurfaceIndex: this.surfaceId_2, virtualSurfaceOption: this.virtualSurfaceOption, seperationNumber: this.seperationNumber, ae_value: this.total_Ae, ae_valueList: this.ae_values
                };
                this.$store.commit('setPanelInfos', payload);
            },
            nextPageFunc() {
                var surfaceInfo = '';
                surfaceInfo = this.virtualsurfaces[this.surfaceId - 1];
                var ip = this.paneliplist.indexOf(this.panelIP);
                if (ip == -1) {
                    ip = 0;
                }
                ip += 1;
                this.ip_Id = ip;

                var payload = {
                    panelType: this.panelType, panelTypeIndex: this.panelTypeIndex, panelDimension: this.panelDimension, panelIP: this.panelIP, selectedIP_Id: this.ip_Id, Sair: this.Sair, surfaceExplanation: surfaceInfo, virtualSurface: this.virtualSurface, virtualSurfaceIndex: this.surfaceId_2, virtualSurfaceOption: this.virtualSurfaceOption, seperationNumber: this.seperationNumber, ae_value: this.total_Ae, ae_valueList: this.ae_values
                };
                this.$store.commit('setPanelInfos', payload);

                this.$store.isPageOpenedFromStepBar = false;
                this.$store.canEnvConditionsOpen = true;

                router.push('/envConditions');
            },
            resetFields(index) {
                if (index == 1) {
                    this.panelIP = '';
                    this.panelDimension = '';
                    this.virtualSurface = 0;
                    this.Sair = 0;
                    this.virtualSurfaceOption = 0;
                    this.seperationNumber = 0;
                } else if (index == 2) {
                    this.panelDimension = '';
                    this.virtualSurface = 0;
                    this.Sair = 0;
                    this.virtualSurfaceOption = 0;
                    this.seperationNumber = 0;
                } else if (index == 3) {
                    this.virtualSurface = 0;
                    this.Sair = 0;
                    this.virtualSurfaceOption = 0;
                    this.seperationNumber = 0;
                } else if (index == 4) {
                    this.Sair = 0;
                    this.virtualSurfaceOption = 0;
                    this.seperationNumber = 0;
                } else if (index == 5) {
                    this.virtualSurfaceOption = 0;
                    this.seperationNumber = 0;
                } else if (index == 6) {
                    this.seperationNumber = 0;
                }
            },

            controlInputs() {
                if (this.panelType == '') {
                    this.hasError_panelType = true;
                    this.emptyWarning_panelType = this.$lang.Menus.EmptyWarning;
                } else if (this.panelIP == '') {
                    this.hasError_ip = true;
                    this.emptyWarning_ip = this.$lang.Menus.EmptyWarning;
                } else if (this.panelDimension == '') {
                    this.hasError_panelDimensions = true;
                    this.emptyWarning_panelDimensions = this.$lang.Menus.EmptyWarning;
                } else if (this.virtualSurface == '' || this.virtualSurface == null || this.virtualSurface == 0) {
                    this.hasError_virtualSurface = true;
                    this.emptyWarning_virtualSurface = this.$lang.Menus.EmptyWarning;
                } else {
                    this.canGoNextPage = true;
                }

                if (this.panelType != '') {
                    this.hasError_panelType = false;
                    this.emptyWarning_panelType = '';
                }
                if (this.panelIP != '') {
                    this.hasError_ip = false;
                    this.emptyWarning_ip = '';
                }
                if (this.panelDimension != '') {
                    this.hasError_panelDimensions = false;
                    this.emptyWarning_panelDimensions = '';
                }
                if (this.virtualSurface != '' && this.virtualSurface != null && this.virtualSurface != 0) {
                    this.hasError_virtualSurface = false;
                    this.emptyWarning_virtualSurface = '';
                }
            },
            updateAll() {
                this.isAeCalculated = false;
                this.panelTypeIndex = this.panelTypeRadioList.indexOf(this.panelTypeRadio);
                this.panelType = this.paneltypelist[this.panelTypeIndex];
                if (this.panelTypeIndex == -1) {
                    this.panelTypeIndex = 0;
                }
                this.$store.state.panelTypeIndex = this.panelTypeIndex;
                this.getPanelDimensions();
                this.getPanelProtectionClassOptions();
                this.getLocationVirtualSurfaceOptions();
            },
            getPanelTypes() {
                this.paneltypelist = [];
                api.panels.getPanelTypes(this.$store.state.ratedCurrentIndex, this.$lang.Format.Lang).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.paneltypelist.push(e.TypeName);
                        });
                        this.panelType = this.$store.state.panelType;

                        this.panelTypeIndex = this.$store.state.panelTypeIndex;
                        this.panelTypeRadio = this.panelTypeRadioList[this.panelTypeIndex];
                        this.panelType = this.paneltypelist[this.panelTypeIndex];
                    }
                });
            },

            getIp() {
                var i = this.panelIpRadioList.indexOf(this.panelIpRadio);
                this.panelIP = this.paneliplist[i];

                var ip = 0;
                if (this.panelIP == '') {
                    if (this.$store.state.panelIP == '') {
                        ip = 55;
                    } else {
                        if (this.$store.state.panelIP == '>IP31') {
                            ip = 55;
                        } else {
                            ip = 31;
                        }
                    }
                } else {
                    if (this.panelIP == '>IP31') {
                        ip = 55;
                    } else {
                        ip = 31;
                    }
                }
                return ip;
            },
            getPanelProtectionClassOptions() {
                this.paneliplist = [];
                api.panels.getPanelProtectionClassOptions(this.$store.state.panelTypeIndex).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.paneliplist.push(e.Option);
                        });
                        this.panelIP = this.$store.state.panelIP;
                        this.panelIpRadio = this.panelIpRadioList[this.paneliplist.indexOf(this.panelIP)];
                        this.getS_airValue();
                    }
                });
                // this.resetFields(1);
            },
            getPanelDimensions() {
                this.isAeCalculated = false;
                this.panelDimension = '';
                this.paneldimensionslist = [];
                var tempIp = this.getIp();
                if (this.$store.state.panelTypeIndex == 1) {
                    if (this.getIp() == 31) {
                        tempIp = 55;
                    }
                }
                api.panels.getPanelDimensions(this.$store.state.panelTypeIndex, tempIp).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.paneldimensionslist.push(e.Y + "x" + e.G + "x" + e.D);
                        });
                        this.panelDimension = this.$store.state.panelDimension;
                    }
                });
                // this.resetFields(2);
            },
            getS_airValue() {
                this.isAeCalculated = false;
                var ip = this.paneliplist.indexOf(this.panelIP);
                if (ip == -1) {
                    ip = 0;
                }
                ip += 1;
                this.ip_Id = ip;

                var width = 0;
                if (this.$store.state.panelDimension != null) {
                    const dimension = this.$store.state.panelDimension.split("x");
                    width = parseFloat(dimension[1]);
                }
                if (this.panelDimension != null && this.panelDimension != '') {
                    const dimension = this.panelDimension.split("x");
                    width = parseFloat(dimension[1]);
                }
                api.panels.getS_airValue(this.$store.state.ratedCurrentIndex, ip, width).then((res) => {
                    if (res != null) {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.Sair = res.data;
                        }
                    } else {
                        this.Sair = "N/A";
                    }
                });
                // this.resetFields(4);
            },
            getLocationVirtualSurfaceOptions() {
                this.virtualsurfaces = [];
                this.virtualsurfaceoptions = [];
                this.virtualsurfaceoptions.push(0);
                api.panels.getLocationVirtualSurfaceOptions(this.$store.state.panelTypeIndex, this.$lang.Format.Lang).then((res) => {
                    if (res != null) {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            res.data.forEach((e) => {
                                this.virtualsurfaces.push(e.PanelLocation);
                            });
                            this.virtualSurface = this.$store.state.virtualSurface;
                        }
                    } else {
                        this.virtualsurfaces.push(0);
                        this.virtualSurface = this.$store.state.virtualSurface;
                    }
                });
            },
            getLocationVirtualSurfaceOptions_2() {
                this.isAeCalculated = false;
                this.surfaceId = this.radios_surface.indexOf(this.virtualSurface);
                this.surfaceId += 1;

                if (this.$store.state.panelTypeIndex == 0) {
                    this.surfaceId_2 = this.radios_surface.indexOf(this.virtualSurface);
                    this.surfaceId_2 += 1;
                } else {
                    if (this.surfaceId < 4) {
                        this.surfaceId_2 = this.surfaceId * 2;
                    } else if (this.surfaceId == 4) {
                        this.surfaceId_2 = 7;
                    }
                }

                if (this.$store.state.ratedCurrentIndex == 1) {
                    this.virtualsurfaceoptions = [];
                    this.virtualsurfaceoptions.push(0);
                } else {
                    this.virtualsurfaceoptions = [];
                    api.panels.getLocationVirtualSurfaceOptions(this.$store.state.panelTypeIndex, this.$lang.Format.Lang).then((res) => {
                        if (res != null) {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                var index = this.radios_surface.indexOf(this.virtualSurface);
                                if (index == -1) {
                                    index = 0;
                                }
                                for (let i = 0; i < res.data[index].SurfaceOptions.length; i++) {
                                    this.virtualsurfaceoptions.push(res.data[index].SurfaceOptions[i]);
                                }
                            }
                        } else {
                            this.virtualsurfaceoptions = [];
                        }
                    });
                }
                // this.resetFields(5);
            },
            getHorizontalSeperationNumbers() {
                this.seperationnumbers = [];
                api.panels.getHorizontalSeperationNumbers(this.$store.state.ratedCurrentIndex).then((res) => {
                    if (res != null) {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            res.data.forEach((e) => {
                                this.seperationnumbers.push(e);
                            });
                            this.seperationNumber = this.$store.state.seperationNumber;
                        }
                    } else {
                        this.seperationnumbers.push(0);
                        this.seperationNumber = this.$store.state.seperationNumber;
                    }
                });
                // this.resetFields(6);
            },
            getAeValue() {
                this.ae_values = [];
                var panelTypeIndex = this.panelTypeRadioList.indexOf(this.panelTypeRadio);
                var surfaceLocationIndex = this.radios_surface.indexOf(this.virtualSurface);
                if (panelTypeIndex == 1) {
                    surfaceLocationIndex = (surfaceLocationIndex + 1) * 2;
                } else {
                    surfaceLocationIndex += 1;
                }
                api.panels.getAeValue(this.$store.state.ratedCurrentIndex, surfaceLocationIndex, this.panelDimension).then((res) => {
                    if (res != null) {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.ae_values.push(res.data.UpSurface_AeValue);
                            this.ae_values.push(res.data.FrontSurface_AeValue);
                            this.ae_values.push(res.data.BackSurface_AeValue);
                            this.ae_values.push(res.data.LeftSurface_AeValue);
                            this.ae_values.push(res.data.RightSurface_AeValue);
                            this.total_Ae = 0;
                            for (let i = 0; i < this.ae_values.length; i++) {
                                this.total_Ae += this.ae_values[i];
                            }
                            this.total_Ae = this.setDecimalView(this.total_Ae, 3);

                            this.ae_value_field_up = this.setDecimalView(res.data.UpSurface_AeValue, 3);
                            this.ae_value_field_front = this.setDecimalView(res.data.FrontSurface_AeValue, 3);
                            this.ae_value_field_back = this.setDecimalView(res.data.BackSurface_AeValue, 3);
                            this.ae_value_field_left = this.setDecimalView(res.data.LeftSurface_AeValue, 3);
                            this.ae_value_field_right = this.setDecimalView(res.data.RightSurface_AeValue, 3);
                            this.total_Ae_field = this.total_Ae;

                            this.isAeCalculated = true;
                        }
                    } else {
                        this.ae_values.push(0);
                    }
                });
            },
            getAeValue_2() {
                this.ae_values = [];
                api.panels.getAeValue(this.$store.state.ratedCurrentIndex, this.$store.state.virtualSurfaceIndex, this.$store.state.panelDimension).then((res) => {
                    if (res != null) {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.ae_values.push(res.data.UpSurface_AeValue);
                            this.ae_values.push(res.data.FrontSurface_AeValue);
                            this.ae_values.push(res.data.BackSurface_AeValue);
                            this.ae_values.push(res.data.LeftSurface_AeValue);
                            this.ae_values.push(res.data.RightSurface_AeValue);
                            this.total_Ae = 0;
                            for (let i = 0; i < this.ae_values.length; i++) {
                                this.total_Ae += this.ae_values[i];
                            }
                            this.total_Ae = this.setDecimalView(this.total_Ae, 3);

                            this.ae_value_field_up = this.setDecimalView(res.data.UpSurface_AeValue, 3);
                            this.ae_value_field_front = this.setDecimalView(res.data.FrontSurface_AeValue, 3);
                            this.ae_value_field_back = this.setDecimalView(res.data.BackSurface_AeValue, 3);
                            this.ae_value_field_left = this.setDecimalView(res.data.LeftSurface_AeValue, 3);
                            this.ae_value_field_right = this.setDecimalView(res.data.RightSurface_AeValue, 3);
                            this.total_Ae_field = this.total_Ae;

                            this.isAeCalculated = true;
                        }
                    } else {
                        this.ae_values.push(0);
                    }
                });
            },
            getAeValue_3() {
                this.controlInputs();
                if (this.canGoNextPage) {
                    this.getAeValue();
                }
            },
            openInfo() {
                if (!this.isInfoOpened) {
                    this.isInfoOpened = true;
                } else {
                    this.isInfoOpened = false;
                }
            },
            setDecimalView(numToBeTruncated, numOfDecimals) {
                var theNumber = numToBeTruncated.toString();
                var pointIndex = theNumber.indexOf('.');
                return +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
            },
            setAeNotCalculated() {
                this.isAeCalculated = false;
            }
        },

    };
</script>

<style>
    .verticalStep {
        background-color: #FFFFFF;
        width: 10% !important;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
    }  
    
    @media only screen and (max-width: 424px) {
        .heroTitle {
            font-size: 18px !important;
        }
        .fillText {
            font-size: 14px !important;
        }
    }   
    
    @media only screen and (max-width: 384px) {
        .heroTitle {
            font-size: 16px !important;
        }
        .fillText {
            font-size: 12px !important;
        }
    }

    .panelListItem1 {
        padding-left: 0.25rem;
        list-style-type: "-";
    }

    .panelListItem2 {
        list-style-type: disc;
    }

    .panelList1 {
        padding-left: 1.25rem;
    }

    .panelList2 {
        padding-left: 4rem;
    }

    .type1 {
        font-size: 0.8em;
    }

    .img1 {
        width: min-content !important;
    }

    .img2 {
        width: min-content !important;
    }

    .locations {
        width: 13%;
        margin-right: 15px !important;
    }

    .panelInfos {
        width: 70% !important;
        margin-right: auto;
    }

    .panelBox {
        width: 30% !important;
        display: flex;
        justify-content: flex-end;
        margin-left: 10%;
    }

    @media only screen and (max-width: 1450px) {

        .panelBox {
            justify-content: center;
            margin-left: 5%;
        }
    }

    @media only screen and (max-width: 1204px) {
        .locations {
            margin-right: 15px !important;
        }

        .panelBox {
            display: block;
        }
    }

    @media only screen and (max-width:1024px) {

        .panelInfos {
            width: 90% !important;
        }
    }

    @media only screen and (min-width: 1024px) {

        .panelbox {
            display: flex;
            justify-content: center;
            width: auto !important;
        }
    }

    @media only screen and (max-width:541px) {
        .locations {
            width: 25%;
            margin-right: 15px !important;
        }
    }
</style>
