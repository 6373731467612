<template>
    <b-field v-if="this.$store.state.memberId != 'TestMember'">
        <p class="control">
            <b-button v-if="this.$store.state.canManuelSave" class="button is-primary" @click="saveProjectManuel()" style="float: right;"> {{ this.$lang.Menus.SaveBtn }} </b-button>
            <b-button v-if="!this.$store.state.canManuelSave" disabled class="button is-primary" style="float: right;">{{this.$lang.Menus.SaveBtn}}</b-button>
        </p>
    </b-field>
</template>

<script>
    // import router from '@/router';
    import api from '@/api';

    export default {
        name: 'SaveButton',
        components: {

        },
        data() {
            return {

            }
        },
        methods: {    
            callMethodInParentComponent() {
                this.$emit("callMethodInParent");
            },

            saveProjectManuel() {
                this.$store.state.canManuelSave = false;
                this.callMethodInParentComponent();

                var newSair = this.$store.state.Sair;
                if (this.Sair == 'N/A') {
                    newSair = null;
                }
                var currDate = new Date();
                var day = String(currDate.getDate()).padStart(2, '0');
                var month = String(currDate.getMonth() + 1).padStart(2, '0');
                var year = currDate.getFullYear();
                var currentDate = year + "-" + month + "-" + day;

                if (!this.$store.state.isPrevProject) {
                    const project = {
                        MemberId: this.$store.state.memberId,
                        IsCompleted: 0,
                        ProjectName: this.$store.state.projectName,
                        PanelName: this.$store.state.panelName,
                        PanelNo: this.$store.state.panelNo,
                        PanelManifacturer: this.panelManifacturer,
                        LastUserName: this.lastUserName,
                        PanelRatedCurrent: this.$store.state.panelRatedCurrent,
                        RatedCurrentIndex: this.$store.state.ratedCurrentIndex,
                        TotalEyesNumber: this.$store.state.totalEyesNumber,
                        EyeNo: this.$store.state.eyesNumber,
                        PanelType: this.$store.state.panelType,
                        PanelTypeId: this.$store.state.panelTypeIndex,
                        PanelDimension: this.$store.state.panelDimension,
                        IP: this.$store.state.panelIP,
                        Sair: newSair,
                        SurfaceInfo: this.$store.state.surfaceExplanation,
                        VirtualSurface: this.$store.state.virtualSurface,
                        VirtualSurfaceIndex: this.$store.state.virtualSurfaceIndex,
                        VirtualSurfaceOption: this.$store.state.virtualSurfaceOption,
                        SeperationNumber: this.$store.state.seperationNumber,
                        Ae: this.$store.state.ae_value,
                        Ta: this.$store.state.envTemperature,
                        Tmax: this.$store.state.maxTemperature,
                        Altitude: this.$store.state.altitude,
                        CalcTypeId: this.$store.state.calcIndex,
                        KnownLostPower: this.$store.state.totalLostPower_1,
                        InPanelTemp: this.$store.state.inPanelTemperature,
                        InEnvTemp: this.$store.state.envMeasureTemperature,
                        InPanelLostPower: this.$store.state.totalLostPower_2,
                        CalcDeviceLostPower: this.$store.state.totalLostPower_3,
                        ReportDate: currentDate,
                        IsValid: this.$store.state.isResultValid
                    };

                    api.panels.addNewProjectManuel(project).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            var oldProjectId = this.$store.state.projectId;
                            this.updateAcceptLog(oldProjectId, res.data);
                            this.updateCalcDevices(oldProjectId, res.data);
                            this.$store.state.projectId = res.data;

                            this.$store.state.prevProjectId = res.data;
                            this.$buefy.toast.open(this.$lang.ReportPage.Saved);
                        }
                    });
                } else {
                    const project = {
                        MemberId: this.$store.state.memberId,
                        IsCompleted: 0,
                        ProjectId: this.$store.state.projectId,
                        ProjectName: this.$store.state.projectName,
                        PanelName: this.$store.state.panelName,
                        PanelNo: this.$store.state.panelNo,
                        LastUserName: this.$store.state.lastUserName,
                        PanelManifacturer: this.$store.state.panelManifacturer,
                        PanelRatedCurrent: this.$store.state.panelRatedCurrent,
                        RatedCurrentIndex: this.$store.state.ratedCurrentIndex,
                        TotalEyesNumber: this.$store.state.totalEyesNumber,
                        EyeNo: this.$store.state.eyesNumber,
                        PanelType: this.$store.state.panelType,
                        PanelTypeId: this.$store.state.panelTypeIndex,
                        PanelDimension: this.$store.state.panelDimension,
                        IP: this.$store.state.panelIP,
                        Sair: newSair,
                        SurfaceInfo: this.$store.state.surfaceExplanation,
                        VirtualSurface: this.$store.state.virtualSurface,
                        VirtualSurfaceIndex: this.$store.state.virtualSurfaceIndex,
                        VirtualSurfaceOption: this.$store.state.virtualSurfaceOption,
                        SeperationNumber: this.$store.state.seperationNumber,
                        Ae: this.$store.state.ae_value,
                        Ta: this.$store.state.envTemperature,
                        Tmax: this.$store.state.maxTemperature,
                        Altitude: this.$store.state.altitude,
                        CalcTypeId: this.$store.state.calcIndex,
                        KnownLostPower: this.$store.state.totalLostPower_1,
                        InPanelTemp: this.$store.state.inPanelTemperature,
                        InEnvTemp: this.$store.state.envMeasureTemperature,
                        InPanelLostPower: this.$store.state.totalLostPower_2,
                        CalcDeviceLostPower: this.$store.state.totalLostPower_3,
                        ReportDate: currentDate,
                        IsValid: this.$store.state.isResultValid
                    };

                    api.panels.updateProject(project).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.$buefy.toast.open(this.$lang.ReportPage.Updated);
                        }
                    });
                }              
            },

            updateAcceptLog(oldProjectId, newProjectId) {
                const log = {
                    MemberId: this.$store.state.memberId,
                    ProjectId: newProjectId,
                    OldProjectId: oldProjectId
                };
                api.panels.updateAcceptLog(log).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {

                    }
                });
            },
            updateCalcDevices(oldProjectId, newProjectId) {
                api.panels.updateCalcTables_1(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_2(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_3(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_4(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_5(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_6(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.updateCalcTables_7(oldProjectId, newProjectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
            },
        }
    };
</script>

<style>
    .b-steps.is-vertical > .steps:not(.has-label-right):not(.has-label-left) .step-items .step-item .step-link > .step-details {
        background-color: #ffffff;
    }
</style>