<template>
    <div class="columns is-mobile is-multiline is-centered">
        <div class="field is-grouped is-mobile">
            <b-field>
                <b-button class="button is-gray" id="btn1" @click="openCalcBusbar" style="height: 100%; width: 100%">
                    {{this.$lang.Calculation.InnerTitle_9}}
                </b-button>
            </b-field>
            <b-field>
                <b-button class="button is-gray" id="btn2" @click="openInputCircuit" style="height: 100%; width: 100%">
                    {{this.$lang.Calculation.InnerTitle_10}}
                </b-button>
            </b-field>
            <b-field>
                <b-button class="button is-gray" id="btn3" @click="openOutputCircuit" style="height: 100%; width: 100%">
                    {{this.$lang.Calculation.InnerTitle_11}}
                </b-button>
            </b-field>
            <b-field>
                <b-button class="button is-gray" id="btn4" @click="openExtraPower" style="height: 100%; width: 100%">
                    {{this.$lang.Calculation.InnerTitle_12}}
                </b-button>
            </b-field>
            <b-field>
                <b-button v-if="isImgBtnVisible" class="button is-gray" id="btn5" @click="openSummary" style="height: 100%; width: 100%">
                    {{this.$lang.Calculation.InnerTitle_13}}
                </b-button>
                <b-button v-if="!isImgBtnVisible" class="button is-gray" id="btn5" @click="openSummary" style="height: 75%; width: 100%">
                    {{this.$lang.Calculation.InnerTitle_13}}
                </b-button>
            </b-field>
            <b-field v-if="isImgBtnVisible">
                <b-button class="button is-gray" @click="openImage()" style="height: 75%; width: 75%">
                    <span class="material-symbols-outlined" style="padding-top: 0.5rem">
                        Info
                    </span>
                </b-button>
            </b-field>
        </div>
    </div>
</template>

<script>
    import router from '@/router';

    export default {
        name: 'OuputCircBtns',
        data() {
            return {
                isImgBtnVisible: true,
                btn1: null,
                btn2: null,
                btn3: null,
                btn4: null,
                btn5: null,
                btnList: []
            }
        },
        mounted() {
            this.btn1 = document.getElementById('btn1');
            this.btn2 = document.getElementById('btn2');
            this.btn3 = document.getElementById('btn3');
            this.btn4 = document.getElementById('btn4');
            this.btn5 = document.getElementById('btn5');
            this.btnList.push(this.btn1);
            this.btnList.push(this.btn2);
            this.btnList.push(this.btn3);
            this.btnList.push(this.btn4);
            this.btnList.push(this.btn5);

            this.setButton(this.$store.state.outputCircCalcBtnIndex);
            if (this.$route.name == 'calc_Busbar') {
                this.setButton(1);
                this.isImgBtnVisible = true;
            } else if (this.$route.name == 'calc_InputCircuit') {
                this.setButton(2);
                this.isImgBtnVisible = true;
            } else if (this.$route.name == 'calc_OutputCircuit') {
                this.setButton(3);
                this.isImgBtnVisible = true;
            } else if (this.$route.name == 'calc_Manuel') {
                this.setButton(4);
                this.isImgBtnVisible = false;
            } else if (this.$route.name == 'calc_Summary') {
                this.setButton(5);
                this.isImgBtnVisible = false;
            }
        },
        methods: {
            openCalcBusbar() {
                this.$store.state.outputCircCalcBtnIndex = 1;
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_Busbar');
            },
            openInputCircuit() {
                this.$store.state.outputCircCalcBtnIndex = 2;
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_InputCircuit');
            },
            openOutputCircuit() {
                this.$store.state.outputCircCalcBtnIndex = 3;
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_OutputCircuit');
            },
            openExtraPower() {
                this.$store.state.outputCircCalcBtnIndex = 4;
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_Manuel');
            },
            openSummary() {
                this.$store.state.outputCircCalcBtnIndex = 5;
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_Summary');
            },
            openImage() {
                const h = this.$createElement;
                var imgUrl = "/img/Psema_output.jpg";

                const vnode = h('p', { class: "image is-4by3" }, [
                    h('img', { attrs: { src: imgUrl } })
                ])
                this.$buefy.modal.open({
                    content: [vnode]
                })
            },
            setButton(btnIndex) {
                if (btnIndex != 0) {
                    for (let i = 0; i < this.btnList.length; i++) {
                        this.btnList[i].style.backgroundColor = 'white';
                    }
                    this.btnList[btnIndex - 1].style.backgroundColor = '#878787';
                    this.btnList[btnIndex - 1].style.color = 'white';
                } else {
                    for (let i = 0; i < this.btnList.length; i++) {
                        this.btnList[i].style.backgroundColor = 'white';
                    }
                }
                this.$store.state.outputCircCalcBtnIndex = 0;
            }
        }
    };
</script>