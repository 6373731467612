<template>
    <div>
        <hero-bar :has-right-visible="false" menu-icon="desktop-mac"> {{this.$lang.Member.AddNew}} </hero-bar>
        <!--<section class="section is-main-section">
            <font size="5">
                <h1>{{this.$lang.Member.AddNew}}</h1>
            </font>
        </section>-->


        <div id="app" style="padding-left: 1rem; padding-top: 1rem">
            <div class="columns">
                <div class="column">
                    <b style="font-size: 13px; color: #878787; padding-left: 2rem ">{{this.$lang.Member.MemberInfo}}</b>

                    <b-field style="padding-top: 1rem" horizontal :label="this.$lang.Member.Name" :type="{ 'is-danger': hasError_memberName }" :message="this.emptyWarning_memberName">
                        <b-input v-model="memberName" maxlength="40" style="width: 65%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Surname" :type="{ 'is-danger': hasError_memberSurname }" :message="this.emptyWarning_memberSurname">
                        <b-input v-model="memberSurname" maxlength="40" style="width: 65%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Email">
                        <b-input v-model="email" type="email" style="width: 65%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Password" :type="{ 'is-danger': hasError_password }" :message="this.emptyWarning_password">
                        <b-input v-model="password" type="password" @input="password_check" password-reveal style="width: 65%;"></b-input>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : password.length > 7}"><i class="frmIcon fas" :class="password.length > 7 ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_1}}</p>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }"><i class="frmIcon fas" :class="has_uppercase ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_2}}</p>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }"><i class="frmIcon fas" :class="has_lowercase ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_3}}</p>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : has_number }"><i class="frmIcon fas" :class="has_number ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_4}}</p>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : has_special }"><i class="frmIcon fas" :class="has_special ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_5}}</p>
                    </b-field>

                    <b-field style="padding-top: 1rem" horizontal :label="this.$lang.Member.Password_2" :type="{ 'is-danger': hasError_password_2 }" :message="this.emptyWarning_password_2">
                        <b-input v-model="password_2" type="password" password-reveal style="width: 65%;"></b-input>
                    </b-field>
                </div>

                <div class="column">
                    <b style="font-size: 13px; color: #878787; padding-left: 0.5rem">{{this.$lang.Member.CompanyInfo}}</b>

                    <b-field style="padding-top: 1rem" horizontal :label="this.$lang.Member.Title" :type="{ 'is-danger': hasError_title }" :message="this.emptyWarning_title">
                        <b-input v-model="title" maxlength="80" style="width: 52%; padding-top: 0.5rem"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Country">
                        <b-select v-if="countryNameList" v-model="country" @input="getCityList()">
                            <option v-for="option in countryNameList"
                                    :value="option"
                                    :key="option.id">
                                {{ option }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field horizontal v-if="isCityListVisible" :label="this.$lang.Member.City" :type="{ 'is-danger': hasError_city }" :message="this.emptyWarning_city">
                        <b-select v-if="cityNameList" v-model="city" @input="setCityId()">
                            <option v-for="option in cityNameList"
                                    :value="option"
                                    :key="option.id">
                                {{ option }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field style="padding-top: 1rem" horizontal :label="this.$lang.Member.Town" :type="{ 'is-danger': hasError_town }" :message="this.emptyWarning_town">
                        <b-input v-model="town" maxlength="40" style="width: 52%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Phone" :type="{ 'is-danger': hasError_phone }" :message="this.emptyWarning_phone">
                        <div class="field is-grouped">
                            <p class="control" v-if="!this.phonePrefixDisabled">
                                <span class="button is-static">+905</span>
                            </p>
                            <b-input v-model="phone" :maxlength="this.phoneLength" @input="controlPhoneInput()" style="width: 40%;"></b-input>
                        </div>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.PhoneBusiness" :type="{ 'is-danger': hasError_phoneBusiness }" :message="this.emptyWarning_phoneBusiness">
                        <div class="field is-grouped">
                            <p class="control" v-if="!this.phonePrefixDisabled">
                                <span class="button is-static">+90</span>
                            </p>
                            <b-input v-model="phoneBusiness" :maxlength="this.phone_2Length" style="width: 40%;"></b-input>
                        </div>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.CommLanguage" :type="{ 'is-danger': hasError_commLanguage }" :message="this.emptyWarning_commLanguage">
                        <b-select v-if="langNameList" v-model="commLanguage" @input="setWarnings()" disabled>
                            <option v-for="option in langNameList"
                                    :value="option"
                                    :key="option.id">
                                {{ option }}
                            </option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column" v-if="!isWarningsVisible">
                    <b-field style="padding-top: 12rem">
                        <p class="control">
                            <b-button class="button is-primary" @click="saveMember">{{this.$lang.Member.SaveBtn}}</b-button>
                        </p>
                    </b-field>
                </div>

                <div class="column" v-if="isWarningsVisible">
                    <b-field>
                        {{ this.$lang.Member.Warning01 }}
                    </b-field>

                    <b-field class="block" :type="{ 'is-danger': hasError_isAccepted_1 }" :message="this.emptyWarning_isAccepted_1">
                        <b-checkbox v-model="isAccepted_1">
                            {{this.$lang.Member.Warning02}}
                        </b-checkbox>
                    </b-field>

                    <b-field class="block" :type="{ 'is-danger': hasError_isAccepted_2 }" :message="this.emptyWarning_isAccepted_2">
                        <b-checkbox v-model="isAccepted_2">
                            {{this.$lang.Member.Warning03}}
                        </b-checkbox>
                    </b-field>

                    <b-field>
                        <p class="control">
                            <b-button class="button is-primary" @click="controlInputs()">{{this.$lang.Member.SaveBtn}}</b-button>
                        </p>
                    </b-field>
                </div>

                <b-modal v-model="isModalActive" :width="960">
                    <div class="card">

                        <div class="card-content">
                            <p class="title is-4">{{ this.$lang.Member.VerifyMember }}</p>

                            <b-field horizontal :label="this.$lang.Member.MailVerifyText" :type="{ 'is-danger': !codesValid }" :message="this.InvalidCode">
                                <b-input v-model="mailCode" pattern="\d*" style="width: 25%;"></b-input>
                            </b-field>
                            <b-field horizontal :label="this.$lang.Member.PhoneVerifyText" :type="{ 'is-danger': !codesValid }" :message="this.InvalidCode">
                                <b-input v-model="SMSCode" pattern="\d*" style="width: 25%;"></b-input>
                            </b-field>

                            <div class="content">
                                <footer class="modal-card-foot" style="align-items:start">
                                    <b-field style="margin-left: 20px;">
                                        <b-button :label="this.$lang.Member.Verify"
                                                  @click="verifyMember()" />
                                    </b-field>
                                    <b-field>
                                        <b-input v-model="countdown" type="text" disabled class="countdown"></b-input>
                                    </b-field>
                                    <b-field>
                                        <b-button :disabled="buttonDisabled"
                                                  :label="this.$lang.Member.Resend"
                                                  @click="resendCodes()" />
                                    </b-field>
                                </footer>
                            </div>
                        </div>

                    </div>
                </b-modal>

            </div>
        </div>
    </div>
</template>

<script type="text/javascript" src="path/to/zxcvbn.js"></script>
<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'AddMember',
        data() {
            return {
                isActive: true,
                isSaveBtnClicked: false,
                canMemberSave: false,
                isWarningsVisible: false,
                isAccepted_1: false,
                isAccepted_2: false,
                memberName: '',
                memberSurname: '',
                email: '',
                password: '',
                password_2: '',
                has_number: false,
                has_lowercase: false,
                has_uppercase: false,
                has_special: false,


                title: '',
                country: '',
                countryId: '',
                countryNameList: [],
                countryList: [],
                city: '',
                cityId: '',
                cityList: [],
                cityNameList: [],
                isCityListVisible: false,
                town: '',
                phone: '',
                phoneBusiness: '',
                langList: [],
                langId: '',
                langNameList: [],
                commLanguage: '',
                phoneLength: 9,
                phone_2Length: 10,
                phonePrefixDisabled: false,
                hasError_isAccepted_1: false,
                hasError_isAccepted_2: false,
                hasError_memberName: false,
                emptyWarning_memberName: '',
                hasError_memberSurname: false,
                emptyWarning_memberSurname: '',
                hasError_email: false,
                hasError_password: false,
                emptyWarning_password: '',
                hasError_password_2: false,
                emptyWarning_password_2: '',
                hasError_title: false,
                emptyWarning_title: '',
                hasError_country: false,
                emptyWarning_country: '',
                hasError_city: false,
                emptyWarning_city: '',
                hasError_town: false,
                emptyWarning_town: '',
                hasError_phone: false,
                emptyWarning_phone: '',
                hasError_phoneBusiness: false,
                emptyWarning_phoneBusiness: '',
                hasError_commLanguage: false,
                emptyWarning_commLanguage: '',
                emptyWarning_isAccepted_1: '',
                emptyWarning_isAccepted_2: '',

                tempMobilePhone: '',
                tempBusinessPhone: '',

                isModalActive: false,
                mailCode: '',
                SMSCode: '',
                TempMemberId: '',

                buttonDisabled: false,
                countdown: "3:00", // Başlangıçta 3 dakika gösterilecek
                timer: null,
                codesValid: true,
                InvalidCode: '',

                memberEncrypted: [],
                member: []
            }
        },
        mounted() {
            this.getCountryList();
            this.getLangList();

            this.$store.state.isFooterFixed = true;
        },
        methods: {
            password_check() {
                this.has_number = /\d/.test(this.password);
                this.has_lowercase = /[a-z]/.test(this.password);
                this.has_uppercase = /[A-Z]/.test(this.password);
                this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
            },

            getCountryList() {
                this.countryNameList = [];
                this.countryList = [];
                api.members.getCountries().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.countryNameList.push(e.name);
                            this.countryList.push(e);
                        });

                        if (this.$lang.Format.Lang == 'tr-TR') {
                            this.country = 'Türkiye';
                            this.getCityList();
                        }
                    }
                });
            },
            getCityList() {
                if (this.country == 'Türkiye') {
                    this.isCityListVisible = true;
                } else {
                    this.isCityListVisible = false;
                }

                var countryIndex = this.countryNameList.indexOf(this.country);
                this.countryId = this.countryList[countryIndex].id;
                if (this.isCityListVisible) {
                    this.cityNameList = [];
                    this.cityList = [];
                    api.members.getCities(this.countryId).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            res.data.forEach((e) => {
                                this.cityNameList.push(e.name);
                                this.cityList.push(e);
                            });

                            this.setCityId();
                        }
                    });
                }
            },
            setCityId() {
                if (this.city != '' && this.city != null) {
                    var cityIndex = this.cityNameList.indexOf(this.city);
                    this.cityId = this.cityList[cityIndex].id;
                }
            },
            getLangList() {
                this.langNameList = [];
                this.langList = [];
                api.members.getCommLanguages().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.langNameList.push(e.name);
                            this.langList.push(e);
                        });

                        if (this.$lang.Format.Lang == 'tr-TR') {
                            this.country = 'Turkey';
                            this.isCityListVisible = true;
                            this.commLanguage = 'Türkçe';
                            this.isWarningsVisible = true;
                        } else {
                            this.commLanguage = 'İngilizce';
                            this.isWarningsVisible = false;
                        }

                        var langIndex = this.langNameList.indexOf(this.commLanguage);
                        this.langId = this.langList[langIndex].id;
                    }
                });
            },
            setWarnings() {
                var langIndex = this.langNameList.indexOf(this.commLanguage);
                this.langId = this.langList[langIndex].id;

                if (this.commLanguage == 'Türkçe') {
                    this.isWarningsVisible = true;
                    this.phonePrefixDisabled = false;
                    this.phoneLength = 9;
                    this.phone_2Length = 10;
                    this.country = 'Türkiye';
                } else {
                    this.isCityListVisible = false;
                    this.isWarningsVisible = false;
                    this.phonePrefixDisabled = true;
                    this.phoneLength = 15;
                    this.phone_2Length = 15;
                    this.country = '';
                }
            },

            showWarning(type) {
                var _message = '';
                if (type == 'Email') {
                    _message = this.$lang.Member.EmailError;
                } else if (type == 'Phone') {
                    _message = this.$lang.Member.MobilePhone;
                }
                else if (type = "PersonalEmail") {
                    _message = this.$lang.Member.PersonalEmailError;
                }

                this.$buefy.dialog.confirm({
                    title: this.$lang.Member.ErrorTitle,
                    message: _message,
                    confirmText: this.$lang.Member.Confirm_2,
                    type: 'is-warning',
                    hasIcon: true
                })
            },
            saveMember() {
                if (this.canMemberSave) {
                    api.members.addNewMember(this.memberEncrypted).then((res1) => {
                        if (res1.isError) {
                            console.log("error ");
                        } else {
                            if (this.$store.state.canReportPageOpen) {
                                api.members.getMember(resCrypto.data.Email, resCrypto.data.Passwordd).then((res2) => {
                                    if (res2 == null) {
                                    } else {
                                        if (res2.isError) {
                                            console.log("error ");
                                        } else {
                                            this.$store.commit('user', res2.data);
                                            var memberId = res2.data.Id;
                                            this.$store.state.memberId = memberId + '';
                                            this.$store.state.isAdmin = res2.data.IsAdmin;

                                            api.panels.updateProjectMemberId(this.$store.state.projectId, memberId).then((res3) => {
                                                if (res3.isError) {
                                                    console.log("error ");
                                                } else {
                                                    this.updateAcceptLog();

                                                    this.resetInputs();
                                                    this.$buefy.toast.open(this.$lang.Member.MemberAdded);

                                                    router.push('/reportpdfpage');
                                                }
                                            });
                                        }
                                    }
                                });
                            } else {
                                this.resetInputs();
                                this.$buefy.toast.open(this.$lang.Member.MemberAdded);

                                router.push('/LoginMember');
                            }
                        }
                    });
                }
            },
            updateAcceptLog() {
                if (this.$store.state.memberId != 'TestMember') {
                    const log = {
                        MemberId: this.$store.state.memberId,
                        ProjectId: this.$store.state.projectId,
                        OldProjectId: this.$store.state.projectId
                    };
                    api.panels.updateAcceptLog(log).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {

                        }
                    });
                }
            },
            controlPhoneInput() {
                this.phone = this.phone.replace(/\D/g, '');
                for (let i = 0; i < this.phone.length; i++) {
                    if (this.phone.charCodeAt(i) < 48 || this.phone.charCodeAt(i) > 57) {
                        this.phone = this.phone.replace(this.phone[i], '');
                        return;
                    }
                }

                for (let i = 0; i < this.phoneBusiness.length; i++) {
                    if (this.phoneBusiness.charCodeAt(i) < 48 || this.phoneBusiness.charCodeAt(i) > 57) {
                        this.phoneBusiness = this.phoneBusiness.replace(this.phoneBusiness[i], '');
                        return;
                    }
                }
            },
            isPhoneNumberValid(num, buss) {
                if (!buss) {
                    if (num.length != 9) {
                        return false;
                    }
                } else {
                    if (num.length != 10) {
                        return false;
                    }
                }

                for (let i = 0; i < num.length; i++) {
                    if (num.charCodeAt(i) < 48 || num.charCodeAt(i) > 57) {
                        return false;
                    }
                }
                return true;
            },

            controlPassword() {
                if (this.password.length <= 7 || !this.has_number || !this.has_lowercase || !this.has_uppercase || !this.has_special) {
                    this.hasError_password = true;
                    this.canMemberSave = false;
                } else {
                    this.hasError_password = false;

                    if (this.password_2 != this.password) {
                        this.hasError_password_2 = true;
                        this.canMemberSave = false;
                    } else {
                        this.hasError_password_2 = false;
                        this.canMemberSave = true;
                    }
                }
            },
            controlInputs() {
                this.isSaveBtnClicked = true;
                if (this.memberName == '') {
                    this.hasError_memberName = true;
                    this.emptyWarning_memberName = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (this.memberSurname == '') {
                    this.hasError_memberSurname = true;
                    this.emptyWarning_memberSurname = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (this.email == '') {
                    this.hasError_email = true;
                } else if (this.password == '') {
                    this.hasError_password = true;
                    this.emptyWarning_password = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (this.password_2 == '') {
                    this.hasError_password_2 = true;
                    this.emptyWarning_password_2 = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (this.title == '') {
                    this.hasError_title = true;
                    this.emptyWarning_title = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (this.city == '' && this.isCityListVisible) {
                    this.hasError_city = true;
                    this.emptyWarning_city = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (this.town == '') {
                    this.hasError_town = true;
                    this.emptyWarning_town = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (!this.isPhoneNumberValid(this.phone, false) && this.phone != '') {
                    this.hasError_phone = true;
                    this.emptyWarning_phone = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (!this.isPhoneNumberValid(this.phoneBusiness, true)) {
                    this.hasError_phoneBusiness = true;
                    this.emptyWarning_phoneBusiness = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (this.commLanguage == '') {
                    this.hasError_commLanguage = true;
                    this.emptyWarning_commLanguage = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else if (this.isAccepted_1 == false) {
                    this.hasError_isAccepted_1 = true;
                    this.emptyWarning_isAccepted_1 = this.$lang.Menus.EmptyWarning;
                    this.canMemberSave = false;
                } else {
                    this.controlPassword();
                    if (this.password == this.password_2) {
                        this.hasError_password_2 = false;
                        this.canMemberSave = true;
                    } else {
                        this.hasError_password_2 = true;
                        this.emptyWarning_password_2 = this.$lang.Menus.EmptyWarning;
                        this.canMemberSave = false;
                    }
                }

                if (this.memberName != '') {
                    this.hasError_memberName = false;
                }
                if (this.memberSurname != '') {
                    this.hasError_memberSurname = false;
                }
                if (this.email != '') {
                    this.hasError_email = false;
                }
                if (this.password != '') {
                    this.hasError_password = false;
                }
                if (this.title != '') {
                    this.hasError_title = false;
                }
                if (this.city != '') {
                    this.hasError_city = false;
                }
                if (this.town != '') {
                    this.hasError_town = false;
                }
                if (this.isPhoneNumberValid(this.phone, false)) {
                    this.hasError_phone = false;
                }
                if (this.isPhoneNumberValid(this.phoneBusiness, true)) {
                    this.hasError_phoneBusiness = false;
                }
                if (this.commLanguage != '') {
                    this.hasError_commLanguage = false;
                }

                if (this.isAccepted_1 == false) {
                    this.hasError_isAccepted_1 = true;
                }
                if (this.isAccepted_1 == true) {
                    this.hasError_isAccepted_1 = false;
                }
                if (this.isAccepted_2 == false) {
                    this.hasError_isAccepted_2 = true;
                }
                if (this.isAccepted_2 == true) {
                    this.hasError_isAccepted_2 = false;
                }

                this.controlMember();
            },
            resetInputs() {
                this.memberName = '';
                this.memberSurname = '';
                this.email = '';
                this.password = '';
                this.password_2 = '';
                this.title = '';
                this.country = '';
                this.city = '';
                this.town = '';
                this.phone = '';
                this.phoneBusiness = '';
                this.commLanguage = '';
            },
            controlMember() {
                this.controlPersonalMail(this.email);

                if (this.canMemberSave) {

                    if (this.commLanguage == 'Türkçe') {
                        this.tempMobilePhone = '+905' + this.phone;
                    } else {
                        this.tempMobilePhone = this.phone;
                    }
                    var tempPhoneNumber = '';
                    if (this.commLanguage == 'Türkçe') {
                        this.tempBusinessNumber = '+90' + this.phoneBusiness;
                    } else {
                        this.tempBusinessNumber = this.phoneBusiness;
                    }

                    const member = {
                        CrmMemberId: null,
                        Name: this.memberName,
                        SurName: this.memberSurname,
                        Email: this.email,
                        Passwordd: this.password,
                        Title: this.title,
                        Country: this.country,
                        CountryId: this.countryId,
                        City: this.city,
                        CityId: this.cityId,
                        Town: this.town,
                        Phone: this.tempMobilePhone,
                        PhoneBusiness: this.tempBusinessNumber,
                        CommLanguage: this.commLanguage,
                        CommLangId: this.langId,
                        AllowComm: this.isAccepted_2
                    };

                    this.member = member;

                    api.members.encryptMember(member).then((resCrypto) => {
                        if (resCrypto.isError) {
                            console.log("error ");
                        } else {
                            api.members.IsMailUnique(resCrypto.data.Email).then((retMail) => {
                                if (retMail.isError) {
                                    console.log("error ");
                                } else {
                                    if (retMail.data == false) {
                                        this.showWarning('Email');
                                    } else if (retMail.data == true) {
                                        api.members.IsPhoneNumberUnique(resCrypto.data.Phone).then((retPhone) => {
                                            if (retPhone.isError) {
                                                console.log("error ");
                                            } else {
                                                if (retPhone.data == false) {
                                                    this.showWarning('Phone');
                                                } else if (retPhone.data == true) {
                                                    const memberEncrypted = {
                                                        CrmMemberId: resCrypto.data.CrmMemberId,
                                                        Name: resCrypto.data.Name,
                                                        SurName: resCrypto.data.SurName,
                                                        Email: resCrypto.data.Email,
                                                        Passwordd: resCrypto.data.Passwordd,
                                                        Title: resCrypto.data.Title,
                                                        Country: resCrypto.data.Country,
                                                        CountryId: resCrypto.data.CountryId,
                                                        City: resCrypto.data.City,
                                                        CityId: resCrypto.data.CityId,
                                                        Town: resCrypto.data.Town,
                                                        Phone: resCrypto.data.Phone,
                                                        PhoneBusiness: resCrypto.data.PhoneBusiness,
                                                        CommLanguage: resCrypto.data.CommLanguage,
                                                        CommLangId: resCrypto.data.CommLangId,
                                                        AllowComm: resCrypto.data.AllowComm
                                                    };

                                                    this.memberEncrypted = memberEncrypted;
                                                    this.openVerifyModal();
                                                }
                                            }
                                        });
                                    }
                                }
                            });
                        }
                    });
                }
            },
            openVerifyModal() {
                if (this.canMemberSave) {
                    //Modal açılırken Mail ve Sms at;
                    api.members.addTempMember(this.member).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.TempMemberId = res.data;
                        }
                    });

                    this.isModalActive = true;
                    this.startCountdown();
                }

            },
            verifyMember() {
                const code = {
                    TempMemberId: this.TempMemberId,
                    MailCode: this.mailCode,
                    SMSCode: this.SMSCode
                };

                api.members.verifyMember(code).then((res) => {
                    if (res.isError) {
                        this.codesValid = false;
                        console.log("error ");
                    } else {
                        if (res.data) {
                            this.$buefy.toast.open(this.$lang.Member.MembershipVerified);
                            this.saveMember();
                        } else {
                            this.codesValid = false;
                            this.InvalidCode = this.$lang.Member.CheckCode;
                        }

                    }
                });
            },
            resendCodes() {
                const code = {
                    TempMemberId: this.TempMemberId,
                    MailAddress: this.email,
                    PhoneNumber: this.tempMobilePhone
                };

                api.members.resendCodes(code).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.$buefy.toast.open(this.$lang.Member.MembershipVerified);
                        this.saveMember();
                    }
                });

                this.startCountdown();
            },
            startCountdown() {
                this.buttonDisabled = true;
                let countdownSeconds = 180;

                this.timer = setInterval(() => {
                    if (countdownSeconds > 0) {
                        // Geri sayımı güncelle
                        let minutes = Math.floor(countdownSeconds / 60);
                        let seconds = countdownSeconds % 60;
                        this.countdown = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

                        // Her saniye bir azalt
                        countdownSeconds--;
                    } else {
                        clearInterval(this.timer);
                        this.countdown = "0:00";

                        this.buttonDisabled = false;
                    }
                }, 1000); // Her saniyede bir güncelle
            },
            controlPersonalMail(mail) {
                const domains = ['gmail.com', 'hotmail.com', 'yahoo.com'];
                const emailDomain = mail.split('@')[1];

                if (domains.includes(emailDomain)) {
                    this.showWarning('PersonalEmail');
                    this.canMemberSave = false;
                }
            }
        }
    }
</script>

<style>
    .container {
        width: 400px;
        margin: 50px auto;
        background: white;
        padding: 10px;
        font-family: Arial, Helvetica, sans-serif, sans-serif;
        border-radius: 3px;
    }

    h1 {
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
    }

    .frmField {
        color: #495057;
        line-height: 1.25;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        border: 0;
        padding: 10px;
        border: 1px solid #dbdbdb;
        border-radius: 3px;
        width: 90%;
    }

    .frmLabel {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .frmValidation {
        font-size: 13px;
    }

    .frmValidation--passed {
        color: #8fce00;
    }

    .frmIcon {
        color: #EB0029;
    }

    .frmValidation--passed .frmIcon {
        color: #8fce00;
    }

    .howToBuild {
        text-align: center;
        color: purple;
    }

        .howToBuild a {
            color: grey;
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;
        }

    /*.label {
        width: 250px;*/
    /*text-align: justify;*/
    /*}*/

    .countdown {
        text-align: center;
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        background-color: hsl(0, 0%, 100%) !important;
        border-color: hsl(0, 0%, 86%) !important;
        border-width: 1px;
        color: hsl(0, 0%, 21%);
        border: 1px solid transparent;
        border-radius: 4px;
        box-shadow: none;
    }

    .is-danger {
        border-color: red !important;
        background-color: #ffe5e5 !important;
        color: red !important;
    }
</style>
