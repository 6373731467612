<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            <span class="heroTitle">
                {{this.$lang.Menus.Users}}
            </span>
            &nbsp;
            <!--<span class="logState" style="font-size:16px">{{this.$lang.Menus.LogState}}</span>-->

            <p v-if="this.backBtnActive == true" class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" class="section is-main-section">
            <div v-if="this.$store.state.memberId != 'TestMember' && this.$store.state.isAdmin == 1">
                <div class="tableMember">
                    <b-table :data="members"
                             :paginated="isPaginated"
                             :per-page="perPage">
                        <b-table-column field="FullName" :label="this.$lang.AdminExcel.UserName" :td-attrs="columnTdAttrs" v-slot="props">
                            {{ props.row.Name }} {{ props.row.SurName }}
                        </b-table-column>
                        <b-table-column field="Email" :label="this.$lang.Member.Email" :td-attrs="columnTdAttrs" v-slot="props">
                            {{ props.row.Email }}
                        </b-table-column>
                        <b-table-column field="Title" :label="this.$lang.Member.Title" :td-attrs="columnTdAttrs" v-slot="props">
                            {{ props.row.Title }}
                        </b-table-column>
                        <b-table-column field="Country" :label="this.$lang.Member.Country" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true">
                            {{ props.row.Country }}
                        </b-table-column>
                        <b-table-column field="City" :label="this.$lang.Member.City" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true">
                            {{ props.row.City }}
                        </b-table-column>
                        <b-table-column field="Town" :label="this.$lang.Member.Town" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true">
                            {{ props.row.Town }}
                        </b-table-column>
                        <b-table-column field="Phone" :label="this.$lang.Member.Phone" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                            {{ props.row.Phone }}
                        </b-table-column>
                        <b-table-column field="Phone" :label="this.$lang.Member.PhoneBusiness" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                            {{ props.row.PhoneBusiness }}
                        </b-table-column>
                        <b-table-column field="CommLanguage" :label="this.$lang.Member.CommLanguage" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                            {{ props.row.CommLanguage }}
                        </b-table-column>
                        <b-table-column field="AllowKVKK" :label="this.$lang.Member.AllowKVKK" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                            {{ props.row.AllowKVKK }}
                        </b-table-column>
                        <b-table-column field="AcceptDate" :label="this.$lang.Member.AllowComm" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                            {{ props.row.AllowComm }}
                        </b-table-column>
                        <b-table-column field="CreateDate" :label="this.$lang.AdminExcel.CreateDate" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                            {{ formatDate(props.row.CreateDate) }}
                        </b-table-column>
                    </b-table>
                </div>
                <div class="downloadButton">
                    <b-button class="button is-gray" @click="downloadMembers()">{{this.$lang.AdminExcel.Download}}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'Users',
        data() {
            return {
                isActive: true,
                isLoading: false,
                firstDate: new Date(),
                secondDate: new Date(),
                showWeekNumber: false,
                enableSeconds: false,
                hourFormat: undefined, // Browser locale
                locale: undefined, // Browser locale
                firstDayOfWeek: undefined, // 0 - Sunday
                isPaginated: true,
                perPage: 10,
                members: [],
                backBtnActive: false
            }
        },
        mounted() {
            if (this.$store.state.previousRoute == 'projectInfos' || this.$store.state.previousRoute == 'panelInfos' || this.$store.state.previousRoute == 'envConditions' ||
                this.$store.state.previousRoute == 'calc_Busbar' || this.$store.state.previousRoute == 'calc_InputCircuit' || this.$store.state.previousRoute == 'calc_OutputCircuit' ||
                this.$store.state.previousRoute == 'calc_Manuel' || this.$store.state.previousRoute == 'calc_Summary' || this.$store.state.previousRoute == 'results' ||
                this.$store.state.previousRoute == 'reportPdfPage' || this.$store.state.previousRoute == 'calculation') {
                this.backBtnActive = true;
            } else {
                this.backBtnActive = false;
            }
            this.getMembersTable();

            this.$store.state.isFooterFixed = true;
        },
        destroyed() {
            this.$store.state.isFooterFixed = false;
        },
        methods: {
            getMembersTable() {
                this.isLoading = true;
                this.members = [];
                api.panels.getMembersList().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            e.AllowKVKK = true;
                            this.members.push(e);
                        });
                        this.isLoading = false;
                    }
                });
            },
            clearDateTime_1() {
                this.firstDate = null
            },
            clearDateTime_2() {
                this.secondDate = null
            },
            formatDate(date) {
                if (date == null) {
                    return '-';
                } else {
                    var yil = date.getFullYear();
                    var ay = ('0' + (date.getMonth() + 1)).slice(-2);
                    var gun = ('0' + date.getDate()).slice(-2);
                    var saat = ('0' + date.getHours()).slice(-2);
                    var dakika = ('0' + date.getMinutes()).slice(-2);
                    var saniye = ('0' + date.getSeconds()).slice(-2);

                    var formatliTarih = yil + '-' + ay + '-' + gun + ' ' + saat + ':' + dakika + ':' + saniye;

                    return formatliTarih;
                }
            },

            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },
            previous() {
                router.push('/' + this.$store.state.previousRoute);
            },
            downloadMembers() {
                this.isLoading = true;

                var path = 'GetMembers_Excel?langCode=' + this.$lang.Format.Lang
                api.reportApi.downloadMembers(this.$lang.Menus.Users, path).then((res) => {
                    this.sendEmail();
                    this.isLoading = false;
                });
            },
            sendEmail() {
                var memberId = this.$store.state.memberId;
                api.members.SendUserListDownloadedMail(memberId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                    }
                });
            },
        }
    };
</script>
<style>
    @media only screen and (max-width: 424px) {
        .heroTitle {
            font-size: 18px !important;
        }

        .logState {
            font-size: 14px !important;
        }
    }

    @media only screen and (max-width: 384px) {
        .heroTitle {
            font-size: 16px !important;
        }

        .logState {
            font-size: 12px !important;
        }
    }

    @media only screen and (max-width: 768px) {
        .downloadButton {
            margin-top: auto !important;
            padding-bottom: 50px;
            justify-content: center !important;
        }
    }

    .downloadButton {
        display: flex;
        justify-content: flex-end;
        margin: 1.25rem;
    }

    .tableMember {
        overflow-x: auto;
    }
</style>