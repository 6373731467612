<template>
    <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <hero-bar :has-right-visible="false" menu-icon="desktop-mac">
            {{this.$lang.Menus.MyProjects}}
            <!--<font size="7" color="#ff0000">
                E-KABIN
            </font>
            <font size="5">
                Isınma Hesabı Yazılımı
            </font>-->
        </hero-bar>

        <div id="app" class="section is-main-section">
            <b-table :data="projectsdata"
                     :paginated="isPaginated"
                     :per-page="perPage">
                <b-table-column field="ProjectName" :label="this.$lang.MyProjects.ProjectName" :td-attrs="columnTdAttrs" v-slot="props" width="19%">
                    {{ props.row.ProjectName }}
                </b-table-column>
                <b-table-column field="PanelName" :label="this.$lang.MyProjects.PanelName" :td-attrs="columnTdAttrs" v-slot="props" width="19%">
                    {{ props.row.PanelName }}
                </b-table-column>
                <b-table-column field="PanelNo" :label="this.$lang.MyProjects.PanelNo" :td-attrs="columnTdAttrs" v-slot="props" width="18%">
                    {{ props.row.PanelNo }}
                </b-table-column>
                <b-table-column field="EyeNumber" :label="$lang.ProjectInfos.EyeNumber + ' / ' + $lang.ProjectInfos.TotalEyesNumber" :td-attrs="columnTdAttrs" v-slot="props" width="19%">
                    {{ props.row.EyeNo }} / {{ props.row.TotalEyesNumber }}
                </b-table-column>
                <b-table-column field="ReportDate" :label="this.$lang.MyProjects.Date" :td-attrs="columnTdAttrs" v-slot="props" width="15%" sortable>
                    {{ props.row.ReportDate }}
                </b-table-column>

                <b-table-column field="Action" v-slot="props">
                    <div class="projectIcons">
                        <button class="button is-small is-light" style="margin-right: 5px" @click="openProjectDetail(props.row)">
                            <b-icon :title="$lang.MyProjects.Edit" icon="pen" size="is-small"></b-icon>
                        </button>
                        <button class="button is-small is-light" style="margin-right: 5px; width: 5px " @click="copyProjectDetail(props.row)">
                            <div class="material-symbols-outlined"
                                 :title="$lang.MyProjects.Copy">
                                content_copy
                            </div>
                        </button>
                        <button v-if="props.row.IsCompleted == 0 || isAdmin == 1" class="button is-small is-danger" style="margin-right: 5px" @click="deleteProject(props.row)">
                            <b-icon :title="$lang.MyProjects.Delete" icon="delete" size="is-small"></b-icon>
                        </button>
                        <button v-if="props.row.IsCompleted == 1" class="button is-small is-light" @click="openPdf(props.row)" style="width: 5px">
                            <div class="material-symbols-outlined"
                                 :title="$lang.MyProjects.Report">
                                picture_as_pdf
                            </div>
                        </button>
                        <button v-if="!props.row.IsValid" class="button is-small is-light" @click="openFanPdf(props.row)" style="width: 5px">
                            <div class="material-symbols-outlined"
                                 :title="$lang.MyProjects.FanReport">
                                picture_as_pdf
                            </div>
                        </button>
                    </div>
                </b-table-column>
            </b-table>

        </div>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'MyProjects',
        data() {
            return {
                isActive: true,
                isLoading: false,
                isPaginated: true,
                perPage: 10,
                firstDate: new Date(),
                secondDate: new Date(),
                showWeekNumber: false,
                enableSeconds: false,
                hourFormat: undefined, // Browser locale
                locale: undefined, // Browser locale
                firstDayOfWeek: undefined, // 0 - Sunday
                projectsdata: [],
                isAdmin: ''
            }
        },
        mounted() {
            this.$store.state.isFooterFixed = true;
            this.getProjectsTable();
            this.isAdmin = this.$store.state.isAdmin;
        },
        destroyed() {
            this.$store.state.isFooterFixed = false;
        },
        methods: {
            clearDateTime_1() {
                this.firstDate = null
            },
            clearDateTime_2() {
                this.secondDate = null
            },
            formatDate(date) {
                const year = date.toLocaleString('default', { year: 'numeric' });
                const month = date.toLocaleString('default', {
                    month: '2-digit',
                });
                const day = date.toLocaleString('default', { day: '2-digit' });

                return [year, month, day].join('-');
            },
            downloadExcel() {
                this.isLoading = true;
                var first = this.formatDate(this.firstDate).toString();
                var second = this.formatDate(this.secondDate).toString();

                var s = 'GetProjects_Excel?First=' + first + '&Second=' + second;
                api.reportApi.getExcel_CircuitDevices('Panel', null, null, 'Cihaz Raporu', s).then((res) => {
                    this.isLoading = false;
                });
            },

            getOutputCircNumber() {
                api.panels.getMaxCircuitIndex_OutputCircuitCalculationDevices(this.$store.state.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        if (res.data == null || res.data == 0) {
                            this.$store.state.circIndex = 1;
                        } else {
                            this.$store.state.circIndex = res.data + 1;
                        }
                    }
                });
            },

            openProjectDetail(row) {
                this.$store.state.projectId = row.ProjectId;
                this.$store.state.isPrevProject = true;
                this.$store.activeStep = 0;
                this.$store.state.calcIndex = row.CalcTypeId;
                if (this.$store.state.calcIndex == 3) {
                    this.getOutputCircNumber();
                } else {
                    this.$store.state.circIndex = 0;
                }
                this.$store.canPanelInfosOpen = false;
                this.$store.canEnvConditionsOpen = false;
                this.$store.canCalculationOpen = false;
                this.$store.canResultOpen = false;
                this.$store.canReportPageOpen = false;
                this.$store.canFanCalcOpen = false;
                this.$store.state.isEdit = true;

                this.$store.state.areHiddenStepsVisible = false;

                var payload = { langCode: this.$lang.Format.Lang, projectName: row.ProjectName, panelName: row.PanelName, panelNo: row.PanelNo, lastUserName: row.LastUserName, panelManifacturer: row.PanelManifacturer, ratedCurrentIndex: row.RatedCurrentIndex, panelRatedCurrent: row.PanelRatedCurrent, totalEyesNumber: row.TotalEyesNumber, eyesNumber: row.EyeNo, isCompleted: row.IsCompleted };
                this.$store.commit('setProjectInfos', payload);

                var payload2 = { panelType: row.PanelType, panelTypeIndex: row.PanelTypeId, panelDimension: row.PanelDimension, panelIP: row.IP, selectedIP_Id: 0, Sair: row.Sair, surfaceExplanation: row.SurfaceInfo, virtualSurface: row.VirtualSurface, virtualSurfaceIndex: row.VirtualSurfaceIndex, virtualSurfaceOption: row.VirtualSurfaceOption, seperationNumber: row.SeperationNumber, ae_value: row.Ae, ae_valueList: [] };
                this.$store.commit('setPanelInfos', payload2);

                var payload3 = { envTemperature: row.Ta, maxTemperature: row.Tmax, humidity: "", altitude: row.Altitude };
                this.$store.commit('setEnvConditionDatas', payload3);

                var payload4 = { totalLostPower_1: row.KnownLostPower };
                this.$store.commit('setCalculationDatas', payload4);

                var payload5 = { inPanelTemperature: row.InPanelTemp, envMeasureTemperature: row.InEnvTemp, totalLostPower_2: row.InPanelLostPower };
                this.$store.commit('setCalculationDatas_2', payload5);

                var payload6 = { InputCircuitDef: "", P_InputCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_InputCircuits', payload6);

                var payload7 = { OutputCircuitDef: "", P_OutputCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_OutputCircuits', payload7);

                var payload8 = { ManuelCircuitDef: "", P_ManuelCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_ManuelCircuits', payload8);

                var payload9 = { envTemperature: 0, maxTemperature: 0, P_890: 0, P_Total: 0, result_630A: 0 };
                this.$store.commit('setResultInfos_630A', payload9);

                var payload10 = { value_k: 0, n_value: 0, value_d: 0, value_x: 0, deltaT_0_5: 0, Ab_val: 0, height_val: 0, value_f: 0, value_g: 0, value_c: 0, deltaT_0_75: 0, deltaT_1: 0, result_1600A: 0 };
                this.$store.commit('setResultInfos_1600A', payload10);

                this.$store.activeStep = 0;
                router.push('/projectInfos');
            },

            copyProjectDetail(row) {
                this.$store.state.isPrevProject = false;
                this.$store.activeStep = 0;
                this.$store.state.calcIndex = row.CalcTypeId;
                if (this.$store.state.calcIndex == 3) {
                    this.getOutputCircNumber();
                } else {
                    this.$store.state.circIndex = 0;
                }
                this.$store.canPanelInfosOpen = false;
                this.$store.canEnvConditionsOpen = false;
                this.$store.canCalculationOpen = false;
                this.$store.canResultOpen = false;
                this.$store.canReportPageOpen = false;
                this.$store.canFanCalcOpen = false;
                this.$store.state.isCopyProject = true;
                this.$store.state.isEdit = false;

                var uuid = this.generateUUID();
                this.$store.state.projectId = uuid + "_Temp";


                this.$store.state.areHiddenStepsVisible = false;

                var copyProjectName = row.ProjectName + '_copy'; 

                var payload = { langCode: this.$lang.Format.Lang, projectName: copyProjectName, panelName: row.PanelName, panelNo: row.PanelNo, lastUserName: row.LastUserName, panelManifacturer: row.PanelManifacturer, ratedCurrentIndex: row.RatedCurrentIndex, panelRatedCurrent: row.PanelRatedCurrent, totalEyesNumber: row.TotalEyesNumber, eyesNumber: row.EyeNo, isCompleted: false };
                this.$store.commit('setProjectInfos', payload);

                var payload2 = { panelType: row.PanelType, panelTypeIndex: row.PanelTypeId, panelDimension: row.PanelDimension, panelIP: row.IP, selectedIP_Id: 0, Sair: row.Sair, surfaceExplanation: row.SurfaceInfo, virtualSurface: row.VirtualSurface, virtualSurfaceIndex: row.VirtualSurfaceIndex, virtualSurfaceOption: row.VirtualSurfaceOption, seperationNumber: row.SeperationNumber, ae_value: row.Ae, ae_valueList: [] };
                this.$store.commit('setPanelInfos', payload2);

                var payload3 = { envTemperature: row.Ta, maxTemperature: row.Tmax, humidity: "", altitude: row.Altitude };
                this.$store.commit('setEnvConditionDatas', payload3);

                var payload4 = { totalLostPower_1: row.KnownLostPower };
                this.$store.commit('setCalculationDatas', payload4);

                var payload5 = { inPanelTemperature: row.InPanelTemp, envMeasureTemperature: row.InEnvTemp, totalLostPower_2: row.InPanelLostPower };
                this.$store.commit('setCalculationDatas_2', payload5);

                var payload6 = { InputCircuitDef: "", P_InputCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_InputCircuits', payload6);

                var payload7 = { OutputCircuitDef: "", P_OutputCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_OutputCircuits', payload7);

                var payload8 = { ManuelCircuitDef: "", P_ManuelCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_ManuelCircuits', payload8);

                var payload9 = { envTemperature: 0, maxTemperature: 0, P_890: 0, P_Total: 0, result_630A: 0 };
                this.$store.commit('setResultInfos_630A', payload9);

                var payload10 = { value_k: 0, n_value: 0, value_d: 0, value_x: 0, deltaT_0_5: 0, Ab_val: 0, height_val: 0, value_f: 0, value_g: 0, value_c: 0, deltaT_0_75: 0, deltaT_1: 0, result_1600A: 0 };
                this.$store.commit('setResultInfos_1600A', payload10);

                this.$store.activeStep = 0;
                router.push('/projectInfos');
            },

            getProjectsTable() {
                this.projectsdata = [];
                api.panels.getProjects(this.$store.state.memberId + '').then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.projectsdata.push(e);
                        });
                    }
                });
            },
            deleteProject(row) {
                this.$buefy.dialog.confirm({
                    title: 'Silme İşlemi',
                    message: 'Projeyi silmek istediğinize emin misiniz?',
                    confirmText: 'Projeyi Sil',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>
                        api.panels.deleteProject(row.ProjectId).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.deleteCalculations(row.ProjectId);
                                this.$buefy.toast.open('Silindi!');
                                this.getProjectsTable();
                            }
                        })
                })
            },
            deleteCalculations(projectId) {
                api.panels.deleteFromCalcTables_1(projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {

                    }
                });
                api.panels.deleteFromCalcTables_2(projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {

                    }
                });
                api.panels.deleteFromCalcTables_3(projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {

                    }
                });
                api.panels.deleteFromCalcTables_4(projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {

                    }
                });
                api.panels.deleteFromCalcTables_5(projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {

                    }
                });
                api.panels.deleteFromCalcTables_6(projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {

                    }
                });
                api.panels.deleteFromCalcTables_7(projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {

                    }
                });
            },

            openPdf(row) {
                this.$store.state.isPrevProject = true;
                this.$store.state.projectId = row.ProjectId;

                router.push('/reportPdfPage2');
            },

            openFanPdf(row) {
                this.$store.state.isPrevProject = true;
                this.$store.state.projectId = row.ProjectId;

                router.push('/FanPdfPage');
            },

            openInfo() {
                if (!this.isInfoOpened) {
                    this.isInfoOpened = true;
                } else {
                    this.isInfoOpened = false;
                }
            },

            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },

            generateUUID() {
                var d = new Date().getTime(); // Timestamp
                var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0; // Time in microseconds since page-load or 0 if unsupported
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16; // random number between 0 and 16
                    if (d > 0) { // Use timestamp until depleted
                        r = (d + r) % 16 | 0;
                        d = Math.floor(d / 16);
                    } else { // Use microseconds since page-load if supported
                        r = (d2 + r) % 16 | 0;
                        d2 = Math.floor(d2 / 16);
                    }
                    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
            },
        }
    };
</script>

<style>
    .material-symbols-outlined {
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }

    /*    .projectIcons {
        display: flex;
        justify-content: space-between !important;
    }*/

    @media screen (max-width:768px) {
        .projectIcons {
            display: flex;
            justify-content: end !important;
        }
    }
</style>
