<template>
    <div>
        <hero-bar :has-right-visible="false" menu-icon="desktop-mac">
            {{this.$lang.Menus.NewProject}}
        </hero-bar>

        <div id="app" class="section is-main-section">
            <div class="center2">
                <div class="columns is-mobile is-multiline is-centered">

                    <div class="field is-grouped">
                        <b-field>
                            <b> {{ this.$lang.NewProject.Title }} </b>
                        </b-field>
                    </div>
                </div>


                <div class="columns is-mobile is-multiline is-centered">
                    <div class="field is-grouped infoText">
                            <p> {{ this.$lang.NewProject.Info_1 }} </p>
                            <p> {{ this.$lang.NewProject.Info_2 }} </p>
                    </div>
                </div>

                <b-field class="block" :type="{ 'is-danger': hasError_isAccepted }" :message="{ 'Alan Boş Bırakılamaz!': hasError_isAccepted }">
                    <b-checkbox v-model="isAccepted">
                        {{this.$lang.NewProject.Accept}}
                    </b-checkbox>
                </b-field>
            </div>

            <div style="padding: 0.5rem;">
                <div class="columns is-mobile is-multiline is-centered">
                    <div class="field is-grouped">
                        <b-field>
                            <b-button class="button is-primary" @click="openNewProject()">
                                {{this.$lang.NewProject.NewProject}}
                            </b-button>
                        </b-field>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'NewProject',
        data() {
            return {
                isActive: true,
                isAccepted: false,
                hasError_isAccepted: false
            }
        },
        mounted() {
            this.$store.state.projectId = '';

            this.$store.state.isFooterFixed = true;
        },
        destroyed() {
            this.$store.state.isFooterFixed = false;
        },
        methods: {
            openNewProject() {
                this.controlInputs();
                if (this.isAccepted) {
                    var uuid = this.generateUUID();

                    this.resetPreviousDatas();
                    this.$store.state.prevProjectId = -1;
                    this.$store.state.isPrevProject = false;
                    this.$store.state.projectId = uuid + "_Temp";
                    this.$store.state.calcIndex = 1;
                    this.$store.state.circIndex = 1;

                    if (this.$store.state.memberId != 'TestMember') {
                        const currDate = new Date();
                        const log = {
                            MemberId: this.$store.state.memberId,
                            ProjectId: this.$store.state.projectId,
                            IsAccepted: 1,
                            AcceptDate: currDate
                        };
                        api.panels.addNewProjectLog(log).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                router.push('/projectInfos');
                            }
                        });
                    } else {
                        router.push('/projectInfos');
                    }
                }
            },
            continueProject() {
                this.controlInputs();
                if (this.isAccepted) {
                    this.$store.activeStep = 0;
                    router.push('/projectInfos');
                }
            },

            generateUUID() {
                var d = new Date().getTime(); // Timestamp
                var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0; // Time in microseconds since page-load or 0 if unsupported
                return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                    var r = Math.random() * 16; // random number between 0 and 16
                    if (d > 0) { // Use timestamp until depleted
                        r = (d + r) % 16 | 0;
                        d = Math.floor(d / 16);
                    } else { // Use microseconds since page-load if supported
                        r = (d2 + r) % 16 | 0;
                        d2 = Math.floor(d2 / 16);
                    }
                    return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
                });
            },

            resetPreviousDatas() {
                this.$store.activeStep = 0;
                this.$store.canPanelInfosOpen = false;
                this.$store.canEnvConditionsOpen = false;
                this.$store.canCalculationOpen = false;
                this.$store.canResultOpen = false;
                this.$store.canReportPageOpen = false;
                this.$store.canFanCalcOpen = false;

                var payload = { langCode: "", projectName: "", panelName: "", panelNo: "", lastUserName: "", panelManifacturer: "", ratedCurrentIndex: -1, panelRatedCurrent: "", totalEyesNumber: 0, eyesNumber: 0, isCompleted: false };
                this.$store.commit('setProjectInfos', payload);

                var payload2 = { panelType: "", panelTypeIndex: 0, panelDimension: "", panelIP: "", selectedIP_Id: 0, Sair: "", surfaceExplanation: "", virtualSurface: "", virtualSurfaceIndex: 0, virtualSurfaceOption: 0, seperationNumber: 0, ae_value: 0, ae_valueList: [] };
                this.$store.commit('setPanelInfos', payload2);

                var payload3 = { envTemperature: 0, maxTemperature: 0, humidity: "", altitude: 0 };
                this.$store.commit('setEnvConditionDatas', payload3);

                var payload4 = { totalLostPower_1: 0 };
                this.$store.commit('setCalculationDatas', payload4);

                var payload5 = { inPanelTemperature: 0, envMeasureTemperature: 0, totalLostPower_2: 0 };
                this.$store.commit('setCalculationDatas_2', payload5);

                var payload6 = { InputCircuitDef: "", P_InputCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_InputCircuits', payload6);

                var payload7 = { OutputCircuitDef: "", P_OutputCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_OutputCircuits', payload7);

                var payload8 = { ManuelCircuitDef: "", P_ManuelCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_ManuelCircuits', payload8);

                var payload9 = { P_890: 0, P_Total: 0, result_630A: 0 };
                this.$store.commit('setResultInfos_630A', payload9);

                var payload10 = { value_k: 0, n_value: 0, value_d: 0, value_x: 0, deltaT_0_5: 0, Ab_val: 0, height_val: 0, value_f: 0, value_g: 0, value_c: 0, deltaT_0_75: 0, deltaT_1: 0, result_1600A: 0 };
                this.$store.commit('setResultInfos_1600A', payload10);
            },

            controlInputs() {
                if (this.isAccepted == false) {
                    this.hasError_isAccepted = true;
                } else {
                    this.hasError_isAccepted = false;
                }
            },
        }
    };
</script>

<style>
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
    }

    .center2 {
        margin: auto;
        width: 50%;
        text-align: center;
    }

    .infoText {
        display: block !important;
        margin-bottom: 1.5em;
    }
</style>
