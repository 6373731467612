<template>
    <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.CalcCircInfos.Title_1}} &nbsp; <span style="font-size:1vw">{{this.$lang.CalcCircInfos.Title_2}}</span>

            <p v-if="this.$store.state.isCompleted" class="control" slot="right" style="margin-right: 16px">
                <saveButton />
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="is-warning" @click="openCircuitDevices" style="height: 88%;">
                    {{this.$lang.Calculation.InnerTitle_14}} <br /> {{this.$lang.Calculation.InnerTitle_15}} <br />{{this.$lang.Calculation.InnerTitle_16}}
                </b-button>
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 0.75rem; padding-bottom: 1rem">
            <div class="columns is-multiline is-mobile">
                <div class="column is-2" style="background-color: #FFFFFF; width: 10%; height: 100vh; display: flex; flex-direction: column;">
                    <verticalStep-bar />
                </div>
                <div class="column is-10">
                    <calculationTabs />
                    <div style="padding: 0.5rem;">
                        <outputCircBtns />
                    </div>
                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="column">
                            <span style="font-size:1vw"> {{ this.$lang.CalcCircInfos.Title_13 }} </span>
                            <div style="padding-top: 1rem"></div>
                            <b-field grouped>
                                <b-field :label="this.$lang.CalcCircInfos.Explanation" :label-position="labelPosition" :type="{ 'is-danger': hasError_explanation }" :message="{ 'Alan Boş Bırakılamaz!': hasError_explanation }">
                                    <b-input v-model="explanation"></b-input>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.LostPower_2" :label-position="labelPosition" :type="{ 'is-danger': hasError_lostPower }" :message="{ 'Alan Boş Bırakılamaz!': hasError_lostPower }">
                                    <b-input v-model="lostPower" type="number" step="any"></b-input>
                                </b-field>
                                <p class="control">
                                    <b-button type="is-success" :label="this.$lang.CalcCircInfos.CalcAddButton" @click="addToManuelCalcDevices" />
                                </p>
                            </b-field>

                            <b-field :label="this.$lang.CalcCircInfos.Title_7" style="padding-top: 2rem;"></b-field>
                            <b-table v-if="calcdevicesdata.length != 0" :data="calcdevicesdata">
                                <b-table-column field="Explanation" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.Explanation }}
                                </b-table-column>
                                <b-table-column field="LostPower" :td-attrs="columnTdAttrs" width="100">
                                    <template v-slot:header="">
                                        <span v-html="$lang.CalcCircInfos.LostPower_3"></span>
                                    </template>
                                    <template v-slot="props">
                                        {{ truncate(props.row.LostPower, 1) }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="Action" v-slot="props">
                                    <button class="button is-small is-light" @click="openManuelCalcDeviceModal(props.row)">
                                        <b-icon icon="pen" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-danger" @click="deleteFromManuelCalcDevices(props.row)">
                                        <b-icon icon="delete" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-light" @click="copyFromManuelCalcDevices(props.row)" style="width: 30px">
                                        <!--<b-icon icon="file" size="is-small"></b-icon>-->
                                        <div class="material-symbols-outlined">
                                            content_copy
                                        </div>
                                    </button>
                                </b-table-column>
                            </b-table>
                            <div v-if="calcdevicesdata.length != 0" style="padding: 1rem;">
                                <div class="columns is-mobile is-multiline">
                                    <div class="field is-grouped">
                                        <b-field>
                                            <b-field :label="this.$lang.CalcCircInfos.Total_P_Manuel"></b-field>
                                        </b-field>
                                        <b-field>
                                            {{ truncate(total_P, 1) }}
                                        </b-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal v-model="isCalcDeviceUpdateModalActive" :width="960">
                <div class="card">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{{ this.$lang.CalcCircInfos.UpdatePManuel }}</p>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.Explanation" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.Explanation">
                                            <b-input v-model="explanation"></b-input>
                                        </b-tooltip>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.LostPower_2" :label-position="labelPosition" style="width: 155px">
                                        <b-tooltip :label="this.$lang.CalcCircInfos.LostPower_2">
                                            <b-input v-model="lostPower" type="number" step="any"></b-input>
                                        </b-tooltip>
                                    </b-field>
                                </b-field>
                            </div>
                        </div>
                        <div class="content">
                            <footer class="modal-card-foot">
                                <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                          @click="isCalcDeviceUpdateModalActive = false" />
                                <b-button :label="this.$lang.Menus.ModalUpdtBtn"
                                          type="is-primary"
                                          @click="updateManuelCalcDevices()" />
                            </footer>
                        </div>
                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';
    import OutputCircBtns from '@/components/OutputCircBtns.vue';
    import CalculationTabs from '@/components/CalculationTabs.vue';
    import SaveButton from '@/components/SaveButton.vue';

    export default {
        name: 'PanelInfos',
        components: {
            VerticalStepBar,
            OutputCircBtns,
            CalculationTabs,
            SaveButton
        },
        data() {
            return {
                isActive: true,
                labelPosition: 'on-border',
                projectId: 0,
                manuelCalcDeviceId: 0,
                calcdevicesdata: [],
                explanation: '',
                total_P: 0,
                lostPower: 0,
                isCalcDeviceUpdateModalActive: false,
                hasError_explanation: false,
                hasError_lostPower: false,
                canCalculate: false
            }
        },
        mounted() {
            this.projectId = this.$store.state.projectId;
            this.getManuelCalcDevicesTable();

            this.$store.state.activeStep = 3;
            this.$store.state.isFooterFixed = false;
        },
        watch: {
            $route() {

            }
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_OutputCircuit');
            },
            submit() {
                this.controlInputs();
                this.saveResult();

                this.$store.isPageOpenedFromStepBar = false;
                this.$store.canResultOpen = true;

                router.push('/calc_Summary');
            },
            saveResult() {
                var totalP = this.$store.state.P_Busbar + this.$store.state.P_InputCircuit + this.$store.state.P_OutputCircuit + this.total_P;
                var payload = { ManuelCircuitDef: this.explanation, P_ManuelCircuit: this.lostPower, totalLostPower_3: totalP };
                this.$store.commit('set_Datas_ManuelCircuits', payload);
            },
            controlInputs() {
                if (this.explanation == '') {
                    this.hasError_explanation = true;
                } else if (this.lostPower == '') {
                    this.hasError_lostPower = true;
                } else {
                    this.canCalculate = true;
                }

                if (this.explanation != '') {
                    this.hasError_explanation = false;
                }
                if (this.lostPower != '') {
                    this.hasError_lostPower = false;
                }
            },
            getManuelCalcDevicesTable() {
                this.calcdevicesdata = [];
                api.panels.getManuelCalcDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.calcdevicesdata.push(e);
                        });

                        api.panels.getTotal_P_OtherPowerCalculationDevices(this.projectId).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.total_P = res.data;
                                this.saveResult();
                            }
                        });
                    }
                });
            },
            addToManuelCalcDevices() {
                this.controlInputs();

                if (this.canCalculate) {
                    var device = {
                        ProjectId: this.projectId,
                        Explanation: this.explanation,
                        LostPower: this.lostPower
                    };

                    api.panels.addToManuelCalcDevices(device).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.$buefy.toast.open(this.$lang.OtherDialog.Added);
                            this.getManuelCalcDevicesTable();
                        }
                    });
                }
            },
            deleteFromManuelCalcDevices(device) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.deleteFromManuelCalcDevices(device).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                                this.getManuelCalcDevicesTable();
                            }
                        })
                })
            },
            copyFromManuelCalcDevices(device) {
                var initialDefinition = device.Explanation;
                device.Explanation = device.Explanation + " " + this.$lang.CopyDialog.Extension;
                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.addToManuelCalcDevices(device).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.CopyDialog.Copied);
                                this.getManuelCalcDevicesTable();
                            }
                        }),
                    onCancel: () => {
                        device.Explanation = initialDefinition;
                    }
                })
            },
            openManuelCalcDeviceModal(device) {
                this.manuelCalcDeviceId = device.Id;
                this.isCalcDeviceUpdateModalActive = true;
                this.explanation = device.Explanation;
                this.lostPower = device.LostPower;
            },
            updateManuelCalcDevices() {
                const device = {
                    Id: this.manuelCalcDeviceId,
                    ProjectId: this.projectId,
                    Explanation: this.explanation,
                    LostPower: this.lostPower,
                };
                api.panels.updateManuelCalcDevices(device).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isCalcDeviceUpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);
                        this.getManuelCalcDevicesTable();
                    }
                });
            },
            truncate(numToBeTruncated, numOfDecimals) {
                var theNumber = numToBeTruncated.toString();
                if (theNumber.includes('.')) {
                    var pointIndex = theNumber.indexOf('.');
                    var _result = +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
                    var _resultString = _result.toString();
                    if (!_resultString.includes('.')) {
                        return _resultString + '.0';
                    } else {
                        return _result;
                    }
                } else {
                    return theNumber + '.0';
                }
            },
            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },
            openCircuitDevices() {
                this.$store.state.previousRoute = this.$route.name;
                this.$store.state.isCircDevicesFromCalculation = true;
                if (this.$store.state.memberId != 'TestMember' && this.$store.state.isAdmin == 1) {
                    router.push('/circuitDevices_Admin');
                } else {
                    router.push('/circuitDevices');
                }
            }
        },

    };
</script>

<style>
    .material-symbols-outlined {
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }
</style>
