<template>
    <section class="hero is-hero-bar">
        <div class="hero-body bodyHero">
            <div class="level herolevel">
                <div class="level-left">
                    <div class="level-item">
                        <a class="title">
                            <b-icon :icon="menuIcon" size="is-small" class="mx-4"></b-icon>
                            <slot />
                        </a>
                    </div>
                </div>
                <div v-show="hasRightVisible" class="level-right">
                    <div class="level-item">
                        <slot name="right" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'HeroBar',
        props: {
            hasRightVisible: {
                type: Boolean,
                default: true
            },
            menuIcon: {
                type: String,
                required: true
            }
        }
    };

</script>

<style>
    @media only screen and (max-width: 670px) {
        .herolevel {
            flex-direction: column;
        }

        .bodyHero {
            display: flex !important;
        }
    }

    .herolevel {
        display: flex;
    }

    .level-right {
        margin-top: 0 !important;
    }

    @media only screen and (max-width: 424px) {
        .title {
            font-size: 1.7em;
        }
    }

    @media only screen and (max-width: 384px) {
        .title {
            font-size: 1.5em;
        }
    }
</style>
