<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.CalcCircInfos.Title_1}} &nbsp; <span style="font-size:1vw">{{this.$lang.CalcCircInfos.Title_2}}</span>

            <p class="control" slot="right" style="margin-right: 16px">
                <saveButton />
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="is-warning" @click="openCircuitDevices" style="height: 88%;">
                    {{this.$lang.Calculation.InnerTitle_14}} <br /> {{this.$lang.Calculation.InnerTitle_15}} <br />{{this.$lang.Calculation.InnerTitle_16}}
                </b-button>
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 0.75rem; padding-bottom: 1rem">
            <div class="is-mobile is-multiline columns">
                <div class="column is-2" style="background-color: #FFFFFF; width: 10%; display: flex; min-height: 100vh; flex-direction: column;">
                    <verticalStep-bar />
                </div>
                <div class="column is-10">
                    <calculationTabs />
                    <div style="padding: 0.5rem;">
                        <outputCircBtns />
                    </div>
                    <div class="columns is-multiline is-mobile is-centered" style="overflow-y:auto">
                        <div class="column">
                            <div>
                                <div v-if="busbardevicesdata.length != 0">
                                    <b-field style="padding-top: 2rem;"></b-field>
                                    <span style="font-size:1vw">{{this.$lang.Calculation.NewTitle_4}}</span>
                                    <b-table :data="busbardevicesdata">
                                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="250">
                                            {{ props.row.Definition }}
                                        </b-table-column>
                                        <b-table-column field="Width_Thickness" :label="this.$lang.CalcCircInfos.BusbarDimension" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.Width_Thickness }}
                                        </b-table-column>
                                        <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.ConductorTemperature }}
                                        </b-table-column>
                                        <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs" width="150">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.MaxTemperature }}
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="I_Max" :td-attrs="columnTdAttrs" width="150">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.Imax"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ truncate(props.row.I_Max, 1) }}
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.ConductorLength }}
                                        </b-table-column>
                                        <b-table-column field="PhasesNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.PhasesNumber }}
                                        </b-table-column>
                                        <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ truncate(props.row.LostPower, 1) }}
                                        </b-table-column>
                                        <b-table-column field="CalcResult" :td-attrs="columnTdAttrs" width="150">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ truncate(props.row.CalcResult, 1) }}
                                            </template>
                                        </b-table-column>
                                    </b-table>
                                    <div style="padding: 0.5rem;">
                                        <div class="columns is-mobile is-multiline">
                                            <div class="field is-grouped">
                                                <b-field>
                                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_Busbar"></b-field>
                                                </b-field>
                                                <b-field>
                                                    {{ truncate(total_P_Busbar, 1) }}
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="inputcirccalculationdevicesdata.length != 0">
                                    <b-field style="padding-top: 2rem;"></b-field>
                                    <span style="font-size:1vw">{{this.$lang.Calculation.NewTitle_5}}</span>
                                    <b-table :data="inputcirccalculationdevicesdata">

                                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ props.row.Definition }}
                                        </b-table-column>
                                        <b-table-column field="Type" :label="this.$lang.CalcCircInfos.Type" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.Type }}
                                        </b-table-column>
                                        <b-table-column field="Brand" :label="this.$lang.CalcCircInfos.Brand" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.Brand }}
                                        </b-table-column>
                                        <b-table-column field="Code" :label="this.$lang.CalcCircInfos.Code" :td-attrs="columnTdAttrs" v-slot="props" width="200">
                                            {{ props.row.Code }}
                                        </b-table-column>
                                        <b-table-column field="In_Value" :td-attrs="columnTdAttrs" width="100">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.In_A"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.In_Value }}
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="Pole" :label="this.$lang.CalcCircInfos.PoleNumber" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ props.row.Pole }}
                                        </b-table-column>
                                        <b-table-column field="Sbt_Cekmeceli" :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.Sbt_Cekmeceli }}
                                        </b-table-column>
                                        <b-table-column field="Power" :label="this.$lang.CalcCircInfos.Power" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ props.row.Power }}
                                        </b-table-column>
                                        <b-table-column field="Pv" :td-attrs="columnTdAttrs" width="100">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ truncate(props.row.Pv, 1) }}
                                            </template>
                                        </b-table-column>
                                    </b-table>

                                    <b-table :data="conductordevicesdata">
                                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ props.row.Definition }}
                                        </b-table-column>
                                        <b-table-column field="ConnectionType" :label="this.$lang.CalcCircInfos.ConnType" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.ConnectionType }}
                                        </b-table-column>
                                        <b-table-column :label="this.$lang.CalcCircInfos.CablePos" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                            {{ props.row.CablePosition }}
                                        </b-table-column>
                                        <b-table-column field="PhaseNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs" v-slot="props" width="200">
                                            {{ props.row.PhaseNumber }}
                                        </b-table-column>
                                        <b-table-column field="DimensionInfo" :label="this.$lang.CalcCircInfos.ConductorDimension" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ props.row.DimensionInfo }}
                                        </b-table-column>
                                        <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ props.row.ConductorLength }}
                                        </b-table-column>
                                        <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ props.row.ConductorTemperature }}
                                        </b-table-column>
                                        <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs" width="150">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ props.row.MaxTemperature }}
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="I_Max" :td-attrs="columnTdAttrs" width="100">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.Imax"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ truncate(props.row.I_Max, 1) }}
                                            </template>
                                        </b-table-column>
                                        <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ truncate(props.row.LostPower, 1) }}
                                        </b-table-column>
                                        <b-table-column field="CalcResult" :td-attrs="columnTdAttrs" width="100">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ truncate(props.row.CalcResult, 1) }}
                                            </template>
                                        </b-table-column>
                                    </b-table>
                                    <div style="padding: 0.5rem;">
                                        <div class="columns is-mobile is-multiline">
                                            <div class="field is-grouped">
                                                <b-field>
                                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_InpCirc"></b-field>
                                                </b-field>
                                                <b-field>
                                                    {{ truncate(total_P_InputCircuit, 1) }}
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="circuitcalculationdevicesdata_detailed.length != 0">
                                    <b-field style="padding-top: 2rem;"></b-field>
                                    <span style="font-size:1vw">{{this.$lang.Calculation.NewTitle_6}}</span>
                                    <b-table :data="circuitcalculationdevicesdata_detailed"
                                             hoverable
                                             detailed
                                             custom-detail-row
                                             :show-detail-icon="false"
                                             detail-key="CircuitIndex">

                                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="70px">
                                            <a class="customDetailField" @click="props.toggleDetails(props.row)">
                                                {{ props.row.Definition }}
                                            </a>
                                        </b-table-column>
                                        <b-table-column field="NumOfCircuits" :label="this.$lang.CalcCircInfos.AmountTitle" :td-attrs="columnTdAttrs" v-slot="props" width="70px">
                                            {{ props.row.NumOfCircuits }}
                                        </b-table-column>
                                        <b-table-column field="Type" :label="this.$lang.CalcCircInfos.Type" :td-attrs="columnTdAttrs" width="70px">
                                            -
                                        </b-table-column>
                                        <b-table-column field="Brand" :label="this.$lang.CalcCircInfos.Brand" :td-attrs="columnTdAttrs" width="70px">
                                            -
                                        </b-table-column>
                                        <b-table-column field="Code" :label="this.$lang.CalcCircInfos.Code" :td-attrs="columnTdAttrs" width="70px">
                                            -
                                        </b-table-column>
                                        <b-table-column field="In_Value" :label="this.$lang.CalcCircInfos.In_A" :td-attrs="columnTdAttrs" width="70px">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.In_A"></span>
                                            </template>
                                            -
                                        </b-table-column>
                                        <b-table-column field="Pole" :label="this.$lang.CalcCircInfos.PoleNumber" :td-attrs="columnTdAttrs" width="70px">
                                            -
                                        </b-table-column>
                                        <b-table-column field="Sbt_Cekmeceli" :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :td-attrs="columnTdAttrs" width="70px">
                                            -
                                        </b-table-column>
                                        <b-table-column field="Power" :label="this.$lang.CalcCircInfos.Power" :td-attrs="columnTdAttrs" width="70px">
                                            -
                                        </b-table-column>
                                        <b-table-column field="Pv" :label="this.$lang.CalcCircInfos.Pv" :td-attrs="columnTdAttrs" width="70px">
                                            -
                                        </b-table-column>
                                        <b-table-column field="RDF" :label="this.$lang.CalcCircInfos.RDF" :td-attrs="columnTdAttrs" v-slot="props" width="10px">
                                            {{ props.row.RDF }}
                                        </b-table-column>
                                        <b-table-column field="P_CalcResult" :label="this.$lang.CalcCircInfos.CalcResult2" :td-attrs="columnTdAttrs" width="70px">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.CalcResult2"></span>
                                            </template>
                                            -
                                        </b-table-column>

                                        <template slot="detail" slot-scope="props">
                                            <tr v-for="item in props.row.Devices" :key="item.Definition">
                                                <td class="has-text-centered" width="70px">{{ item.Definition }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.NumOfCircuits }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.Type }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.Brand }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.Code }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.In_Value }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.Pole }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.Sbt_Cekmeceli }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.Power }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.Pv }}</td>
                                                <td class="has-text-centered" width="10px">{{ item.RDF }}</td>
                                                <td class="has-text-centered" width="70px">{{ item.P_CalcResult}}</td>
                                                <td class="has-text-centered" width="70px">
                                                </td>
                                            </tr>
                                        </template>
                                    </b-table>
                                    <b-table :data="circuitdeviceconductorsdata_detailed"
                                             hoverable
                                             detailed
                                             custom-detail-row
                                             :show-detail-icon="false"
                                             detail-key="CircuitIndex">

                                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props">
                                            <a class="customDetailField" @click="props.toggleDetails(props.row)">
                                                {{ props.row.Definition }}
                                            </a>
                                        </b-table-column>
                                        <b-table-column field="NumOfCircuits" :label="this.$lang.CalcCircInfos.AmountTitle" :td-attrs="columnTdAttrs" v-slot="props">
                                            {{ props.row.NumOfCircuits }}
                                        </b-table-column>
                                        <b-table-column field="ConnectionType" :label="this.$lang.CalcCircInfos.ConnType" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="CablePosition" :label="this.$lang.CalcCircInfos.CablePos" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="PhaseNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="DimensionInfo" :label="this.$lang.CalcCircInfos.ConductorDimension" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                            </template>
                                            -
                                        </b-table-column>
                                        <b-table-column field="I_Max" :td-attrs="columnTdAttrs">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.Imax"></span>
                                            </template>
                                            -
                                        </b-table-column>
                                        <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="RDF" :label="this.$lang.CalcCircInfos.RDF" :td-attrs="columnTdAttrs" v-slot="props">
                                            {{ props.row.RDF }}
                                        </b-table-column>
                                        <b-table-column field="P_CalcResult" :label="this.$lang.CalcCircInfos.CalcResult" :td-attrs="columnTdAttrs">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                            </template>
                                            -
                                        </b-table-column>

                                        <template slot="detail" slot-scope="props">
                                            <tr v-for="item in props.row.Devices" :key="item.Definition">
                                                <td class="has-text-centered">{{ item.Definition }}</td>
                                                <td class="has-text-centered">{{ item.NumOfCircuits }}</td>
                                                <td class="has-text-centered">{{ item.ConnectionType }}</td>
                                                <td class="has-text-centered">{{ item.CablePosition }}</td>
                                                <td class="has-text-centered">{{ item.PhaseNumber }}</td>
                                                <td class="has-text-centered">{{ item.DimensionInfo }}</td>
                                                <td class="has-text-centered">{{ item.ConductorLength }}</td>
                                                <td class="has-text-centered">{{ item.ConductorTemperature }}</td>
                                                <td class="has-text-centered">{{ item.MaxTemperature }}</td>
                                                <td class="has-text-centered">{{ truncate(item.I_Max, 1) }}</td>
                                                <td class="has-text-centered">{{ truncate(item.LostPower, 1) }}</td>
                                                <td class="has-text-centered">{{ item.RDF }}</td>
                                                <td class="has-text-centered">{{ truncate(item.P_CalcResult ,1) }}</td>
                                                <td class="has-text-centered" width="70px">
                                                </td>
                                            </tr>
                                        </template>
                                    </b-table>

                                    <div style="padding: 0.5rem;">
                                        <div class="columns is-mobile is-multiline">
                                            <div class="field is-grouped">
                                                <b-field>
                                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_OutputCircs"></b-field>
                                                </b-field>
                                                <b-field>
                                                    {{ truncate(allTotal_P_1, 1) }}
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="subdistributionbusbarsdata_detailed.length != 0">
                                    <b-table :data="subdistributionbusbarsdata_detailed"
                                             hoverable
                                             detailed
                                             custom-detail-row
                                             :show-detail-icon="false"
                                             detail-key="CircuitIndex">
                                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props">
                                            <a class="customDetailField" @click="props.toggleDetails(props.row)">
                                                {{ props.row.Definition }}
                                            </a>
                                        </b-table-column>
                                        <b-table-column field="NumOfCircuits" :label="this.$lang.CalcCircInfos.AmountTitle" :td-attrs="columnTdAttrs" v-slot="props">
                                            {{ props.row.NumOfCircuits }}
                                        </b-table-column>
                                        <b-table-column field="ConnectionType" :label="this.$lang.CalcCircInfos.ConnType" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="CablePosition" :label="this.$lang.CalcCircInfos.CablePos" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="PhaseNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="DimensionInfo" :label="this.$lang.CalcCircInfos.ConductorDimension" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="MaxTemperature" :label="this.$lang.CalcCircInfos.Tmax_inPanel" :td-attrs="columnTdAttrs">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                            </template>
                                            -
                                        </b-table-column>
                                        <b-table-column field="I_Max" :td-attrs="columnTdAttrs">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.Imax"></span>
                                            </template>
                                            -
                                        </b-table-column>
                                        <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs">
                                            -
                                        </b-table-column>
                                        <b-table-column field="P_CalcResult" :label="this.$lang.CalcCircInfos.CalcResult" :td-attrs="columnTdAttrs">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                            </template>
                                            -
                                        </b-table-column>

                                        <template slot="detail" slot-scope="props">
                                            <tr v-for="item in props.row.Devices" :key="item.Id">
                                                <td class="has-text-centered">{{ item.Definition }}</td>
                                                <td class="has-text-centered">{{ item.NumOfCircuits }}</td>
                                                <td class="has-text-centered">{{ item.ConnectionType }}</td>
                                                <td class="has-text-centered">{{ item.CablePosition }}</td>
                                                <td class="has-text-centered">{{ item.PhaseNumber }}</td>
                                                <td class="has-text-centered">{{ item.DimensionInfo }}</td>
                                                <td class="has-text-centered">{{ item.ConductorLength }}</td>
                                                <td class="has-text-centered">{{ item.ConductorTemperature }}</td>
                                                <td class="has-text-centered">{{ item.MaxTemperature }}</td>
                                                <td class="has-text-centered">{{ truncate(item.I_Max, 1) }}</td>
                                                <td class="has-text-centered">{{ truncate(item.LostPower, 1) }}</td>
                                                <td class="has-text-centered">{{ truncate(item.P_CalcResult, 1) }}</td>
                                                <td class="has-text-centered" width="70px">
                                                </td>
                                            </tr>
                                        </template>
                                    </b-table>
                                    <div style="padding: 0.5rem;">
                                        <div class="columns is-mobile is-multiline">
                                            <div class="field is-grouped">
                                                <b-field>
                                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_SubDistBusbars"></b-field>
                                                </b-field>
                                                <b-field>
                                                    {{ truncate(total_P_OutputCircuit_SubDistributionBusbars, 1) }}
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="padding-top: 3rem; padding-left: 0.5rem">
                                        <div class="columns is-mobile is-multiline">
                                            <div class="field is-grouped">
                                                <b-field>
                                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_OutputCircs"></b-field>
                                                </b-field>
                                                <b-field>
                                                    {{ truncate(allTotal_P_2, 1) }}
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="manuelcalcdevicesdata.length != 0">
                                    <b-field style="padding-top: 2rem;"></b-field>
                                    <span style="font-size:1vw">{{this.$lang.Calculation.NewTitle_7}}</span>
                                    <b-table :data="manuelcalcdevicesdata">
                                        <b-table-column field="Explanation" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                            {{ props.row.Explanation }}
                                        </b-table-column>
                                        <b-table-column field="LostPower" :td-attrs="columnTdAttrs" width="100">
                                            <template v-slot:header="">
                                                <span v-html="$lang.CalcCircInfos.LostPower_3"></span>
                                            </template>
                                            <template v-slot="props">
                                                {{ truncate(props.row.LostPower, 1) }}
                                            </template>
                                        </b-table-column>
                                    </b-table>
                                    <div style="padding: 0.5rem;">
                                        <div class="columns is-mobile is-multiline">
                                            <div class="field is-grouped">
                                                <b-field>
                                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_Manuel_2"></b-field>
                                                </b-field>
                                                <b-field>
                                                    {{ truncate(total_P_manuel, 1) }}
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style="padding-top: 1rem"></div>
                            </div>
                        </div>
                    </div>

                    <div style="padding-left: 0.5rem">
                        <div class="columns is-mobile is-multiline">
                            <div class="field is-grouped">
                                <b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.TotalLostPower"></b-field>
                                </b-field>
                                <b-field>
                                    {{ truncate(totalPowerLost, 1) }}
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <b-modal v-model="isCalcWarningModalActive" :width="960">
                <div class="card">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{{this.$lang.Calculation.CalculationWarning_2}}</p>
                                <div class="content">
                                    <footer class="modal-card-foot">
                                        <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                                  @click="isCalcWarningModalActive = false" />
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

        </div>
        <b-notification :closable="false">
            <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>
        </b-notification>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';
    import OutputCircBtns from '@/components/OutputCircBtns.vue';
    import CalculationTabs from '@/components/CalculationTabs.vue';
    import SaveButton from '@/components/SaveButton.vue';

    export default {
        name: 'Calc_Busbar',
        components: {
            VerticalStepBar,
            OutputCircBtns,
            CalculationTabs,
            SaveButton
        },
        data() {
            return {
                isActive: true,
                isLoading: false,
                labelPosition: 'on-border',
                projectId: 0,
                currentPage: 1,
                areCalcsEmpty: false,
                isCalcWarningModalActive: false,
                allTotal_P_1: 0,
                allTotal_P_2: 0,

                busbardevicesdata: [],
                total_P_Busbar: 0,

                inputcirccalculationdevicesdata: [],
                conductordevicesdata: [],
                total_P_InputCircuit: 0,

                circuitcalculationdevicesdata_detailed: [],
                total_P_OutputCircuitCalcDevices: 0,
                circuitdeviceconductorsdata_detailed: [],
                total_P_OutputCircuitDeviceConductors: 0,
                subdistributionbusbarsdata_detailed: [],
                total_P_OutputCircuit_SubDistributionBusbars: 0,

                manuelcalcdevicesdata: [],
                total_P_manuel: 0,
                totalPowerLost: 0,
            }
        },
        mounted() {
            this.isLoading = true;
            this.projectId = this.$store.state.projectId;

            this.getBusbarDevicesTable();

            this.$store.state.activeStep = 3;
            this.$store.state.isFooterFixed = false;
        },
        watch: {
            $route() {

            }
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_Manuel');
            },
            submit() {
                if (this.busbardevicesdata.length == 0 && this.inputcirccalculationdevicesdata.length == 0 &&
                    this.circuitcalculationdevicesdata_detailed.length == 0 && this.circuitdeviceconductorsdata_detailed.length == 0 &&
                    this.manuelcalcdevicesdata.length == 0) {
                    this.areCalcsEmpty = true;
                    this.isCalcWarningModalActive = true;
                }

                if (!this.areCalcsEmpty) {
                    this.$store.isPageOpenedFromStepBar = false;
                    this.$store.canResultOpen = true;
                    this.$store.state.calcIndexFromOutputCircs = -1;

                    var totalLostpower3 = this.total_P_Busbar + this.total_P_InputCircuit + this.allTotal_P_2 + this.total_P_manuel;

                    var payload3 = { totalLostPower_3: totalLostpower3 };

                    this.$store.commit('setCalculationDatas_3', payload3);

                    router.push('/results');
                }
            },

            getAllTotalP() {
                this.allTotal_P_1 = 0;
                this.allTotal_P_2 = 0;
                api.panels.getAllTotal_P_OutputCircuit(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.allTotal_P_1 = res.data[0];
                        this.allTotal_P_2 = res.data[1];
                    }
                });
            },

            getBusbarDevicesTable() {
                this.busbardevicesdata = [];
                api.panels.getBusbarCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.busbardevicesdata.push(e);
                        });
                        this.getTotal_P_BusbarCalculationDevices();
                    }
                });
            },
            getTotal_P_BusbarCalculationDevices() {
                api.panels.getTotal_P_BusbarCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_Busbar = res.data;

                        this.getInputCircuitCalcDevicesTable();
                    }
                });
            },

            getInputCircuitCalcDevicesTable() {
                this.inputcirccalculationdevicesdata = [];
                api.panels.getInputCircuitCalcDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.inputcirccalculationdevicesdata.push(e);
                        });

                        this.getInputCircuitDeviceConductorsTable();
                    }
                });
            },
            getInputCircuitDeviceConductorsTable() {
                this.conductordevicesdata = [];
                api.panels.getInputCircuitDeviceConductors(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.conductordevicesdata.push(e);
                        });

                        this.getTotal_P_InputCircuits();
                    }
                });
            },
            getTotal_P_InputCircuits() {
                api.panels.getTotal_P_InputCircuitCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_InputCircuit = res.data;

                        api.panels.getTotal_P_InputCircuitDeviceConductors(this.projectId).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.total_P_InputCircuit += res.data;

                                this.getOutputCircuitCalcDevicesTable_Detailed();
                            }
                        });
                    }
                });
            },
            getOutputCircuitCalcDevicesTable_Detailed() {
                this.circuitcalculationdevicesdata_detailed = [];
                api.panels.getOutputCircuitCalcDevices_Detailed(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.circuitcalculationdevicesdata_detailed.push(e);
                        });
                        this.getTotal_P_OutputCircuitCalcDevices();

                        if (this.circuitcalculationdevicesdata_detailed.length > 0) {
                            this.getAllTotalP();
                        }
                    }
                });
            },
            getTotal_P_OutputCircuitCalcDevices() {
                this.total_P_OutputCircuitCalcDevices = 0
                api.panels.getTotal_P_OutputCircuitCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_OutputCircuitCalcDevices = res.data;
                        this.getOutputCircuitDeviceConductorsTable_Detailed();
                    }
                });
            },
            getOutputCircuitDeviceConductorsTable_Detailed() {
                this.circuitdeviceconductorsdata_detailed = [];
                api.panels.getOutputCircuitDeviceConductors_Detailed(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.circuitdeviceconductorsdata_detailed.push(e);
                        });
                        this.getTotal_P_OutputCircuitDeviceConductors();
                        this.getAllTotalP();
                    }
                });
            },
            getTotal_P_OutputCircuitDeviceConductors() {
                this.total_P_OutputCircuitDeviceConductors = 0
                api.panels.getTotal_P_OutputCircuitDeviceConductors(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_OutputCircuitDeviceConductors = res.data;

                        this.getOutputCircuit_SubDistributionBusbarsTable_Detailed();
                    }
                });
            },
            getOutputCircuit_SubDistributionBusbarsTable_Detailed() {
                this.subdistributionbusbarsdata_detailed = [];
                api.panels.get_OutputCircuit_SubDistributionBusbars_Detailed(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.subdistributionbusbarsdata_detailed.push(e);
                        });
                        this.getTotal_P_OutputCircuit_SubDistributionBusbars();
                    }
                });
            },
            getTotal_P_OutputCircuit_SubDistributionBusbars() {
                this.total_P_OutputCircuit_SubDistributionBusbars = 0;
                api.panels.getTotal_P_OutputCircuit_SubDistributionBusbars(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_OutputCircuit_SubDistributionBusbars = res.data;

                        this.getManuelCalcDevicesTable();
                    }
                });
            },
            getManuelCalcDevicesTable() {
                this.manuelcalcdevicesdata = [];
                api.panels.getManuelCalcDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.manuelcalcdevicesdata.push(e);
                        });

                        api.panels.getTotal_P_OtherPowerCalculationDevices(this.projectId).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.total_P_manuel = res.data;

                                this.getTotalPowerLost();
                            }
                        });
                    }
                });
            },
            openCircuitDevices() {
                this.$store.state.previousRoute = this.$route.name;
                this.$store.state.isCircDevicesFromCalculation = true;
                if (this.$store.state.memberId != 'TestMember' && this.$store.state.isAdmin == 1) {
                    router.push('/circuitDevices_Admin');
                } else {
                    router.push('/circuitDevices');
                }
            },
            truncate(numToBeTruncated, numOfDecimals) {
                var theNumber = numToBeTruncated.toString();
                if (theNumber.includes('.')) {
                    var pointIndex = theNumber.indexOf('.');
                    var _result = +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
                    var _resultString = _result.toString();
                    if (!_resultString.includes('.')) {
                        return _resultString + '.0';
                    } else {
                        return _result;
                    }
                } else {
                    return theNumber + '.0';
                }
            },
            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },
            getTotalPowerLost() {
                var totalPowerLost = this.total_P_Busbar + this.total_P_InputCircuit + this.allTotal_P_2 + this.total_P_manuel;
                this.totalPowerLost = totalPowerLost;
                this.isLoading = false;
            }
        }
    };
</script>

<style>
    .calcTable {
        /*        top: 0;
        bottom: 0;
        position: fixed;*/
        display: flex !important;
        overflow-y: auto;
    }
</style>
