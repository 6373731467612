<template>
    <div>
        <hero-bar :has-right-visible="false" menu-icon="desktop-mac">  </hero-bar>

        <div id="app" class="section is-main-section">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-3">
                    <b-field style="padding-top: 1rem; width: 125%;" horizontal :label="this.$lang.Member.Password" :type="{ 'is-danger': hasError_password }" :message="this.emptyWarning_password">
                        <b-input v-model="password" type="password" @input="password_check" password-reveal style="width: 65%;"></b-input>
                    </b-field>
                    <b-field v-if="isUpdtBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : password.length > 7}"><i class="frmIcon fas" :class="password.length > 7 ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_1}}</p>
                    </b-field>
                    <b-field v-if="isUpdtBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }"><i class="frmIcon fas" :class="has_uppercase ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_2}}</p>
                    </b-field>
                    <b-field v-if="isUpdtBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }"><i class="frmIcon fas" :class="has_lowercase ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_3}}</p>
                    </b-field>
                    <b-field v-if="isUpdtBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : has_number }"><i class="frmIcon fas" :class="has_number ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_4}}</p>
                    </b-field>
                    <b-field v-if="isUpdtBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : has_special }"><i class="frmIcon fas" :class="has_special ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_5}}</p>
                    </b-field>

                    <b-field style="padding-top: 1rem; width: 125%;" horizontal :label="this.$lang.Member.Password_2" :type="{ 'is-danger': hasError_password_2 }" :message="this.emptyWarning_password_2">
                        <b-input v-model="password_2" type="password" password-reveal style="width: 65%;"></b-input>
                    </b-field>
                </div>
            </div>
            <div class="columns is-mobile is-multiline is-centered">
                <p class="control" style="padding-top: 2rem">
                    <b-button class="button is-primary" @click="saveNewPassword">{{this.$lang.Member.Password_Updt}}</b-button>
                </p>
            </div>
        </div>

    </div>
</template>

<script type="text/javascript" src="path/to/zxcvbn.js"></script>
<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'ForgetPassword',
        data() {
            return {
                isActive: true,  
                canPasswordChange: false,
                isUpdtBtnClicked: false,
                password: '',
                password_2: '',         
                hasError_password: false,
                emptyWarning_password: '',
                hasError_password_2: false,
                emptyWarning_password_2: '',

                has_number: false,
                has_lowercase: false,
                has_uppercase: false,
                has_special: false,
            }
        },
        mounted() {
            this.$store.state.isFooterFixed = true;
        },
        methods: {
            password_check() {
                this.has_number = /\d/.test(this.password);
                this.has_lowercase = /[a-z]/.test(this.password);
                this.has_uppercase = /[A-Z]/.test(this.password);
                this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
            },                                                     
            controlPassword() {
                if (this.password.length <= 7 || !this.has_number || !this.has_lowercase || !this.has_uppercase || !this.has_special) {
                    this.hasError_password = true;
                    this.canPasswordChange = false;
                } else {
                    this.hasError_password = false;

                    if (this.password_2 != this.password) {
                        this.hasError_password_2 = true;
                        this.canPasswordChange = false;
                    } else {
                        this.hasError_password_2 = false;
                        this.canPasswordChange = true;
                    }
                }               
            },   
            saveNewPassword() {
                this.controlPassword();
                this.isUpdtBtnClicked = true;
                if (this.canPasswordChange) {
                    api.members.resetPassword(this.$route.query.memberId, this.password).then((res) => {
                        if (res.isError) {
                            this.$buefy.dialog.alert(this.$errorModal(res));
                        } else {
                            if (res.data) {
                                this.$buefy.dialog.alert({
                                    title: '',
                                    message: this.$lang.Member.Password_Changed,
                                    confirmText: this.$lang.Menus.Okay,
                                    type: 'is-success',
                                    hasIcon: true,
                                    onConfirm: () => {
                                        this.password_old = '';
                                        this.password = '';
                                        this.password_2 = '';
                                        router.push('/LoginMember');
                                    }
                                });
                            }
                        }
                    });
                }                
            }
        }
    };
</script>
