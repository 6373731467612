<template>
	<div class="error-page-icon">
		<b-icon icon="power-plug"> </b-icon>
		<div class="error-page-caption">
			<h1>404 - Sayfa Bulunamadı</h1>
		</div>
		<div class="error-page-action">
			<b-button type="is-link" tag="router-link" to="/Home"> Ana Sayfa </b-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'pageNotFound'
};
</script>
