export const Modals = {
	install(Vue) {
		Vue.prototype.$successModal = (msg) => {
			return {
				title: 'Başarılı',
				message: msg,
				type: 'is-success',
				hasIcon: true,
				icon: 'check-circle',
				ariaRole: 'alertdialog',
				ariaModal: true
			};
		};
		Vue.prototype.$errorModal = (res) => {
			return {
				title: 'Hata',
				message: res.errorDecription,
				confirmText: 'Tamam',
				type: 'is-danger',
				hasIcon: true
			};
		};
	}
};
