<template>
	<section class="section hero is-fullheight is-error-section">
		<div class="hero-body">
			<div class="container">
				<div class="columns is-centered">
					<div class="column is-two-thirds-tablet is-half-desktop is-one-third-widescreen">
						<div class="has-text-centered">
							<slot></slot>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		name: 'Error',
		beforeDestroy() {
			document.documentElement.className = 'has-aside-left has-aside-mobile-transition has-navbar-fixed-top';
		},
		created() {
			document.documentElement.className = '';
		}
	}
</script>