<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{ this.$lang.CircuitDevices.Title }} &nbsp; <span style="font-size:1vw">{{ this.$lang.CircuitDevices.Title_2 }}</span>

            <p v-if="this.backBtnActive == true || this.$store.state.isCircDevicesFromCalculation" slot="right" class="control" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">
                    {{ this.$lang.Menus.BackBtn }}
                </b-button>
            </p>
        </hero-bar>

        <section>
            <div class="columns">
                <div :v-if="this.$store.state.isCircDevicesFromCalculation" class="column is-1" style="padding-left: 2rem; background-color: #FFFFFF; width: 10%; height: 100%; min-height: 100vh; display: flex; flex-direction: column; ">
                    <verticalStep-bar />
                </div>
                <div class="column">
                    <div class="section is-main-section">
                        <div>
                            {{ this.$lang.CircuitDevices.Warning_3 }}
                        </div>
                        <form @submit.prevent="submit">
                            <div class="is-flex is-justify-content-center">
                                <b-upload v-model="dropFiles"
                                          multiple
                                          drag-drop
                                          accept=".xlsx, .xls"
                                          :validationMessage="this.$lang.CircuitDevices.Validation"
                                          :loading="isSendLoading">
                                    <section class="section">
                                        <div class="content has-text-centered">
                                            <p>
                                                <b-icon icon="upload" size="is-large"> </b-icon>
                                            </p>
                                            <p>
                                                {{ this.$lang.CircuitDevices.Warning_1 }} <br />
                                                {{ this.$lang.CircuitDevices.Warning_2 }}
                                            </p>
                                        </div>
                                    </section>
                                </b-upload>
                            </div>
                            <div class="tags">
                                <div v-for="(file, index) in dropFiles" :key="index">
                                    <span class="tag is-info">
                                        {{ file.name }} - {{ calcSize(file) }} - {{ $lang.CircuitDevices.Delete }}
                                        <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
                                    </span>
                                </div>
                            </div>
                            <b-progress v-if="loadPercentage"
                                        type="is-primary"
                                        size="is-small"
                                        :max="100"
                                        :value="loadPercentage"
                                        :show-value="true"
                                        format="percent"></b-progress>
                            <b-field horizontal>
                            </b-field>
                            <b-button @click="downloadExcel" icon-right="download" :is-loading="isLoading">
                                {{ this.$lang.CircuitDevices.DownloadBtn }}
                            </b-button>
                            <b-button @click="uploadExcel" type="is-primary" class="is-pulled-right" icon-right="content-save" :loading="isSendLoading">
                                {{ this.$lang.CircuitDevices.UploadBtn }}
                            </b-button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';

    export default {
        components: {
            VerticalStepBar
        },
        data() {
            return {
                name: 'CircuitDevices',
                isLoading: false,
                dropFiles: [],
                formData: [],
                isSendLoading: false,
                loadPercentage: 0,
                backBtnActive: false
            };
        },
        computed: {
            newDataMsg() {
                return this.$lang.CircuitDevices.ExcelLoaded;
            }
        },
        watch: {
            dropFiles(newVal, oldVal) {
                if (newVal.at(-1)?.size > 3145728) {
                    this.$buefy.toast.open({
                        duration: 2500,
                        message: this.$lang.CircuitDevices.MbWarning_1 + " <b>3MB</b>" + this.$lang.CircuitDevices.MbWarning_2,
                        type: 'is-warning'
                    });
                    this.dropFiles.pop();
                }

                this.dropFiles.forEach((e, i) => {
                    if (!('previewUrl' in e)) {
                        e.previewUrl = URL.createObjectURL(e);
                    }
                });

                this.formData = [];
                this.dropFiles.forEach((e, i) => {
                    const objectModel = {
                        Id: i,
                        ItemId: this.$route.params.id,
                        Base64String: ''
                    };

                    const reader = new FileReader();
                    reader.readAsDataURL(e);
                    reader.onloadend = function () {
                        const base64data = reader.result.split(',')[1];
                        objectModel.Base64String = base64data;
                    };

                    this.formData.push(objectModel);
                });
            }
        },
        mounted() {
            if (this.$store.state.previousRoute == 'projectInfos' || this.$store.state.previousRoute == 'panelInfos' || this.$store.state.previousRoute == 'envConditions' ||
                this.$store.state.previousRoute == 'calc_Busbar' || this.$store.state.previousRoute == 'calc_InputCircuit' || this.$store.state.previousRoute == 'calc_OutputCircuit' ||
                this.$store.state.previousRoute == 'calc_Manuel' || this.$store.state.previousRoute == 'calc_Summary' || this.$store.state.previousRoute == 'results' ||
                this.$store.state.previousRoute == 'reportPdfPage' || this.$store.state.previousRoute == 'calculation') {
                this.backBtnActive = true;
            } else {
                this.backBtnActive = false;
            }

            this.$store.state.isFooterFixed = true;
        },
        destroyed() {
            this.$store.state.isFooterFixed = false;
            this.$store.state.isCircDevicesFromCalculation = false;
        },
        methods: {
            downloadExcel() {
                this.isLoading = true;

                // var fileName = this.$lang.Format.Lang == "tr-TR" ? "Devre Cihazları.xlsx" : "Circuit Devices.xlsx";
                var fileName = this.$lang.CircuitDevices.Title + "xlsx";
                console.log(fileName);

                api.reportApi.getExcel_CircuitDevices('Panel', null, null, fileName, this.$lang.Format.Lang, 'Excel').then((res) => {
                    this.isLoading = false;
                    console.log(res);
                })
                    .catch((error) => {
                        this.isLoading = false;
                        this.$buefy.dialog.alert('An error occurred: ' + (error.response ? error.response.data.message : error.message));
                    });
            },
            deleteDropFile(index) {
                this.dropFiles.splice(index, 1);
                this.formData.splice(index, 1);
            },
            calcSize(file) {
                const fileSize = Math.trunc(file.size / 1024);
                if (fileSize > 1024) {
                    const fileSizeMb = fileSize / 1024;
                    return `${fileSizeMb.toFixed(2)}MB`;
                } else {
                    return `${fileSize}kb`;
                }
            },
            uploadExcel() {
                const config = {
                    onUploadProgress: (progressEvent) => {
                        this.loadPercentage = Math.round((progressEvent.loaded * 50) / progressEvent.total);
                    },
                    onDownloadProgress: (progressEvent) => {
                        this.loadPercentage = Math.round((progressEvent.loaded * 50) / progressEvent.total) + 50;
                    }
                };
                this.isSendLoading = true;
                if (this.formData != null) {
                    var memberId = this.$store.state.memberId;
                    api.reportApi.uploadExcel_CircuitDevices('Panel', this.formData, memberId, config, 'UploadExcel_CircuitDevices').then((res) => {
                        this.isSendLoading = false;
                        if (res.isError) {
                            this.$buefy.dialog.alert(this.$errorModal(res));
                        } else {
                            this.formData = res.data;
                            this.$buefy.dialog.alert(this.$successModal(this.$lang.CircuitDevices.SendApproval));
                            this.dropFiles = [];
                            this.formData = [];
                            this.loadPercentage = 0;
                            // this.$buefy.toast.open(this.$lang.ReportPage.Updated);
                        }
                    });
                }
            },
            previous() {
                router.push('/' + this.$store.state.previousRoute);
            },
            formatDate(date) {
                if (date == null) {
                    return '-';
                } else {
                    var yil = date.getFullYear();
                    var ay = ('0' + (date.getMonth() + 1)).slice(-2);
                    var gun = ('0' + date.getDate()).slice(-2);
                    var saat = ('0' + date.getHours()).slice(-2);
                    var dakika = ('0' + date.getMinutes()).slice(-2);
                    var saniye = ('0' + date.getSeconds()).slice(-2);

                    var formatliTarih = yil + '-' + ay + '-' + gun + ' ' + saat + ':' + dakika + ':' + saniye;

                    return formatliTarih;
                }
            },
        }
    };
</script>
