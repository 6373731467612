<template>
    <div>
        <hero-bar :has-right-visible="false" menu-icon="desktop-mac"> {{this.$lang.Member.Login}} </hero-bar>
        <!--<section class="section is-main-section">
            <font size="5">
                <h1>{{this.$lang.Member.Login}}</h1>
            </font>
        </section>-->

        <div id="app" class="section is-main-section">
            <div class="columns is-mobile is-multiline is-centered">
                <div class="column is-3">
                    <b-field :type="{ 'is-danger': hasError_email }" :message="this.emptyWarning_email">
                        <b-input type="email"
                                 v-model="email"
                                 :placeholder="this.$lang.Login.Email"
                                 required>
                        </b-input>
                    </b-field>

                    <b-field :type="{ 'is-danger': hasError_password }" :message="this.emptyWarning_password">
                        <b-input type="password"
                                 v-model="password"
                                 password-reveal
                                 :placeholder="this.$lang.Login.Password"
                                 required>
                        </b-input>
                    </b-field>

                    <b-field :type="{ 'is-danger': hasError_signIn }" :message="this.warning_signIn"></b-field>

                    <div class="level">
                        <p class="level-left"><b-checkbox v-model="rememberMe">{{ this.$lang.Login.RememberMe }}</b-checkbox></p>
                        <p class="level-right">
                            <b-button type="is-text" @click="forgetPassword()">
                                {{ this.$lang.Login.ForgetPassword }}
                            </b-button>
                        </p>
                    </div>

                    <div class="level">
                        <p class="level-left">
                            <b-button :label="this.$lang.Login.SignUpBtn"
                                      type="is-primary"
                                      @click="openSignUpPage()" />
                        </p>
                        <p class="level-right">
                            <b-button :label="this.$lang.Login.LoginBtn"
                                      type="is-danger"
                                      @click="signIn()" />
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'ProjectInfos',
        data() {
            return {
                isActive: true,
                rememberMe: false,
                canSignIn: false,
                hasError_email: false,
                emptyWarning_email: '',
                hasError_password: false,
                emptyWarning_password: '',
                hasError_signIn: false,
                warning_signIn: '',
                email: '',
                password: ''
            }
        },
        mounted() {
            /* api.members.getMember(this.$store.state.rememberMail, this.$store.state.rememberPassword + '').then((res) => {
                if (res.isError) {
                    console.log("error ");
                } else {
                    if (this.$store.canReportPageOpen) {
                        router.push('/ReportPdfPage');
                    }
                }
            }); */

            this.$store.state.isFooterFixed = true;
        },
        destroyed() {
            this.$store.state.isFooterFixed = false;
        },
        methods: {
            openSignUpPage() {
                this.$store.state.currentRoute = this.$route.name;
                router.push('/addMember');
            },
            signIn() {
                if (this.email == '') {
                    this.hasError_email = true;
                    this.emptyWarning_email = this.$lang.Menus.EmptyWarning;
                } else if (this.password == '') {
                    this.hasError_password = true;
                    this.emptyWarning_password = this.$lang.Menus.EmptyWarning;
                } else {
                    this.canSignIn = true;

                    const loginModel = {
                        Email: this.email,
                        Passwordd: this.password
                    };
                    api.members.encryptLogin(loginModel).then((resCrypto) => {
                        if (resCrypto.isError) {
                            console.log("error ");
                        } else {
                            api.members.getMember(resCrypto.data.Email, resCrypto.data.Passwordd + '').then((res) => {
                                if (res == null) {
                                    this.hasError_signIn = true;
                                    this.warning_signIn = this.$lang.Menus.LoginWarning;
                                } else {
                                    this.hasError_signIn = false;
                                    this.emptyWarning_password = '';
                                    if (res.isError) {
                                        console.log("error ");
                                    } else {
                                        this.$store.commit('user', res.data);
                                        var memberId = res.data.Id;
                                        this.$store.state.memberId = memberId + '';
                                        this.$store.state.isAdmin = res.data.IsAdmin;

                                        this.rememberMeFunc();

                                        if (this.$store.canReportPageOpen) {
                                            router.push('/ReportPdfPage');
                                        } else {
                                            router.push('/');
                                        }
                                    }
                                }
                            });
                        }
                    });
                }

                if (this.email != '') {
                    this.hasError_email = false;
                    this.emptyWarning_email = '';
                }
                if (this.password != '') {
                    this.hasError_password = false;
                    this.emptyWarning_password = '';
                }
            },
            rememberMeFunc() {
                if (this.rememberMe) {
                    const loginModel = {
                        Email: this.email,
                        Passwordd: this.password
                    };
                    api.members.encryptLogin(loginModel).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            var payload = {
                                rememberMe: true,
                                rememberMail: res.data[0],
                                rememberPassword: res.data[1]
                            };
                            this.$store.commit('rememberMeData', payload);
                        }
                    });
                } else {
                    var payload = {
                        rememberMe: false,
                        rememberMail: '',
                        rememberPassword: ''
                    };
                    this.$store.commit('rememberMeData', payload);
                }
            },
            forgetPassword() {
                router.push('/passwordEmail');
            }
        }
    };
</script>
