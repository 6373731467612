<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.Calculation.Title}} &nbsp; <span style="font-size:1vw">{{this.pageTitle}}</span>

            <p class="control" v-if="isCircDeviceDemandActive" slot="right" style="margin-right: 16px">
                <b-button class="is-warning" @click="openCircuitDevices" style="height: 88%; font-size:12px">
                    {{this.$lang.Calculation.InnerTitle_14}} <br /> {{this.$lang.Calculation.InnerTitle_15}} <br />{{this.$lang.Calculation.InnerTitle_16}}
                </b-button>
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-field v-if="isSecondTabActive && !isLostPowerCalculated && (activeTab == 0 || activeTab == 2)">
                    <p class="control">
                        <b-button class="button is-primary" @click="calculateLostPower_CurrentPanel" style="float: right;">{{this.$lang.PanelInfos.CalculateBtn}}</b-button>
                    </p>
                </b-field>
                <b-field v-else-if="!isSecondTabActive && !isLostPowerCalculated && activeTab == 0">
                    <p class="control">
                        <b-button class="button is-primary" @click="calculateLostPower_CurrentPanel" style="float: right;">{{this.$lang.PanelInfos.CalculateBtn}}</b-button>
                    </p>
                </b-field>
                <b-field v-else>
                    <p class="control">
                        <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
                    </p>
                </b-field>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 1rem; padding-top: 0.6rem">
            <div class="columns is-multiline is-mobile">
                <div class="column is-2" style="background-color: #FFFFFF; width: 10%; height: 100vh; display: flex; flex-direction: column;">
                    <verticalStep-bar />
                </div>
                <div class="column is-10">
                    <b-tabs class="btabColumns" position="is-centered" type="is-toggle is-light is-outlined" v-model="activeTab">
                        <b-tab-item class="tabColumn">
                            <template slot="header">
                                <b-button class="button is-light" @click="changeTab(0)" style="height: 80px; width: 240px">
                                    <div class="columns tabText">
                                        <div class="column is-5">
                                            <img src="/img/icon_1.jpg" style="width:75px; padding-top: 0.5rem" />
                                        </div>
                                        <div class="column is-2" style="align-self: center;">
                                            <div style="padding-top: 0.5rem">
                                                {{this.$lang.Calculation.InnerTitle_1}}<br />{{this.$lang.Calculation.InnerTitle_2}}<br />{{this.$lang.Calculation.InnerTitle_3}}
                                            </div>
                                        </div>
                                    </div>
                                </b-button>
                            </template>

                            <div class="section is-main-section">
                                <div class="columns">
                                    <div class="column is-4">
                                        <section>
                                            <b-field horizontal :type="{ 'is-danger': hasError_totalLostpower }" :message="this.emptyWarning_totalLostpower" style="width: 300%;">
                                                <template #label>
                                                    {{ label_totalLostpower }}
                                                </template>
                                                <b-field class="field is-grouped">
                                                    <b-numberinput v-model="totalLostpower" min="1" :controls=false step="any" @input="setTotalLostPowerValidity" style="width: fit-content;">{{ 'W' }}</b-numberinput>
                                                    <b-field label="W" style="padding-left: 0.5rem; padding-top: 0.5rem;"></b-field>
                                                </b-field>
                                            </b-field>
                                        </section>
                                    </div>
                                </div>
                            </div>

                        </b-tab-item>

                        <b-tab-item disabled>
                            <template slot="header">
                                <b-button style="height: 1px; width: 25px; opacity: 0">

                                </b-button>
                            </template>
                        </b-tab-item>

                        <b-tab-item v-if="isSecondTabActive">
                            <template slot="header">
                                <button class="button is-light" @click="changeTab(1)" style="height: 80px; width: 240px;">
                                    <div class="columns tabText">
                                        <div class="column is-5">
                                            <img src="/img/icon_2.jpg" style="width:75px; padding-top: 0.5rem" />
                                        </div>
                                        <div class="column is-2" style="align-self: center;">
                                            <div style="padding-top: 0.5rem">
                                                {{this.$lang.Calculation.InnerTitle_4}}<br />{{this.$lang.Calculation.InnerTitle_5}}<br />{{this.$lang.Calculation.InnerTitle_8}}
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </template>

                            <div class="section is-main-section">
                                <div class="columns">
                                    <div class="column is-4">
                                        <section>
                                            <b-field horizontal :type="{ 'is-danger': hasError_paneltemperature }" :message="this.emptyWarning_paneltemperature" style="width: 300%;">
                                                <template #label>
                                                    {{ label_paneltemperature }}
                                                </template>
                                                <b-field class="field is-grouped">
                                                    <b-input v-model="paneltemperature" type="number" step="any" @input="setLostPowerCalculated()" style="width: 8%;"></b-input>
                                                    <b-field label="°C" style="padding-left: 0.5rem; padding-top: 0.5rem;"></b-field>
                                                </b-field>
                                            </b-field>

                                            <b-field horizontal :type="{ 'is-danger': hasError_envtemperature }" :message="this.emptyWarning_envtemperature" style="width: 300%;">
                                                <template #label>
                                                    {{ label_envtemperature }}
                                                </template>
                                                <b-field class="field is-grouped">
                                                    <b-input v-model="envtemperature" type="number" step="any" @input="setLostPowerCalculated()" style="width: 8%;"></b-input>
                                                    <b-field label="°C" style="padding-left: 0.5rem; padding-top: 0.5rem;"></b-field>
                                                </b-field>
                                            </b-field>
                                        </section>
                                    </div>
                                    <div class="column is-4">
                                        <div class="box" style="background-color: #878787; width: 330px;">
                                            <b-field class="box" style="width: 300px; height: 50px; padding: 15px;">
                                                <b-field class="field is-grouped">
                                                    <template #label style="width: 300px;">
                                                        {{ label_P_total_panel }}
                                                    </template>
                                                    <b-field class="field is-grouped">
                                                        <b-field style="margin-left: 10px"> {{ this.truncate(totalLostpower_2, 1) }} {{ 'W' }} </b-field>
                                                    </b-field>
                                                </b-field>
                                            </b-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab-item>

                        <b-tab-item v-if="isSecondTabActive" disabled>
                            <template slot="header">
                                <b-button style="height: 1px; width: 25px; opacity: 0">

                                </b-button>
                            </template>
                        </b-tab-item>

                        <b-tab-item>
                            <template slot="header">
                                <button class="button is-light" @click="changeTab(2)" style="height: 80px; width: 240px">
                                    <div class="columns tabText">
                                        <div class="column is-5">
                                            <img src="/img/icon_3.jpg" style="width:75px; padding-top: 0.5rem" />
                                        </div>
                                        <div class="column is-2" style="align-self: center;">
                                            <div style="padding-top: 0.5rem">
                                                {{this.$lang.Calculation.InnerTitle_6}}<br /> {{this.$lang.Calculation.InnerTitle_7}}<br />{{this.$lang.Calculation.InnerTitle_8}}
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </template>

                            <div style="padding: 0.5rem; padding-top: 2rem">
                                <outputCircBtns />
                            </div>

                            <div class="section is-main-section">
                                <b-field :label="label_calculatedTitle"></b-field>
                                <b-table :data="devicesdata">
                                    <b-table-column field="Definition" :label="label_tableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="250">
                                        {{ props.row.Definition }}
                                    </b-table-column>
                                    <b-table-column field="LostPower" :td-attrs="columnTdAttrs" width="150">
                                        <template v-slot:header="">
                                            <span v-html="$lang.Calculation.TableLostPower"></span>
                                        </template>
                                        <template v-slot="props">
                                            {{ truncate(parseFloat(props.row.LostPower), 1) }}
                                        </template>
                                    </b-table-column>

                                    <b-table-column field="Action" v-slot="props">
                                        <button class="button is-small is-light" @click="updateCalculationData(props.row)">
                                            <b-icon icon="pen" size="is-small"></b-icon>
                                        </button>
                                    </b-table-column>
                                </b-table>

                                <div style="padding: 1rem;">
                                    <div class="columns is-mobile is-multiline">
                                        <div class="field is-grouped">
                                            <b-field :label="label_totalP"></b-field>
                                            <b-field>
                                                {{ truncate(parseFloat(totalLostpower_3), 1) }}
                                            </b-field>
                                        </div>
                                    </div>
                                </div>

                                <div style="padding-top: 2rem"></div>
                            </div>
                        </b-tab-item>
                    </b-tabs>
                </div>
            </div>

            <b-modal v-model="isTabsModalActive" :width="960">
                <div class="card">
                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{{this.$lang.Calculation.CalculationWarning}}</p>
                                <div class="content">
                                    <footer class="modal-card-foot">
                                        <b-button :label="$lang.Format.Lang === 'tr-TR' ? 'Vazgeç' : 'Deny'"
                                                  @click="cancelTabChange()" />
                                        <b-button :label="$lang.Format.Lang === 'tr-TR' ? 'Değiştir' : 'Change'"
                                                  type="is-danger"
                                                  @click="approveTabChange()" />
                                    </footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-modal>

        </div>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';
    import OutputCircBtns from '@/components/OutputCircBtns.vue';

    export default {
        name: 'PanelInfos',
        components: {
            VerticalStepBar,
            OutputCircBtns
        },
        data() {
            return {
                isActive: true,
                projectId: 0,
                pageTitle: '',
                isCircDeviceDemandActive: false,
                tempTabIndex: 0,
                activeTab: 0,
                previousTab: 0,
                isSecondTabActive: false,
                isTabsModalActive: false,
                areHiddenStepsVisible: false,
                canGoNextPage: false,

                totalLostpower: 0,
                hasError_totalLostpower: false,
                emptyWarning_totalLostpower: '',

                paneltemperature: 0,
                envtemperature: 0,
                totalLostpower_2: 0,
                isLostPowerCalculated: false,
                hasError_paneltemperature: false,
                emptyWarning_paneltemperature: '',
                hasError_envtemperature: false,
                emptyWarning_envtemperature: '',

                devicesdata: [],
                totalLostpower_3: 0,
                isCalcWarningModalActive: false,

                label_totalLostpower: this.$lang.Calculation.TotalLostPower_1,
                label_paneltemperature: this.$lang.Calculation.PanelTemp,
                label_envtemperature: this.$lang.Calculation.EnvTemp,
                label_P_total_panel: this.$lang.Calculation.LostPower_InPanel,
                label_calculatedTitle: this.$lang.Calculation.Label_1,
                label_totalP: this.$lang.Calculation.TotalLostPower_2,
                label_tableDefinition: this.$lang.Calculation.TableDefinition,
            }
        },
        beforeMount() {
            if (this.$store.state.ratedCurrentIndex == 1) {
                this.isSecondTabActive = true;
            } else {
                this.isSecondTabActive = false;
            }

            if (this.$store.state.calcIndexFromOutputCircs != -1) {
                this.changeTab(1);
            }
        },
        mounted() {
            this.projectId = this.$store.state.projectId;

            if (this.$store.state.ratedCurrentIndex == 1) {
                this.activeTab = 2 * this.$store.state.calcIndex - 2;
            } else {
                if (this.$store.state.calcIndex == 3) {
                    this.activeTab = 2;
                } else {
                    this.activeTab = this.$store.state.calcIndex - 1;
                }
            }

            this.setPageTitle(this.activeTab);
            this.totalLostpower = this.$store.state.totalLostPower_1;
            this.paneltemperature = this.$store.state.inPanelTemperature;
            this.envtemperature = this.$store.state.envMeasureTemperature;
            this.totalLostpower_2 = this.$store.state.totalLostPower_2;
            this.calculateLostPower_CurrentPanel();

            this.getCalculationDatas();
            this.$store.state.activeStep = 3;

            this.$store.state.isFooterFixed = true;
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/envConditions');
            },
            submit() {
                this.controlInputs();

                if (this.canGoNextPage) {
                    this.totalLostpower = this.truncate(this.totalLostpower, 1);

                    var payload = {
                        totalLostPower_1: this.totalLostpower
                    };
                    this.$store.commit('setCalculationDatas', payload);

                    this.calculateLostPower_CurrentPanel();
                    var payload2 = {
                        inPanelTemperature: this.paneltemperature, envMeasureTemperature: this.envtemperature, totalLostPower_2: this.totalLostpower_2
                    };
                    this.$store.commit('setCalculationDatas_2', payload2);

                    var payload3 = { totalLostPower_3: this.totalLostpower_3 };
                    this.$store.commit('setCalculationDatas_3', payload3);

                    this.$store.isPageOpenedFromStepBar = false;
                    this.$store.canResultOpen = true;
                    this.$store.state.calcIndexFromOutputCircs = -1;

                    router.push('/results');
                }
            },
            setTotalLostPowerValidity() {
                if (this.totalLostpower < 1) {
                    this.totalLostpower = 1;
                }
            },
            setLostPowerCalculated() {
                this.isLostPowerCalculated = false;
            },
            setPageTitle(tabIndex) {
                if (tabIndex == 0) {
                    this.pageTitle = this.$lang.Calculation.NewTitle_1;
                    this.isCircDeviceDemandActive = false;
                } else if (tabIndex == 2) {
                    if (this.isSecondTabActive) {
                        this.pageTitle = this.$lang.Calculation.NewTitle_2;
                        this.isCircDeviceDemandActive = false;
                    } else {
                        this.pageTitle = this.$lang.Calculation.NewTitle_3;
                        this.isCircDeviceDemandActive = true;
                    }
                } else if (tabIndex == 4) {
                    if (this.isSecondTabActive) {
                        this.pageTitle = this.$lang.Calculation.NewTitle_3;
                        this.isCircDeviceDemandActive = true;
                    }
                }
            },
            changeTab(tabIndex) {
                this.tempTabIndex = tabIndex;
                if (this.isSecondTabActive) {
                    if (this.activeTab != 2 * tabIndex) {
                        this.isTabsModalActive = true;
                    }
                } else {
                    if (tabIndex == 0) {
                        if (this.activeTab != 0) {
                            this.isTabsModalActive = true;
                        }
                    } else if (tabIndex == 2) {
                        if (this.activeTab != 4) {
                            this.isTabsModalActive = true;
                        }
                    }
                }
                this.previousTab = this.activeTab;
            },
            cancelTabChange() {
                this.activeTab = this.previousTab;
                this.isTabsModalActive = false;

                if (this.$store.state.calcIndexFromOutputCircs != -1) {
                    router.push('/' + this.$store.state.currentRouteFromOutputCircs);
                    this.$store.state.calcIndexFromOutputCircs = -1;
                }
            },
            approveTabChange() {
                this.setPageTitle(this.activeTab);
                if (this.isSecondTabActive) {
                    if (this.activeTab == 0) {
                        this.$store.state.calcIndex = 1;
                    } else if (this.activeTab == 2) {
                        this.$store.state.calcIndex = 2;
                    } else if (this.activeTab == 4) {
                        this.$store.state.calcIndex = 3;
                        this.totalLostpower_3 = 0;

                        if (this.$store.state.calcIndexFromOutputCircs != -1) {
                            this.activeTab = this.$store.state.calcIndexFromOutputCircs * 2;
                            this.$store.state.calcIndexFromOutputCircs = -1;
                        }
                    }
                } else {
                    if (this.activeTab == 0) {
                        this.$store.state.calcIndex = 1;
                    } else if (this.activeTab == 2) {
                        this.$store.state.calcIndex = 3;
                        this.totalLostpower_3 = 0;

                        if (this.$store.state.calcIndexFromOutputCircs != -1) {
                            this.activeTab = this.$store.state.calcIndexFromOutputCircs * 2;
                            this.$store.state.calcIndexFromOutputCircs = -1;
                        }
                    }
                }

                this.resetCalc_1();
                this.resetCalc_2();
                this.resetCalc_3();
                this.devicesdata = [];
                this.isTabsModalActive = false;

                if (this.tempTabIndex == 2) {
                    router.push('/calc_Busbar');
                }
            },
            resetCalc_1() {
                var payload1 = { totalLostPower_1: 0 };
                this.$store.commit('setCalculationDatas', payload1);

                this.totalLostpower = 0;
            },
            resetCalc_2() {
                var payload2 = { inPanelTemperature: 0, envMeasureTemperature: 0, totalLostPower_2: 0 };
                this.$store.commit('setCalculationDatas_2', payload2);

                this.totalLostpower_2 = 0;
            },
            resetCalc_3() {
                var payload3 = { InputCircuitDef: "", P_InputCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_InputCircuits', payload3);

                var payload4 = { OutputCircuitDef: "", P_OutputCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_OutputCircuits', payload4);

                var payload5 = { ManuelCircuitDef: "", P_ManuelCircuit: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_ManuelCircuits', payload5);

                var payload6 = { ManuelCircuitDef: "", P_Busbar: 0, totalLostPower_3: 0 };
                this.$store.commit('set_Datas_Busbar', payload6);

                api.panels.deleteFromCalcTables_1(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.deleteFromCalcTables_2(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.deleteFromCalcTables_3(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.deleteFromCalcTables_4(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.deleteFromCalcTables_5(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.deleteFromCalcTables_6(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });
                api.panels.deleteFromCalcTables_7(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    }
                });

                this.totalLostpower_3 = 0;
            },
            controlInputs() {
                if (this.activeTab == 0) {
                    if (this.totalLostpower == 0) {
                        this.hasError_totalLostpower = true;
                        this.emptyWarning_totalLostpower = this.$lang.Menus.EmptyWarning;
                    } else {
                        this.canGoNextPage = true;
                        this.emptyWarning_totalLostpower = '';
                    }

                    if (this.totalLostpower != 0) {
                        this.hasError_totalLostpower = false;
                        this.emptyWarning_totalLostpower = '';
                    }
                } else if (this.activeTab == 2) {
                    if (!this.isSecondTabActive) {
                        if (this.devicesdata.length > 0) {
                            this.canGoNextPage = true;
                        } else {
                            this.isCalcWarningModalActive = true;
                        }
                    } else {
                        if (this.paneltemperature == 0) {
                            this.hasError_paneltemperature = true;
                            this.emptyWarning_paneltemperature = this.$lang.Menus.EmptyWarning;
                        } else if (this.envtemperature == 0) {
                            this.hasError_envtemperature = true;
                            this.emptyWarning_envtemperature = this.$lang.Menus.EmptyWarning;
                        } else {
                            this.canGoNextPage = true;
                        }

                        if (this.paneltemperature != 0) {
                            this.hasError_paneltemperature = false;
                            this.emptyWarning_paneltemperature = '';
                        }
                        if (this.envtemperature != 0) {
                            this.hasError_envtemperature = false;
                            this.emptyWarning_envtemperature = '';
                        }
                    }
                } else if (this.activeTab == 4) {
                    if (this.devicesdata.length > 0) {
                        this.canGoNextPage = true;
                    } else {
                        this.isCalcWarningModalActive = true;
                    }
                }
            },
            getCalculationDatas() {
                api.panels.getBusbarCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            var device1 = {
                                Id: 0,
                                Definition: e.Definition,
                                LostPower: e.CalcResult
                            }

                            var temp = parseFloat(this.totalLostpower_3);
                            temp += parseFloat(e.CalcResult);
                            this.totalLostpower_3 = temp;
                            this.devicesdata.push(device1);
                        });

                        api.panels.getInputCircuitCalcDevices(this.projectId).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                var tempPower = 0;
                                res.data.forEach((e) => {
                                    tempPower += e.CalcResult;

                                    var temp = parseFloat(this.totalLostpower_3);
                                    temp += parseFloat(e.CalcResult);
                                    this.totalLostpower_3 = temp;
                                });

                                api.panels.getInputCircuitDeviceConductors(this.projectId).then((res) => {
                                    if (res.isError) {
                                        console.log("error ");
                                    } else {
                                        res.data.forEach((e) => {
                                            tempPower += e.CalcResult;
                                            var device3 = {
                                                Id: 0,
                                                Definition: e.Definition,
                                                LostPower: tempPower
                                            }

                                            var temp = parseFloat(this.totalLostpower_3);
                                            temp += parseFloat(e.CalcResult);
                                            this.totalLostpower_3 = temp;
                                            this.devicesdata.push(device3);
                                        });

                                        this.get_CalcDatas_OutputCircCalc();
                                        this.getOutputCalcTablesSummary();
                                    }
                                });
                            }
                        });
                    }
                });
            },
            get_CalcDatas_OutputCircCalc() {
                api.panels.getOutputCircuitCalcDevices_Detailed(this.projectId).then((res1) => {
                    if (res1.isError) {
                        console.log("error ");
                    } else {
                        api.panels.getTotal_P_OutputCircuitCalculationDevices(this.projectId).then((res2) => {
                            if (res2.isError) {
                                console.log("error ");
                            } else {
                                var temp = parseFloat(this.totalLostpower_3);
                                temp += res2.data;
                                this.totalLostpower_3 = temp;

                                api.panels.getTotal_P_OutputCircuitDeviceConductors(this.projectId).then((res3) => {
                                    if (res3.isError) {
                                        console.log("error ");
                                    } else {
                                        var temp = parseFloat(this.totalLostpower_3);
                                        temp += res3.data;
                                        this.totalLostpower_3 = temp;

                                        api.panels.getTotal_P_OutputCircuit_SubDistributionBusbars(this.projectId).then((res4) => {
                                            if (res4.isError) {
                                                console.log("error ");
                                            } else {
                                                var temp = parseFloat(this.totalLostpower_3);
                                                temp += res4.data;
                                                this.totalLostpower_3 = temp;

                                                this.get_OutputCalcDatas_Manuel();
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            },
            get_OutputCalcDatas_Manuel() {
                api.panels.getManuelCalcDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            var device5 = {
                                Id: 2,
                                Definition: e.Explanation,
                                LostPower: e.LostPower
                            }

                            var temp = parseFloat(this.totalLostpower_3);
                            temp += parseFloat(e.LostPower);
                            this.totalLostpower_3 = temp;
                            this.devicesdata.push(device5);
                        });
                    }
                });
            },
            getOutputCalcTablesSummary() {
                var powerslist1 = [];
                var powerslist2 = [];
                var powerslist3 = [];
                api.panels.getOutputCircuitCalcDevices_Detailed(this.projectId).then((res1) => {
                    if (res1.isError) {
                        console.log("error ");
                    } else {
                        var temp = 0;
                        for (let i = 0; i < res1.data.length; i++) {
                            res1.data[i].Devices.forEach((e) => {
                                temp += e.P_CalcResult;
                            });
                            powerslist1.push(temp);
                            if (i < res1.data.length - 1) {
                                if (res1.data[i].Definition < res1.data[i + 1].Definition || res1.data[i].Definition > res1.data[i + 1].Definition) {
                                    temp = 0;
                                }
                            }
                        }

                        api.panels.getOutputCircuitDeviceConductors_Detailed(this.projectId).then((res2) => {
                            if (res2.isError) {
                                console.log("error ");
                            } else {
                                res2.data.forEach((e) => {
                                    e.Devices.forEach((element) => {
                                        powerslist2.push(element.P_CalcResult);
                                    });
                                });

                                api.panels.get_OutputCircuit_SubDistributionBusbars_Detailed(this.projectId).then((res3) => {
                                    if (res3.isError) {
                                        console.log("error ");
                                    } else {
                                        res3.data.forEach((e) => {
                                            e.Devices.forEach((element) => {
                                                powerslist3.push(element.P_CalcResult);
                                            });
                                            var index = res3.data.indexOf(e);
                                            var device = {
                                                Id: 1,
                                                Definition: e.Definition,
                                                LostPower: powerslist1[index] + powerslist2[index] + powerslist3[index]
                                            }
                                            this.devicesdata.push(device);
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            },
            updateCalculationData(row) {
                if (row.Id == 0) {
                    this.openInputCircuit();
                } else if (row.Id == 1) {
                    this.openOutputCircuit();
                } else if (row.Id == 2) {
                    this.openExtraPower();
                }
            },
            openCalcBusbar() {
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_Busbar');
            },
            openInputCircuit() {
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_InputCircuit');
            },
            openOutputCircuit() {
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_OutputCircuit');
            },
            openExtraPower() {
                this.areHiddenStepsVisible = true;
                this.$store.state.areHiddenStepsVisible = this.areHiddenStepsVisible;
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_Manuel');
            },
            openCircuitDevices() {
                this.$store.state.isCircDevicesFromCalculation = true;
                router.push('/circuitDevices');
            },
            calculateLostPower_CurrentPanel() {
                if (this.isSecondTabActive && this.activeTab == 1) {
                    this.controlInputs();
                }
                var alfa = 0;
                if (this.$store.state.selectedIP_Id == 1) {
                    alfa = 3;
                } else {
                    alfa = 4.2;
                }
                this.totalLostpower_2 = alfa * this.$store.state.ae_value * (this.paneltemperature - this.envtemperature);
                this.totalLostpower_2 = parseFloat(this.totalLostpower_2);
                this.isLostPowerCalculated = true;
            },
            truncate(numToBeTruncated, numOfDecimals) {
                var theNumber = numToBeTruncated.toString();
                if (theNumber.includes('.')) {
                    var pointIndex = theNumber.indexOf('.');
                    var _result = +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
                    var _resultString = _result.toString();
                    if (!_resultString.includes('.')) {
                        return _resultString + '.0';
                    } else {
                        return _result;
                    }
                } else {
                    return theNumber + '.0';
                }
            },
            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },
        },

    };
</script>

<style>
    .multiline {
        margin: 10px auto;
        width: 30%;
    }
        .tabcolumn {
            margin-bottom: 10px;
    }

/*    .btabColumns {
        display: flex !important;
        justify-content: center;
    }*/

    @media screen and (max-width: 768px) {
        .tabs ul li {
            width: 50% !important;
            margin: 0.5rem;
        }

        .tabText {
            display:flex !important;
        }
    }
</style>