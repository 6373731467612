<template>
	<nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
		<div class="navbar-brand">
			<a class="navbar-item is-hidden-desktop" @click.prevent="asideToggleMobile">
				<b-icon :icon="asideMobileIcon" />
			</a>
			<a class="navbar-item is-hidden-touch is-hidden-widescreen is-desktop-icon-only" @click.prevent="asideDesktopOnlyToggle">
				<b-icon icon="menu" />
			</a>
		</div>
		<div class="navbar-brand is-right">
			<a class="navbar-item navbar-item-menu-toggle is-hidden-desktop" @click.prevent="menuToggle">
				<b-icon :icon="menuToggleIcon" custom-size="default" />
			</a>
		</div>		

		<div class="navbar-menu fadeIn animated faster" :class="{ 'is-active': isMenuActive }">
			<div class="navbar-end">

				<div style="padding-top: 0.5rem;">
					<div class="field is-grouped">
						<b-field style="padding-top: 0.5rem;">
							<b-field>{{this.$lang.Menus.LangOptions}}</b-field>
						</b-field>
						<b-field>
							<b-select v-model="selectedLanguage" @input="setLanguage">
								<option v-for="option in langlist"
										:value="option"
										:key="option.id">
									{{ option }}
								</option>
							</b-select>
						</b-field>
					</div>
				</div>

				<nav-bar-menu class="has-divider">
					<a class="is-user-name" @click="openProfileOrLogin">
						<b-icon icon="account" type="is-primary" size="is-small"></b-icon>
						{{ this.$store.state.userInfo.Name }}
					</a>
					<!--<div slot="dropdown" class="navbar-dropdown">
			<router-link to="/ChangePassword" class="navbar-item" exact-active-class="is-active">
				<b-icon icon="lock" custom-size="default" />
				<span>Şifre Değiştir</span>
			</router-link>
		</div>-->
				</nav-bar-menu>
				<a class="navbar-item is-desktop-icon-only" title="Log out" @click="logout">
					<b-icon icon="logout" type="is-danger" />
					<span>Log out</span>
				</a>
			</div>
		</div>
	</nav>
</template>

<script>
import { useStore } from '@/store';
import { useRouter } from '@/router';
import { computed, ref, onMounted } from '@vue/composition-api';
import NavBarMenu from '@/components/NavBarMenu.vue';
import api from '@/api';
import Vue from 'vue'

export default {
	name: 'NavBar',
        
	components: {
		NavBarMenu
	},
	methods: {
        setLanguage() {
            if (this.selectedLanguage == "Türkçe") {
                this.$config.lang = "tr-TR";
            } else if (this.selectedLanguage == "English") {
                this.$config.lang = "en-EN";
			}
            window.localStorage.setItem("lang", this.$config.langCode);
            location.reload();
        }
	},
	setup(props) {
		const store = useStore();

		const asideToggleMobile = () => {
			store.commit('asideMobileStateToggle');
		};

		const asideDesktopOnlyToggle = () => {
			store.dispatch('asideDesktopOnlyToggle');
		};

		const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded);

		const asideMobileIcon = computed(() => (isAsideMobileExpanded.value ? 'backburger' : 'forwardburger'));

		const isNavBarVisible = computed(() => store.state.isNavBarVisible);

		const userName = computed(() => store.state.userInfo.Name.value);

		const isMenuActive = ref(false);

		const menuToggle = () => {
			isMenuActive.value = !isMenuActive.value;
		};

		const menuToggleIcon = computed(() => (isMenuActive.value ? 'close' : 'dots-vertical'));

		const router = useRouter();

		onMounted(() => {
			router.afterEach(() => {
				isMenuActive.value = false;
			});

            if (store.state.memberId != 'TestMember') {
                api.members.getMember_2(store.state.memberId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
						store.state.userInfo.Name = res.data.Name;
                    }
                });                
			}
		});

		const logout = () => {
            store.commit('logout', Vue.prototype.$lang.Login.LoginBtn);
			store.state.memberId = 'TestMember';
			// api.auth.logout();

			router.push('/');
		};

		const openProfileOrLogin = () => {
			if (store.state.memberId == 'TestMember') {
                router.push('/loginMember');
			} else {
                router.push('/updateMember');
			}
		};

		const langlist = ["Türkçe", "English"];
		const selectedLanguage = '';

		return {
			asideToggleMobile,
			asideDesktopOnlyToggle,
			isAsideMobileExpanded,
			asideMobileIcon,
			isNavBarVisible,
			userName,
			isMenuActive,
			menuToggle,
			menuToggleIcon,
			selectedLanguage,
            langlist,
			logout,
            openProfileOrLogin
		};
	}
};
</script>
