<template>
	<aside v-show="isAsideVisible" class="aside is-placed-left">
		<div class="aside-tools">
			<a class="navbar-item is-hidden-touch is-hidden-widescreen is-desktop-icon-only" @click="asideToggleDesktopOnly">
				<b-icon icon="menu" />
			</a>
			<a class="aside-tools-label" href="/Home" style="margin:0 auto;">
				<img style="width:180px; margin-top:15px;" src="/img/e-kabin-logo.png" />
			</a>
		</div>
		<div class="menu is-menu-main">
			<!-- <template v-for="(menuGroup, index) in menu">
				<p v-if="typeof menuGroup === 'string'" :key="index" class="menu-label">
					{{ menuGroup }}
				</p>
			</template> -->
			<aside-menu-list :menu="menu" @menu-click="menuClick" />
		</div>
	</aside>
</template>

<script>
import { mapState } from 'vuex';
import AsideMenuList from '@/components/AsideMenuList.vue';

export default {
	name: 'AsideMenu',
	components: { AsideMenuList },
	props: {
		menu: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		...mapState(['isAsideVisible'])
	},
	mounted() {
		this.$router.afterEach(() => {
			this.$store.dispatch('asideDesktopOnlyToggle', false);
		});
	},
	methods: {
		asideToggleDesktopOnly() {
			this.$store.dispatch('asideDesktopOnlyToggle');
		},
		menuClick(item) {
			//
		}
	}
};
</script>
