import { axios } from '@/api/common';


export default {
    sendResetPasswordEmail(emailParam) {
        return axios.post('/Member/SendResetPasswordEmail', { email: emailParam }).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    resetPassword(memberCode, newPassword) {
        return axios.get('/Member/ResetPassword?memberCode=' + memberCode + '&NewPassword=' + newPassword).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    IsMailUnique(email) {
        return axios.get('/Member/IsMailUnique?Email=' + email).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    IsMailUnique_ForUpdate(memberId, email) {
        return axios.get('/Member/IsMailUnique_ForUpdate?MemberId=' + memberId + '&Email=' + email).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    IsPhoneNumberUnique(phone) {
        return axios.get('/Member/IsPhoneNumberUnique?Phone=' + phone).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    IsPhoneNumberUnique_ForUpdate(memberId, phone) {
        return axios.get('/Member/IsPhoneNumberUnique_ForUpdate?MemberId=' + memberId + '&Phone=' + phone).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    encryptLogin(model) {
        return axios.post('/Member/EncryptLogin', model).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    encryptMember(model) {
        return axios.post('/Member/EncryptMember', model).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    decryptMember(model) {
        return axios.post('/Member/DecryptMember', model).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addNewMember(member) {
        return axios.post('/Member/AddNewMember', member).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateMember(member) {
        return axios.post('/Member/UpdateMember', member).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getMemberId(email, password) {
        return axios.get('/Member/GetMemberId?Email=' + email + '&Password=' + password).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getMember(email, password) {
        return axios.get('/Member/GetMember?Email=' + email + '&Password=' + password).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getMember_2(memberId) {
        return axios.get('/Member/GetMember_2?MemberId=' + memberId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCountries() {
        return axios.post('/Member/GetCountries').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCities(countryId) {
        return axios.get('/Member/GetCities?CountryId=' + countryId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCommLanguages() {
        return axios.post('/Member/GetCommLanguages').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    SendUserListDownloadedMail(memberId) {
        return axios.post('/Member/SendUserListDownloadedMail?memberId=' + memberId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addTempMember(member) {
        return axios.post('/Member/AddTempMember', member).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    verifyMember(code) {
        return axios.post('/Member/VerifyMember', code).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    resendCodes(code) {
        return axios.post('/Member/ResendCodes', code).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    }
}
