<template>
    <div>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.CalcCircInfos.Title_1}} &nbsp; <span style="font-size:1vw">{{this.$lang.CalcCircInfos.Title_2}}</span>

            <p v-if="this.$store.state.isCompleted" class="control" slot="right" style="margin-right: 16px">
                <saveButton />
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="is-warning" @click="openCircuitDevices" style="height: 88%;">
                    {{this.$lang.Calculation.InnerTitle_14}} <br /> {{this.$lang.Calculation.InnerTitle_15}} <br />{{this.$lang.Calculation.InnerTitle_16}}
                </b-button>
            </p>
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 0.75rem; padding-bottom: 1rem">
            <div class="columns is-multiline is-mobile">
                <div class="column is-2" style="background-color: #FFFFFF; width: 10%; display: flex; min-height: 100%; flex-direction: column;">
                    <verticalStep-bar />
                </div>
                <div class="column is-10">
                    <calculationTabs />
                    <div style="padding: 0.5rem;">
                        <outputCircBtns />
                    </div>
                    <div class="columns is-mobile is-multiline is-centered">
                        <div class="column">
                            <span style="font-size:1vw"> {{ this.$lang.CalcCircInfos.Title_14 }} </span>

                            <b-field grouped>
                                <b-field :label="this.$lang.CalcCircInfos.CircuitDef" :type="{ 'is-danger': hasError_circuitDefinition }" :message="{ 'Alan Boş Bırakılamaz!': hasError_circuitDefinition }">
                                    <b-input v-model="circuitDefinition"></b-input>
                                </b-field>
                                <b-field style="padding-left: 1rem" :type="{ 'is-danger': hasError_ratedCurrent }" :message="{ 'Alan Boş Bırakılamaz!': hasError_ratedCurrent }">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.CircuitRatedCurr"></span>
                                    </template>
                                    <b-select v-if="ratedCurrentList" v-model="ratedCurrent" @input="setIng">
                                        <option v-for="option in ratedCurrentList"
                                                :value="option.CurrentVal"
                                                :key="option.id">
                                            {{ option.CurrentVal }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.RDF" style="padding-left: 2rem" :type="{ 'is-danger': hasError_rdf }" :message="{ 'Alan Boş Bırakılamaz!': hasError_rdf }">
                                    <b-select v-if="rdfList" v-model="rdf" @input="setIng">
                                        <option v-for="option in rdfList"
                                                :value="option"
                                                :key="option.id">
                                            {{ option }}
                                        </option>
                                    </b-select>
                                </b-field>
                                <b-field style="width: 6%; padding-left: 2rem">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Ing"></span>
                                    </template>
                                    <b-input v-model="Ing" type="number" step="any" disabled></b-input>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.CircuitNumber" style="padding-left: 1rem; width: 7%"
                                         :type="{ 'is-danger': hasError_numberOfCircuits,
                                                  'is-danger': hasError_numberOfCircuits2 }"
                                         :message="{  'Alan Boş Bırakılamaz!': hasError_numberOfCircuits,
                                                      'Devre sayısı minimum 1 olabilir!': hasError_numberOfCircuits2 }">
                                    <b-input v-model="numberOfCircuits" type="number" step="1" @input="controlNumOfCircuits()"></b-input>
                                </b-field>
                            </b-field>
                            <b-field style="padding-top: 1rem;"></b-field>
                            <b-field :label="this.$lang.CalcCircInfos.Title_9"></b-field>
                            <b-field grouped>
                                <b-field :label="this.$lang.CalcCircInfos.Type" :label-position="labelPosition" :type="{ 'is-danger': hasError_type }" :message="{ 'Alan Boş Bırakılamaz!': hasError_type }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Type">
                                        <b-select v-if="typesList" v-model="type" @input="getCircuitDevice_Brands">
                                            <option v-for="option in typesList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>

                                <b-field :label="this.$lang.CalcCircInfos.Brand" :label-position="labelPosition" :type="{ 'is-danger': hasError_brand }" :message="{ 'Alan Boş Bırakılamaz!': hasError_brand }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Brand">
                                        <b-select v-if="brandsList" v-model="brand" @input="getCircuitDevice_Codes">
                                            <option v-for="option in brandsList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.Code" :label-position="labelPosition" :type="{ 'is-danger': hasError_code }" :message="{ 'Alan Boş Bırakılamaz!': hasError_code }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Code">
                                        <b-select v-if="codesList" v-model="code" @input="getCircuitDevice_Ins">
                                            <option v-for="option in codesList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isIn_AVisible" :label="this.$lang.CalcCircInfos.In_A" :label-position="labelPosition" :type="{ 'is-danger': hasError_in_A }" :message="{ 'Alan Boş Bırakılamaz!': hasError_in_A }">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.In_A"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.In_A"></span>
                                        </template>
                                        <b-select v-if="in_valList" v-model="in_A" @input="getCircuitDevice_Pole">
                                            <option v-for="option in in_valList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isPoleNumVisible" :label="this.$lang.CalcCircInfos.PoleNumber" :label-position="labelPosition" :type="{ 'is-danger': hasError_poleNumber }" :message="{ 'Alan Boş Bırakılamaz!': hasError_poleNumber }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PoleNumber">
                                        <b-select v-if="poleList" v-model="poleNumber" @input="getCircuitDevice_Sbt_Cekmeceli">
                                            <option v-for="option in poleList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="is_sbt_cekmeceliVisible" :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :label-position="labelPosition" :type="{ 'is-danger': hasError_sbt_cekmeceli }" :message="{ 'Alan Boş Bırakılamaz!': hasError_sbt_cekmeceli }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Sbt_cekmeceli">
                                        <b-select v-if="sbt_cekmeceliList" v-model="sbt_cekmeceli" @input="getCircuitDevice_Power">
                                            <option v-for="option in sbt_cekmeceliList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isPowerVisible" :label="this.$lang.CalcCircInfos.Power" :label-position="labelPosition" :type="{ 'is-danger': hasError_power }" :message="{ 'Alan Boş Bırakılamaz!': hasError_power }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Power">
                                        <b-select v-if="powerList" v-model="power" @input="getCircuitDevice_Pv">
                                            <option v-for="option in powerList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <p class="control">
                                    <b-button type="is-success" :label="this.$lang.CalcCircInfos.AddButton" @click="addToOutputCircuitCalcDevices" :disabled="isButtonDisabled" />
                                </p>
                            </b-field>

                            <b-table v-if="circuitcalculationdevicesdata.length != 0" :data="circuitcalculationdevicesdata">
                                <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                    {{ props.row.Definition}}
                                </b-table-column>
                                <b-table-column field="Type" :label="this.$lang.CalcCircInfos.Type" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                    {{ props.row.Type}}
                                </b-table-column>
                                <b-table-column field="Brand" :label="this.$lang.CalcCircInfos.Brand" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                    {{ props.row.Brand }}
                                </b-table-column>
                                <b-table-column field="Code" :label="this.$lang.CalcCircInfos.Code" :td-attrs="columnTdAttrs" v-slot="props" width="200">
                                    {{ props.row.Code }}
                                </b-table-column>
                                <b-table-column field="In_Value" :td-attrs="columnTdAttrs" width="100">
                                    <template v-slot:header="">
                                        <span v-html="$lang.CalcCircInfos.In_A"></span>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.In_Value }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="Pole" :label="this.$lang.CalcCircInfos.PoleNumber" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.Pole }}
                                </b-table-column>
                                <b-table-column field="Sbt_Cekmeceli" :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                    {{ props.row.Sbt_Cekmeceli }}
                                </b-table-column>
                                <b-table-column field="Power" :label="this.$lang.CalcCircInfos.Power" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.Power }}
                                </b-table-column>
                                <b-table-column field="Pv" :label="this.$lang.CalcCircInfos.Pv" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ getPvCalculated(props.row.Pv, props.row.RDF) }}
                                </b-table-column>

                                <b-table-column field="Action" v-slot="props">
                                    <button class="button is-small is-light" @click="openUpdateModal_OutputCircuitCalcDevices(props.row)">
                                        <b-icon icon="pen" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-danger" @click="deleteFromOutputCircuitCalcDevices(props.row)">
                                        <b-icon icon="delete" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-light" @click="copyFromOutputCircuitCalcDevices(props.row)" style="width: 30px">
                                        <!--<b-icon icon="file" size="is-small"></b-icon>-->
                                        <div class="material-symbols-outlined">
                                            content_copy
                                        </div>
                                    </button>
                                </b-table-column>
                            </b-table>

                            <div style="padding-top: 3rem"></div>
                            <b-field :label="this.$lang.CalcCircInfos.Title_10"></b-field>
                            <div style="padding-top: 1rem"></div>
                            <b-field grouped>
                                <b-field :label="this.$lang.CalcCircInfos.ConnType" :label-position="labelPosition" :type="{ 'is-danger': hasError_connType }" :message="{ 'Alan Boş Bırakılamaz!': hasError_connType }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConnType">
                                        <b-select v-if="connTypeList" v-model="connType" @input="getCablePositions">
                                            <option v-for="option in connTypeList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isCablePosVisible" :label="this.$lang.CalcCircInfos.CablePos" :label-position="labelPosition" :type="{ 'is-danger': hasError_cablePos }" :message="{ 'Alan Boş Bırakılamaz!': hasError_cablePos }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.CablePos">
                                        <b-select v-if="cablePosList" v-model="cablePos" @input="getConductorDimensions">
                                            <option v-for="option in cablePosList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.PhaseNumber" :label-position="labelPosition" :type="{ 'is-danger': hasError_phaseNumber_conductor }" :message="{ 'Alan Boş Bırakılamaz!': hasError_phaseNumber_conductor }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PhaseNumber">
                                        <b-select v-if="phaseNumber_conductor_List" v-model="phaseNumber_conductor">
                                            <option v-for="option in phaseNumber_conductor_List"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorDimension" :label-position="labelPosition" :type="{ 'is-danger': hasError_conductorDimension }" :message="{ 'Alan Boş Bırakılamaz!': hasError_conductorDimension }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorDimension">
                                        <b-select v-if="condDimensionList" v-model="conductorDimension" @input="getConductor_Imax_and_Power">
                                            <option v-for="option in condDimensionList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorLength" :label-position="labelPosition" style="width: 10%;" :type="{ 'is-danger': hasError_conductorLength }" :message="{ 'Alan Boş Bırakılamaz!': hasError_conductorLength }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorLength">
                                        <b-numberinput v-model="conductorLength" :controls=false type="number" step="any" @input="setPositiveNumber()"></b-numberinput>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorTemp" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorTemp">
                                        <b-input v-model="conductorTemp" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 10%;">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <b-input v-model="Tmax_inPanel" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 10%;">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Imax"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <b-input v-model="Imax_conductor" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.LostPower" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.LostPower">
                                        <b-input v-model="lostPower_conductor" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <p class="control">
                                    <b-button type="is-success" :label="this.$lang.CalcCircInfos.AddButton" @click="addToOutputCircuitDeviceConductors" />
                                </p>
                            </b-field>

                            <b-table v-if="circuitdeviceconductorsdata.length != 0" :data="circuitdeviceconductorsdata">

                                <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.Definition }}
                                </b-table-column>
                                <b-table-column field="ConnectionType" :label="this.$lang.CalcCircInfos.ConnType" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                    {{ props.row.ConnectionType }}
                                </b-table-column>
                                <b-table-column v-if="isCablePosVisible" field="CablePosition" :label="this.$lang.CalcCircInfos.CablePos" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                    {{ props.row.CablePosition }}
                                </b-table-column>
                                <b-table-column field="PhaseNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs" v-slot="props" width="200">
                                    {{ props.row.PhaseNumber }}
                                </b-table-column>
                                <b-table-column field="DimensionInfo" :label="this.$lang.CalcCircInfos.ConductorDimension" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.DimensionInfo }}
                                </b-table-column>
                                <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.ConductorLength }}
                                </b-table-column>
                                <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.ConductorTemperature }}
                                </b-table-column>
                                <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs" width="150">
                                    <template v-slot:header="">
                                        <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.MaxTemperature }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="I_Max" :td-attrs="columnTdAttrs" width="100">
                                    <template v-slot:header="">
                                        <span v-html="$lang.CalcCircInfos.Imax"></span>
                                    </template>
                                    <template v-slot="props">
                                        {{ truncate(props.row.I_Max, 1) }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ truncate(props.row.LostPower, 1) }}
                                </b-table-column>
                                <b-table-column field="P_CalcResult" :td-attrs="columnTdAttrs" width="100">
                                    <template v-slot:header="">
                                        <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                    </template>
                                    <template v-slot="props">
                                        {{ truncate(props.row.P_CalcResult, 1) }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="Action" v-slot="props">
                                    <button class="button is-small is-light" @click="openUpdateModal_OutputCircuitDeviceConductors(props.row)">
                                        <b-icon icon="pen" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-danger" @click="deleteFromOutputCircuitDeviceConductors(props.row)">
                                        <b-icon icon="delete" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-light" @click="copyFromOutputCircuitDeviceConductors(props.row)" style="width: 30px">
                                        <!--<b-icon icon="file" size="is-small"></b-icon>-->
                                        <div class="material-symbols-outlined">
                                            content_copy
                                        </div>
                                    </button>
                                </b-table-column>
                            </b-table>

                            <div style="padding-top: 3rem"></div>
                            <b-field :label="this.$lang.CalcCircInfos.Title_11"></b-field>
                            <div style="padding-top: 1rem"></div>
                            <b-field grouped>
                                <b-field :label="this.$lang.CalcCircInfos.ConnType" :label-position="labelPosition" :type="{ 'is-danger': hasError_connType_2 }" :message="{ 'Alan Boş Bırakılamaz!': hasError_connType_2 }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConnType">
                                        <b-select v-if="connTypeList" v-model="connType_2" @input="getCablePositions_2">
                                            <option v-for="option in connTypeList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isCablePosVisible_2" :label="this.$lang.CalcCircInfos.CablePos" :label-position="labelPosition" :type="{ 'is-danger': hasError_cablePos_2 }" :message="{ 'Alan Boş Bırakılamaz!': hasError_cablePos_2 }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.CablePos">
                                        <b-select v-if="cablePosList_2" v-model="cablePos_2" @input="getConductorDimensions_2">
                                            <option v-for="option in cablePosList_2"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.PhaseNumber" :label-position="labelPosition" :type="{ 'is-danger': hasError_phaseNumber_conductor_2 }" :message="{ 'Alan Boş Bırakılamaz!': hasError_phaseNumber_conductor_2 }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PhaseNumber">
                                        <b-select v-if="phaseNumber_conductor_List_2" v-model="phaseNumber_conductor_2">
                                            <option v-for="option in phaseNumber_conductor_List_2"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorDimension" :label-position="labelPosition" :type="{ 'is-danger': hasError_conductorDimension_2 }" :message="{ 'Alan Boş Bırakılamaz!': hasError_conductorDimension_2 }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorDimension">
                                        <b-select v-if="condDimensionList_2" v-model="conductorDimension_2" @input="getConductor_Imax_and_Power_2">
                                            <option v-for="option in condDimensionList_2"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorLength" :label-position="labelPosition" style="width: 10%;" :type="{ 'is-danger': hasError_conductorLength_2 }" :message="{ 'Alan Boş Bırakılamaz!': hasError_conductorLength_2 }">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorLength">
                                        <b-numberinput v-model="conductorLength_2" :controls=false type="number" step="any" @input="setPositiveNumber()"></b-numberinput>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorTemp" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorTemp">
                                        <b-input v-model="conductorTemp" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 10%;">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <b-input v-model="Tmax_inPanel" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 10%;">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Imax"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <b-input v-model="Imax_conductor_2" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.LostPower" :label-position="labelPosition" style="width: 10%;">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.LostPower">
                                        <b-input v-model="lostPower_conductor_2" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <p class="control">
                                    <b-button type="is-success" :label="this.$lang.CalcCircInfos.AddButton" @click="addToOutputCircuit_SubDistributionBusbars" />
                                </p>
                            </b-field>

                            <b-table v-if="subdistributionbusbarsdata.length != 0" :data="subdistributionbusbarsdata">
                                <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.Definition }}
                                </b-table-column>
                                <b-table-column field="ConnectionType" :label="this.$lang.CalcCircInfos.ConnType" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                    {{ props.row.ConnectionType }}
                                </b-table-column>
                                <b-table-column v-if="isCablePosVisible" field="CablePosition" :label="this.$lang.CalcCircInfos.CablePos" :td-attrs="columnTdAttrs" v-slot="props" width="150">
                                    {{ props.row.CablePosition }}
                                </b-table-column>
                                <b-table-column field="PhaseNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs" v-slot="props" width="200">
                                    {{ props.row.PhaseNumber }}
                                </b-table-column>
                                <b-table-column field="DimensionInfo" :label="this.$lang.CalcCircInfos.ConductorDimension" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.DimensionInfo }}
                                </b-table-column>
                                <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.ConductorLength }}
                                </b-table-column>
                                <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ props.row.ConductorTemperature }}
                                </b-table-column>
                                <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs" width="150">
                                    <template v-slot:header="">
                                        <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                    </template>
                                    <template v-slot="props">
                                        {{ props.row.MaxTemperature }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="I_Max" :td-attrs="columnTdAttrs" width="100">
                                    <template v-slot:header="">
                                        <span v-html="$lang.CalcCircInfos.Imax"></span>
                                    </template>
                                    <template v-slot="props">
                                        {{ truncate(props.row.I_Max, 1) }}
                                    </template>
                                </b-table-column>
                                <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                                    {{ truncate(props.row.LostPower, 1) }}
                                </b-table-column>
                                <b-table-column field="P_CalcResult" :td-attrs="columnTdAttrs" width="100">
                                    <template v-slot:header="">
                                        <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                                    </template>
                                    <template v-slot="props">
                                        {{ truncate(props.row.P_CalcResult, 1) }}
                                    </template>
                                </b-table-column>

                                <b-table-column field="Action" v-slot="props">
                                    <button class="button is-small is-light" @click="openUpdateModal_OutputCircuit_SubDistributionBusbars(props.row)">
                                        <b-icon icon="pen" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-danger" @click="deleteFromOutputCircuit_SubDistributionBusbars(props.row)">
                                        <b-icon icon="delete" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-light" @click="copyFromOutputCircuit_SubDistributionBusbars(props.row)" style="width: 30px">
                                        <!--<b-icon icon="file" size="is-small"></b-icon>-->
                                        <div class="material-symbols-outlined">
                                            content_copy
                                        </div>
                                    </button>
                                </b-table-column>
                            </b-table>

                            <div class="columns is-mobile is-multiline is-centered" style="padding-top: 2rem;">
                                <b-field>
                                    <b-button class="button is-primary" @click="calculateThenAddAll">
                                        {{ this.$lang.CalcCircInfos.CalcAddButton }}
                                    </b-button>
                                </b-field>
                            </div>
                            <div class="columns is-mobile is-multiline is-centered" style="padding-top: 2rem;">
                                <b-field>
                                    <b-button class="button is-danger" @click="setForNewCircuit">
                                        {{ this.$lang.CalcCircInfos.AddNewCircuit }}
                                    </b-button>
                                </b-field>
                            </div>
                            <div style="padding-top: 2rem"></div>
                        </div>
                    </div>

                    <b-field :label="this.$lang.CalcCircInfos.Title_7"></b-field>
                    <b-field :label="this.$lang.CalcCircInfos.Title_9" style="padding-top: 0.5rem"></b-field>
                    <b-table :data="circuitcalculationdevicesdata_detailed"
                             hoverable
                             detailed
                             custom-detail-row
                             :show-detail-icon="false"
                             detail-key="CircuitIndex">

                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" centered width="70px">
                            <a class="customDetailField" @click="props.toggleDetails(props.row)">
                                {{ props.row.Definition }}
                            </a>
                        </b-table-column>
                        <b-table-column field="NumOfCircuits" :label="this.$lang.CalcCircInfos.AmountTitle" :td-attrs="columnTdAttrs" v-slot="props" centered width="70px">
                            {{ props.row.NumOfCircuits }}
                        </b-table-column>
                        <b-table-column field="Type" :label="this.$lang.CalcCircInfos.Type" :td-attrs="columnTdAttrs" centered width="70px">
                            -
                        </b-table-column>
                        <b-table-column field="Brand" :label="this.$lang.CalcCircInfos.Brand" :td-attrs="columnTdAttrs" centered width="70px">
                            -
                        </b-table-column>
                        <b-table-column field="Code" :label="this.$lang.CalcCircInfos.Code" :td-attrs="columnTdAttrs" centered width="70px">
                            -
                        </b-table-column>
                        <b-table-column field="In_Value" :td-attrs="columnTdAttrs" centered width="70px">
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.In_A"></span>
                            </template>
                            -
                        </b-table-column>
                        <b-table-column field="Pole" :label="this.$lang.CalcCircInfos.PoleNumber" :td-attrs="columnTdAttrs" centered width="70px">
                            -
                        </b-table-column>
                        <b-table-column field="Sbt_Cekmeceli" :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :td-attrs="columnTdAttrs" centered width="70px">
                            -
                        </b-table-column>
                        <b-table-column field="Power" :label="this.$lang.CalcCircInfos.Power" :td-attrs="columnTdAttrs" centered width="70px">
                            -
                        </b-table-column>
                        <b-table-column field="RDF" :label="this.$lang.CalcCircInfos.RDF" :td-attrs="columnTdAttrs" v-slot="props" centered width="10px">
                            {{ props.row.RDF }}
                        </b-table-column>
                        <b-table-column field="P_CalcResult" :label="this.$lang.CalcCircInfos.CalcResult2" :td-attrs="columnTdAttrs" centered width="70px">
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.CalcResult2"></span>
                            </template>
                            -
                        </b-table-column>

                        <b-table-column v-slot="props" colspan="2">
                            <button class="button is-small is-light" @click="openUpdateModal_OutputCircuits(props.row, 1)">
                                <b-icon icon="pen" size="is-small"></b-icon>
                            </button>
                            <button class="button is-small is-danger" @click="deleteFrom_OutputCircuits(props.row)">
                                <b-icon icon="delete" size="is-small"></b-icon>
                            </button>
                        </b-table-column>

                        <template slot="detail" slot-scope="props">
                            <tr v-for="item in props.row.Devices" :key="item.Definition">
                                <td class="has-text-centered" width="70px">{{ item.Definition }}</td>
                                <td class="has-text-centered" width="70px">{{ item.NumOfCircuits }}</td>
                                <td class="has-text-centered" width="70px">{{ item.Type }}</td>
                                <td class="has-text-centered" width="70px">{{ item.Brand }}</td>
                                <td class="has-text-centered" width="70px">{{ item.Code }}</td>
                                <td class="has-text-centered" width="70px">{{ item.In_Value }}</td>
                                <td class="has-text-centered" width="70px">{{ item.Pole }}</td>
                                <td class="has-text-centered" width="70px">{{ item.Sbt_Cekmeceli }}</td>
                                <td class="has-text-centered" width="70px">{{ item.Power }}</td>
                                <td class="has-text-centered" width="10px">{{ item.RDF }}</td>
                                <td class="has-text-centered" width="70px">{{ truncate(item.P_CalcResult, 1) }}</td>
                                <td class="has-text-centered" width="70px">

                                    <button class="button is-small is-light" @click="openUpdateModal_OutputCircuitCalcDevices_2(item, props.row)">
                                        <b-icon icon="pen" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-danger" @click="deleteFromOutputCircuitCalcDevices_2(item)">
                                        <b-icon icon="delete" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-light" @click="copyFromOutputCircuitCalcDevices_2(item, props.row)" style="width: 30px">
                                        <!--b-icon icon="file" size="is-small"></b-icon>-->
                                        <div class="material-symbols-outlined">
                                            content_copy
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </b-table>

                    <b-field :label="this.$lang.CalcCircInfos.Title_10" style="padding-top: 1.5rem"></b-field>
                    <b-table :data="circuitdeviceconductorsdata_detailed"
                             hoverable
                             detailed
                             custom-detail-row
                             :show-detail-icon="false"
                             detail-key="CircuitIndex">

                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" centered>
                            <a class="customDetailField" @click="props.toggleDetails(props.row)">
                                {{ props.row.Definition }}
                            </a>
                        </b-table-column>
                        <b-table-column field="NumOfCircuits" :label="this.$lang.CalcCircInfos.AmountTitle" :td-attrs="columnTdAttrs" v-slot="props" centered>
                            {{ props.row.NumOfCircuits }}
                        </b-table-column>
                        <b-table-column field="ConnectionType" :label="this.$lang.CalcCircInfos.ConnType" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="CablePosition" :label="this.$lang.CalcCircInfos.CablePos" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="PhaseNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="DimensionInfo" :label="this.$lang.CalcCircInfos.ConductorDimension" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs" centered>
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                            </template>
                            -
                        </b-table-column>
                        <b-table-column field="I_Max" :td-attrs="columnTdAttrs" centered>
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.Imax"></span>
                            </template>
                            -
                        </b-table-column>
                        <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="RDF" :label="this.$lang.CalcCircInfos.RDF" :td-attrs="columnTdAttrs" v-slot="props" centered>
                            {{ props.row.RDF }}
                        </b-table-column>
                        <b-table-column field="P_CalcResult" :label="this.$lang.CalcCircInfos.CalcResult" :td-attrs="columnTdAttrs" centered>
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                            </template>
                            -
                        </b-table-column>

                        <b-table-column field="Action" v-slot="props">
                            <button class="button is-small is-light" @click="openUpdateModal_OutputCircuits(props.row, 2)">
                                <b-icon icon="pen" size="is-small"></b-icon>
                            </button>
                            <button class="button is-small is-danger" @click="deleteFrom_OutputCircuits(props.row)">
                                <b-icon icon="delete" size="is-small"></b-icon>
                            </button>
                        </b-table-column>

                        <template slot="detail" slot-scope="props">
                            <tr v-for="item in props.row.Devices" :key="item.Definition">
                                <td class="has-text-centered">{{ item.Definition }}</td>
                                <td class="has-text-centered">{{ item.NumOfCircuits }}</td>
                                <td class="has-text-centered">{{ item.ConnectionType }}</td>
                                <td class="has-text-centered">{{ item.CablePosition }}</td>
                                <td class="has-text-centered">{{ item.PhaseNumber }}</td>
                                <td class="has-text-centered">{{ item.DimensionInfo }}</td>
                                <td class="has-text-centered">{{ item.ConductorLength }}</td>
                                <td class="has-text-centered">{{ item.ConductorTemperature }}</td>
                                <td class="has-text-centered">{{ item.MaxTemperature }}</td>
                                <td class="has-text-centered">{{ truncate(item.I_Max, 1) }}</td>
                                <td class="has-text-centered">{{ truncate(item.LostPower, 1) }}</td>
                                <td class="has-text-centered">{{ item.RDF }}</td>
                                <td class="has-text-centered">{{ truncate(item.P_CalcResult, 1) }}</td>
                                <td class="has-text-centered" width="70px">

                                    <button class="button is-small is-light" @click="openUpdateModal_OutputCircuitDeviceConductors_2(item, props.row)">
                                        <b-icon icon="pen" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-danger" @click="deleteFromOutputCircuitDeviceConductors_2(item)">
                                        <b-icon icon="delete" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-light" @click="copyFromOutputCircuitDeviceConductors_2(item, props.row)" style="width: 30px">
                                        <!--<b-icon icon="file" size="is-small"></b-icon>-->
                                        <div class="material-symbols-outlined">
                                            content_copy
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </b-table>

                    <div style="padding: 0.5rem;">
                        <div class="columns is-mobile is-multiline">
                            <div class="field is-grouped">
                                <b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_OutputCircs"></b-field>
                                </b-field>
                                <b-field>
                                    {{ truncate(allTotal_P_1, 1) }}
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <b-field style="padding-top: 3rem;"></b-field>

                    <b-field :label="this.$lang.CalcCircInfos.Title_11"></b-field>
                    <b-table :data="subdistributionbusbarsdata_detailed"
                             hoverable
                             detailed
                             custom-detail-row
                             :show-detail-icon="false"
                             detail-key="CircuitIndex">
                        <b-table-column field="Definition" :label="this.$lang.CalcCircInfos.TableDefinition" :td-attrs="columnTdAttrs" v-slot="props" centered>
                            <a class="customDetailField" @click="props.toggleDetails(props.row)">
                                {{ props.row.Definition }}
                            </a>
                        </b-table-column>
                        <b-table-column field="NumOfCircuits" :label="this.$lang.CalcCircInfos.AmountTitle" :td-attrs="columnTdAttrs" centered>
                            1
                        </b-table-column>
                        <b-table-column field="ConnectionType" :label="this.$lang.CalcCircInfos.ConnType" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="CablePosition" :label="this.$lang.CalcCircInfos.CablePos" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="PhaseNumber" :label="this.$lang.CalcCircInfos.PhaseNumber" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="DimensionInfo" :label="this.$lang.CalcCircInfos.ConductorDimension" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="ConductorLength" :label="this.$lang.CalcCircInfos.ConductorLength" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="ConductorTemperature" :label="this.$lang.CalcCircInfos.ConductorTemp" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="MaxTemperature" :td-attrs="columnTdAttrs" centered>
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                            </template>
                            -
                        </b-table-column>
                        <b-table-column field="I_Max" :label="this.$lang.CalcCircInfos.Imax" :td-attrs="columnTdAttrs" centered>
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.Imax"></span>
                            </template>
                            -
                        </b-table-column>
                        <b-table-column field="LostPower" :label="this.$lang.CalcCircInfos.LostPower" :td-attrs="columnTdAttrs" centered>
                            -
                        </b-table-column>
                        <b-table-column field="P_CalcResult" :td-attrs="columnTdAttrs" centered>
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.CalcResult"></span>
                            </template>
                            -
                        </b-table-column>

                        <b-table-column field="Action" v-slot="props">
                            <button class="button is-small is-light" @click="openUpdateModal_OutputCircuits(props.row, 3)">
                                <b-icon icon="pen" size="is-small"></b-icon>
                            </button>
                            <button class="button is-small is-danger" @click="deleteFrom_OutputCircuits(props.row)">
                                <b-icon icon="delete" size="is-small"></b-icon>
                            </button>
                        </b-table-column>

                        <template slot="detail" slot-scope="props">
                            <tr v-for="item in props.row.Devices" :key="item.Id">
                                <td class="has-text-centered">{{ item.Definition }}</td>
                                <td class="has-text-centered">{{ item.NumOfCircuits }}</td>
                                <td class="has-text-centered">{{ item.ConnectionType }}</td>
                                <td class="has-text-centered">{{ item.CablePosition }}</td>
                                <td class="has-text-centered">{{ item.PhaseNumber }}</td>
                                <td class="has-text-centered">{{ item.DimensionInfo }}</td>
                                <td class="has-text-centered">{{ item.ConductorLength }}</td>
                                <td class="has-text-centered">{{ item.ConductorTemperature }}</td>
                                <td class="has-text-centered">{{ item.MaxTemperature }}</td>
                                <td class="has-text-centered">{{ truncate(item.I_Max, 1) }}</td>
                                <td class="has-text-centered">{{ truncate(item.LostPower, 1) }}</td>
                                <td class="has-text-centered">{{ truncate(item.P_CalcResult, 1) }}</td>
                                <td class="has-text-centered" width="70px">

                                    <button class="button is-small is-light" @click="openUpdateModal_OutputCircuit_SubDistributionBusbars_2(item, props.row)">
                                        <b-icon icon="pen" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-danger" @click="deleteFromOutputCircuit_SubDistributionBusbars_2(item)">
                                        <b-icon icon="delete" size="is-small"></b-icon>
                                    </button>
                                    <button class="button is-small is-light" @click="copyFromOutputCircuit_SubDistributionBusbars_2(item, props.row)" style="width: 30px">
                                        <!--<b-icon icon="file" size="is-small"></b-icon>-->
                                        <div class="material-symbols-outlined">
                                            content_copy
                                        </div>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </b-table>

                    <div style="padding: 0.5rem;">
                        <div class="columns is-mobile is-multiline">
                            <div class="field is-grouped">
                                <b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_SubDistBusbars"></b-field>
                                </b-field>
                                <b-field>
                                    {{ truncate(total_P_OutputCircuit_SubDistributionBusbars, 1) }}
                                </b-field>
                            </div>
                        </div>
                    </div>

                    <div style="padding-top: 3rem; padding-left: 0.5rem">
                        <div class="columns is-mobile is-multiline">
                            <div class="field is-grouped">
                                <b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.Total_P_OutputCircs"></b-field>
                                </b-field>
                                <b-field>
                                    {{ truncate(allTotal_P_2, 1) }}
                                </b-field>
                            </div>
                        </div>
                    </div>
                    <div class="card-content">
                        <div> <b> {{ this.$lang.CalcCircInfos.SubDistrBusbarWarning }} </b> </div>
                        <div> <b> {{ this.$lang.CalcCircInfos.WarningInfo }} </b> </div>
                    </div>
                    <div></div>

                </div>
            </div>
        </div>

        <b-modal v-model="isOutputCircuit_UpdateModalActive" :width="960">
            <div class="card">
                <div class="card-content">
                    <div class="media">
                        <div class="media-content">
                            <p v-if="this.isDeviceConductor" class="title is-4">{{ this.$lang.CalcCircInfos.UpdateDeviceConductor }}</p>
                            <p v-else-if="this.isSubDistrBusbar" class="title is-4">{{ this.$lang.CalcCircInfos.UpdateSubBusbar }}</p>
                            <p v-else class="title is-4">{{ this.$lang.CalcCircInfos.UpdateCircuit }}</p>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.CircuitDef" style="width: 250px">
                                    <b-input v-model="circuitDefinition"></b-input>
                                </b-field>
                                <b-field v-if="!this.isSubDistrBusbar" :label="this.$lang.CalcCircInfos.CircuitNumber" style="width: 250px; padding-right: 5rem">
                                    <b-input v-model="numberOfCircuits" type="number"></b-input>
                                </b-field>
                            </b-field>
                        </div>
                    </div>
                    <div class="content">
                        <footer class="modal-card-foot">
                            <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                      @click="isOutputCircuit_UpdateModalActive = false" />
                            <b-button :label="this.$lang.Menus.ModalUpdtBtn"
                                      type="is-primary"
                                      @click="update_OutputCircuits()" />
                        </footer>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="isCalcDevice_UpdateModalActive" :width="960">
            <div class="card">
                <div class="card-content">
                    <div class="media">
                        <div class="media-content">
                            <p class="title is-4">{{ this.$lang.CalcCircInfos.UpdateDevice }}</p>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.Type" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Type">
                                        <b-select v-if="typesList" v-model="type" @input="getCircuitDevice_Brands">
                                            <option v-for="option in typesList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.Brand" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Brand">
                                        <b-select v-if="brandsList" v-model="brand" @input="getCircuitDevice_Codes">
                                            <option v-for="option in brandsList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.Code" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Code">
                                        <b-select v-if="codesList" v-model="code" @input="getCircuitDevice_Ins">
                                            <option v-for="option in codesList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.In_A" :label-position="labelPosition" style="width: 155px">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.In_A"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.In_A"></span>
                                        </template>
                                        <b-select v-if="in_valList" v-model="in_A" @input="getCircuitDevice_Pole">
                                            <option v-for="option in in_valList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.PoleNumber" :label-position="labelPosition" :type="{ 'is-danger': hasError_poleNumber }" :message="emptyWarning_poleNumber" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PoleNumber" v-if="isPoleNumVisible">
                                        <b-select v-if="poleList" v-model="poleNumber" @input="getCircuitDevice_Sbt_Cekmeceli">
                                            <option v-for="option in poleList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PoleNumber" v-if="!isPoleNumVisible">
                                        <b-select v-if="poleList" v-model="poleNumber" @input="getCircuitDevice_Sbt_Cekmeceli" disabled>
                                            <option v-for="option in poleList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :label-position="labelPosition" :type="{ 'is-danger': hasError_sbt_cekmeceli }" :message="emptyWarning_sbt_cekmeceli" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" v-if="is_sbt_cekmeceliVisible">
                                        <b-select v-if="sbt_cekmeceliList" v-model="sbt_cekmeceli" @input="getCircuitDevice_Power">
                                            <option v-for="option in sbt_cekmeceliList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" v-if="!is_sbt_cekmeceliVisible">
                                        <b-select v-if="sbt_cekmeceliList" v-model="sbt_cekmeceli" @input="getCircuitDevice_Power" disabled>
                                            <option v-for="option in sbt_cekmeceliList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.Power" :label-position="labelPosition" :type="{ 'is-danger': hasError_power }" :message="emptyWarning_power" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Power" v-if="isPowerVisible">
                                        <b-select v-if="powerList" v-model="power" @input="getCircuitDevice_Pv">
                                            <option v-for="option in powerList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                    <b-tooltip :label="this.$lang.CalcCircInfos.Power" v-if="!isPowerVisible">
                                        <b-select v-if="powerList" v-model="power" @input="getCircuitDevice_Pv" disabled>
                                            <option v-for="option in powerList"
                                                    :value="option"
                                                    :key="option">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                        </div>
                    </div>
                    <div class="content">
                        <footer class="modal-card-foot">
                            <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                      @click="isCalcDevice_UpdateModalActive = false" />
                            <b-button v-if="notDetailed"
                                      :label="this.$lang.Menus.ModalUpdtBtn"
                                      type="is-primary"
                                      @click="update_OutputCircuitCalcDevices(device_definition)" />
                            <b-button v-if="isDetailed"
                                      :label="this.$lang.Menus.ModalUpdtBtn"
                                      type="is-primary"
                                      @click="update_OutputCircuitCalcDevices_2()" />
                        </footer>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="isConductor_UpdateModalActive" :width="960">
            <div class="card">
                <div class="card-content">
                    <div class="media">
                        <div class="media-content">
                            <p class="title is-4">{{ this.$lang.CalcCircInfos.UpdateDevice }}</p>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.ConnType" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConnType">
                                        <b-select v-if="connTypeList" v-model="connType" @input="getCablePositions">
                                            <option v-for="option in connTypeList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isCablePosVisible" :label="this.$lang.CalcCircInfos.CablePos" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.CablePos">
                                        <b-select v-if="cablePosList" v-model="cablePos" @input="getConductorDimensions">
                                            <option v-for="option in cablePosList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.PhaseNumber" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PhaseNumber">
                                        <b-select v-if="phaseNumber_conductor_List" v-model="phaseNumber_conductor">
                                            <option v-for="option in phaseNumber_conductor_List"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorDimension" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorDimension">
                                        <b-select v-if="condDimensionList" v-model="conductorDimension" @input="getConductor_Imax_and_Power">
                                            <option v-for="option in condDimensionList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorLength" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorLength">
                                        <b-numberinput v-model="conductorLength" :controls=false type="number" step="any" @input="setPositiveNumber()"></b-numberinput>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorTemp" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorTemp">
                                        <b-input v-model="conductorTemp" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label-position="labelPosition" style="width: 155px">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <b-input v-model="Tmax_inPanel" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 155px">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Imax"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <b-input v-model="Imax_conductor" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.LostPower" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.LostPower">
                                        <b-input v-model="lostPower_conductor" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                        </div>
                    </div>
                    <div class="content">
                        <footer class="modal-card-foot">
                            <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                      @click="isConductor_UpdateModalActive = false" />
                            <b-button v-if="notDetailed"
                                      :label="this.$lang.Menus.ModalUpdtBtn"
                                      type="is-primary"
                                      @click="update_OutputCircuitDeviceConductors(device_definition_2)" />
                            <b-button v-if="isDetailed"
                                      :label="this.$lang.Menus.ModalUpdtBtn"
                                      type="is-primary"
                                      @click="update_OutputCircuitDeviceConductors_2()" />
                        </footer>
                    </div>
                </div>
            </div>
        </b-modal>

        <b-modal v-model="isSubDistributionBusbars_UpdateModalActive" :width="960">
            <div class="card">
                <div class="card-content">
                    <div class="media">
                        <div class="media-content">
                            <p class="title is-4">{{ this.$lang.CalcCircInfos.UpdateDevice }}</p>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.ConnType" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConnType">
                                        <b-select v-if="connTypeList" v-model="connType_2" @input="getCablePositions_2">
                                            <option v-for="option in connTypeList"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field v-if="isCablePosVisible_2" :label="this.$lang.CalcCircInfos.CablePos" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.CablePos">
                                        <b-select v-if="cablePosList_2" v-model="cablePos_2" @input="getConductorDimensions_2">
                                            <option v-for="option in cablePosList_2"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.PhaseNumber" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.PhaseNumber">
                                        <b-select v-if="phaseNumber_conductor_List_2" v-model="phaseNumber_conductor_2">
                                            <option v-for="option in phaseNumber_conductor_List_2"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorDimension" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorDimension">
                                        <b-select v-if="condDimensionList_2" v-model="conductorDimension_2" @input="getConductor_Imax_and_Power_2">
                                            <option v-for="option in condDimensionList_2"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorLength" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorLength">
                                        <b-numberinput v-model="conductorLength_2" :controls=false type="number" step="any" @input="setPositiveNumber()"></b-numberinput>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label="this.$lang.CalcCircInfos.ConductorTemp" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.ConductorTemp">
                                        <b-input v-model="conductorTemp_2" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.Tmax_inPanel" :label-position="labelPosition" style="width: 155px">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Tmax_inPanel"></span>
                                        </template>
                                        <b-input v-model="Tmax_inPanel" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                                <b-field :label-position="labelPosition" style="width: 155px">
                                    <template #label>
                                        <span v-html="$lang.CalcCircInfos.Imax"></span>
                                    </template>
                                    <b-tooltip>
                                        <template v-slot:content>
                                            <span v-html="$lang.CalcCircInfos.Imax"></span>
                                        </template>
                                        <b-input v-model="Imax_conductor_2" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                            <b-field horizontal>
                                <b-field :label="this.$lang.CalcCircInfos.LostPower" :label-position="labelPosition" style="width: 155px">
                                    <b-tooltip :label="this.$lang.CalcCircInfos.LostPower">
                                        <b-input v-model="lostPower_conductor_2" type="number" step="any" disabled></b-input>
                                    </b-tooltip>
                                </b-field>
                            </b-field>
                        </div>
                    </div>
                    <div class="content">
                        <footer class="modal-card-foot">
                            <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                      @click="isSubDistributionBusbars_UpdateModalActive = false" />
                            <b-button v-if="notDetailed"
                                      :label="this.$lang.Menus.ModalUpdtBtn"
                                      type="is-primary"
                                      @click="update_OutputCircuit_SubDistributionBusbars(device_definition_3)" />
                            <b-button v-if="isDetailed"
                                      :label="this.$lang.Menus.ModalUpdtBtn"
                                      type="is-primary"
                                      @click="update_OutputCircuit_SubDistributionBusbars_2()" />
                        </footer>
                    </div>
                </div>
            </div>
        </b-modal>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';
    import OutputCircBtns from '@/components/OutputCircBtns.vue';
    import CalculationTabs from '@/components/CalculationTabs.vue';
    import SaveButton from '@/components/SaveButton.vue';

    export default {
        name: 'PanelInfos',
        components: {
            VerticalStepBar,
            OutputCircBtns,
            CalculationTabs,
            SaveButton
        },
        data() {
            return {
                isActive: true,
                projectId: 0,
                isInfoOpened: false,
                busbarDimensionList: [],
                selectedBusbarDimension: '',
                labelPosition: 'on-border',
                notDetailed: false,
                isDetailed: false,
                Tmax_inPanel: 0,
                allTotal_P_1: 0,
                allTotal_P_2: 0,

                circuitDefinition: '',
                savedCircDef: '',
                circuitIndex: 0,
                ratedCurrent: 0,
                ratedCurrentList: [],
                rdf: 0.8,
                rdfList: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
                Ing: 0,
                numberOfCircuits: 1,
                isOutputCircuit_UpdateModalActive: false,
                hasError_circuitDefinition: false,
                hasError_ratedCurrent: false,
                hasError_rdf: false,
                hasError_numberOfCircuits: false,
                hasError_numberOfCircuits2: false,
                canCalculate: false,

                outputCircuitCalcDeviceId: 0,
                circuitcalculationdevicesdata: [],
                circuitcalculationdevicesdata_detailed: [],
                total_P_OutputCircuitCalcDevices: 0,
                definitionIndex: 1,
                device_definition: '',
                type: '',
                typesList: [],
                brand: '',
                brandsList: [],
                code: '',
                codesList: [],
                in_A: '',
                in_valList: [],
                poleNumber: 0,
                poleList: [],
                isPoleNumVisible: false,
                sbt_cekmeceli: '',
                sbt_cekmeceliList: [],
                is_sbt_cekmeceliVisible: false,
                power: 0,
                powerList: [],
                isPowerVisible: false,
                Pv: 0,
                pvList: [],
                PvResult: 0,
                isPv_Visible: false,
                isCalcDevice_UpdateModalActive: false,
                hasError_type: false,
                hasError_brand: false,
                hasError_code: false,
                hasError_in_A: false,
                hasError_poleNumber: false,
                emptyWarning_poleNumber: '',
                hasError_sbt_cekmeceli: false,
                emptyWarning_sbt_cekmeceli: '',
                hasError_power: false,
                emptyWarning_power: '',
                hasError_Pv: false,
                canCalculate_2: false,
                isIn_AVisible: true,

                circuitDeviceConductorId: 0,
                circuitdeviceconductorsdata: [],
                circuitdeviceconductorsdata_detailed: [],
                total_P_OutputCircuitDeviceConductors: 0,
                device_definition_2: '',
                connType: '',
                connTypeList: [],
                cablePos: '',
                isCablePosVisible: false,
                cablePosList: [],
                phaseNumber_conductor: '',
                phaseNumber_conductor_List: [],
                conductorDimension: '',
                condDimensionList: [],
                conductorLength: 1,
                conductorTemp: 70,
                Imax_conductor: 0,
                lostPower_conductor: 0,
                isConductor_UpdateModalActive: false,
                hasError_connType: false,
                hasError_cablePos: false,
                hasError_phaseNumber_conductor: false,
                hasError_conductorDimension: false,
                hasError_conductorLength: false,
                canCalculate_3: false,
                isSubDistrBusbar: false,
                isDeviceConductor: false,

                subDistributionBusbarId: 0,
                subdistributionbusbarsdata: [],
                subdistributionbusbarsdata_detailed: [],
                total_P_OutputCircuit_SubDistributionBusbars: 0,
                subDistributionBusbarsId: 1,
                device_definition_3: '',
                connType_2: '',
                cablePos_2: '',
                isCablePosVisible_2: false,
                cablePosList_2: [],
                phaseNumber_conductor_2: '',
                phaseNumber_conductor_List_2: [],
                conductorDimension_2: '',
                condDimensionList_2: [],
                conductorLength_2: 1,
                conductorTemp_2: 70,
                Imax_conductor_2: 0,
                lostPower_conductor_2: 0,
                isSubDistributionBusbars_UpdateModalActive: false,
                hasError_connType_2: false,
                hasError_cablePos_2: false,
                hasError_phaseNumber_conductor_2: false,
                hasError_conductorDimension_2: false,
                hasError_conductorLength_2: false,
                canCalculate_4: false,
                isButtonDisabled: false
            }
        },
        mounted() {
            this.projectId = this.$store.state.projectId;
            this.Tmax_inPanel = this.$store.state.maxTemperature;

            this.getCircuitIndex();
            this.getRatedCurrentList();
            this.getOutputCircuitCalcDevicesTable_Detailed();
            this.getOutputCircuitDeviceConductorsTable_Detailed();
            this.getOutputCircuit_SubDistributionBusbarsTable_Detailed();
            this.getCircuitDevice_Types();
            this.setIng();
            this.getConnectionTypes();

            this.$store.state.activeStep = 3;
            this.$store.state.isFooterFixed = false;
        },
        watch: {
            $route() {

            }
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/calc_InputCircuit');
            },
            submit() {
                this.saveResult();

                this.$store.isPageOpenedFromStepBar = false;
                this.$store.canResultOpen = true;

                router.push('/calc_Manuel');
            },
            saveResult() {
                var totalPOutputCircuit = this.total_P_OutputCircuitCalcDevices + this.total_P_OutputCircuitDeviceConductors + this.total_P_OutputCircuit_SubDistributionBusbars;
                var totalP = this.$store.state.P_Busbar + this.$store.state.P_InputCircuit + this.$store.state.P_ManuelCircuit + totalPOutputCircuit;
                var payload = { OutputCircuitDef: this.savedCircDef, P_OutputCircuit: totalPOutputCircuit, totalLostPower_3: totalP };
                this.$store.commit('set_Datas_OutputCircuits', payload);
            },
            resetFieldList_1(index) {
                if (index == 1) {
                    this.brand = '';
                    this.code = '';
                    this.in_A = '';
                    this.poleNumber = '';
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 2) {
                    this.code = '';
                    this.in_A = '';
                    this.poleNumber = '';
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 3) {
                    this.poleNumber = '';
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 4) {
                    this.sbt_cekmeceli = '';
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 5) {
                    this.power = '';
                    this.Pv = 0;
                } else if (index == 6) {
                    this.Pv = 0;
                } else if (index == 7) {
                    this.Pv = 0;
                }
            },
            resetFieldList_2(index) {
                if (index == 1) {
                    this.cablePos = '';
                    this.phaseNumber_conductor = 0;
                    this.conductorDimension = '';
                } else if (index == 2) {
                    this.phaseNumber_conductor = 0;
                    this.conductorDimension = '';
                } else if (index == 3) {
                    this.conductorDimension = '';
                }
            },
            resetFieldList_3(index) {
                if (index == 1) {
                    this.cablePos_2 = '';
                    this.phaseNumber_conductor_2 = 0;
                    this.conductorDimension_2 = '';
                } else if (index == 2) {
                    this.phaseNumber_conductor_2 = 0;
                    this.conductorDimension_2 = '';
                } else if (index == 3) {
                    this.conductorDimension_2 = '';
                }
            },

            controlNumOfCircuits() {
                var tempNumberOfCircuits = this.numberOfCircuits + '';
                tempNumberOfCircuits = tempNumberOfCircuits.replace(/[^0-9]/g, '');
                this.numberOfCircuits = tempNumberOfCircuits;

                if (this.numberOfCircuits < 1) {
                    this.hasError_numberOfCircuits2 = true;
                } else {
                    this.hasError_numberOfCircuits2 = false;
                }
            },

            calculateThenAddAll() {
                if (this.numberOfCircuits < 1) {
                    this.$buefy.dialog.confirm({
                        title: this.$lang.OtherDialog.Title_1,
                        message: this.$lang.OtherDialog.Message_1,
                        confirmText: this.$lang.OtherDialog.Confirm,
                        cancelText: this.$lang.OtherDialog.Cancel,
                        type: 'is-warning',
                        hasIcon: true
                    })
                    return;
                }

                if (this.canCalculate && this.canCalculate_2 && this.canCalculate_3) {
                    var array1 = {
                        DeviceArray: this.circuitcalculationdevicesdata,
                    };
                    api.panels.addToOutputCircuitCalcDevices_2(array1).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.$buefy.toast.open(this.$lang.OtherDialog.Added);
                            this.getOutputCircuitCalcDevicesTable_Detailed();
                            if (this.circuitcalculationdevicesdata_detailed.length > 0) {
                                this.getAllTotalP();
                            }
                        }
                    });

                    var array2 = {
                        DeviceArray: this.circuitdeviceconductorsdata,
                    };
                    api.panels.addToOutputCircuitDeviceConductors_2(array2).then((res) => {
                        console.log("devices2-2");
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.$buefy.toast.open(this.$lang.OtherDialog.Added);
                            this.getOutputCircuitDeviceConductorsTable_Detailed();
                            if (this.circuitcalculationdevicesdata_detailed.length > 0) {
                                this.getAllTotalP();
                            }
                        }
                    });

                    var array3 = {
                        DeviceArray: this.subdistributionbusbarsdata,
                    };
                    api.panels.addToOutputCircuit_SubDistributionBusbars_2(array3).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            this.$buefy.toast.open(this.$lang.OtherDialog.Added);
                            this.getOutputCircuit_SubDistributionBusbarsTable_Detailed();
                            if (this.circuitcalculationdevicesdata_detailed.length > 0) {
                                this.getAllTotalP();
                            }
                        }
                    });

                    this.savedCircDef = this.circuitDefinition;
                    this.setForNewCircuit();
                } else if (!this.canCalculate || this.circuitcalculationdevicesdata.length == 0 || this.circuitdeviceconductorsdata.length == 0) {
                    this.$buefy.dialog.confirm({
                        title: this.$lang.OtherDialog.Title_2,
                        message: this.$lang.OtherDialog.Message_2,
                        confirmText: this.$lang.OtherDialog.Confirm,
                        cancelText: this.$lang.OtherDialog.Cancel,
                        type: 'is-warning',
                        hasIcon: true
                    })
                }
            },

            getCircuitIndex() {
                this.circuitIndex = this.$store.state.circIndex;
                this.$store.state.circIndex = this.$store.state.circIndex + 1;
            },
            getRatedCurrentList() {
                this.ratedCurrentList = [];
                api.panels.getRatedCurrentList().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.ratedCurrentList.push(e);
                        });
                    }
                });
            },
            setIng() {
                if (this.ratedCurrent == null) {
                    this.ratedCurrent = 0;
                }
                if (this.rdf == null) {
                    this.rdf = 0.8;
                }
                this.Ing = this.ratedCurrent * this.rdf;
            },
            get_P_Device_2(_Pv, _RDF) {
                var deviceLostPower = _Pv * _RDF * _RDF;
                return deviceLostPower;
            },
            getCircuitDevice_Types() {
                this.typesList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Types(langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.typesList.push(e);
                        });
                    }
                });
            },
            getCircuitDevice_Brands() {
                this.brandsList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Brands(this.type, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.brandsList.push(e);
                        });
                    }
                });
                this.resetFieldList_1(1);
            },
            getCircuitDevice_Codes() {
                this.codesList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Codes(this.brand, this.type, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.codesList.push(e);
                        });
                    }
                });
                this.resetFieldList_1(2);
            },
            getCircuitDevice_Ins() {
                this.in_valList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Ins(this.brand, this.type, this.code, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.in_valList.push(e);
                        });

                        if (this.in_valList.length > 0) {
                            this.isIn_AVisible = true;
                            if (this.in_valList.length == 1 && this.in_valList[0] == 'NA') {
                                this.isIn_AVisible = false;
                                this.in_A = 'NA';
                            }
                        } else {
                            this.isIn_AVisible = false;
                            this.in_A = 'NA';
                        }
                    }
                    if (this.in_A == 'NA') {
                        this.isButtonDisabled = true;
                        this.getCircuitDevice_Pole();
                    }
                });
                this.resetFieldList_1(3);
            },
            getCircuitDevice_Pole() {
                this.poleList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Pole(this.brand, this.type, this.code, this.in_A, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.poleList.push(e);
                        });
                        if (this.poleList.length > 0) {
                            this.isPoleNumVisible = true;
                            if (this.poleList.length == 1 && this.poleList[0] == 'NA') {
                                this.isPoleNumVisible = false;
                                this.poleNumber = 'NA';
                            }
                        } else {
                            this.isPoleNumVisible = false;
                            this.poleNumber = 'NA';
                        }
                        if (this.poleNumber == 'NA') {
                            this.isButtonDisabled = true;
                            this.getCircuitDevice_Sbt_Cekmeceli();
                        }
                    }
                });
                this.resetFieldList_1(4);
            },
            getCircuitDevice_Sbt_Cekmeceli() {
                this.sbt_cekmeceliList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Sbt_Cekmeceli(this.brand, this.type, this.code, this.in_A, this.poleNumber, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.sbt_cekmeceliList.push(e);
                        });
                        if (this.sbt_cekmeceliList.length > 0) {
                            this.is_sbt_cekmeceliVisible = true;
                            if (this.sbt_cekmeceliList.length == 1 && this.sbt_cekmeceliList[0] == 'NA') {
                                this.is_sbt_cekmeceliVisible = false;
                                this.sbt_cekmeceli = 'NA';
                            }
                        } else {
                            this.is_sbt_cekmeceliVisible = false;
                            this.sbt_cekmeceli = 'NA';
                        }

                        if (this.sbt_cekmeceli == 'NA') {
                            this.isButtonDisabled = true;
                            this.getCircuitDevice_Power();
                        }
                    }
                });
                this.resetFieldList_1(5);
            },
            getCircuitDevice_Power() {
                this.powerList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Power(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.powerList.push(e);
                        });
                        if (this.powerList.length > 0) {
                            this.isPowerVisible = true;
                            if (this.powerList.length == 1 && this.powerList[0] == 'NA') {
                                this.isPowerVisible = false;
                                this.power = 'NA';
                            }
                        } else {
                            this.isPowerVisible = false;
                            this.power = 'NA';
                        }
                        if (this.power == 'NA') {
                            this.isButtonDisabled = true;
                            this.getCircuitDevice_Pv();
                        }
                    }
                });
                this.resetFieldList_1(6);
            },
            getCircuitDevice_Pv() {
                this.pvList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Pv(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, this.power, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.pvList.push(e);
                        });
                        if (this.pvList.length > 0) {
                            this.isPv_Visible = true;
                            this.Pv = this.pvList[0];
                            this.PvResult = this.Pv * this.rdf;
                        } else {
                            this.isPv_Visible = false;
                            this.Pv = null;
                        }
                    }

                    if (this.Pv == null || this.Pv == 0) {
                        this.isButtonDisabled = true;
                    } else {
                        this.isButtonDisabled = false;
                    }
                });
            },
            getOutputCircuitCalcDevicesTable_Detailed() {
                this.circuitcalculationdevicesdata_detailed = [];
                api.panels.getOutputCircuitCalcDevices_Detailed(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.circuitcalculationdevicesdata_detailed.push(e);
                        });
                        this.getTotal_P_OutputCircuitCalcDevices();
                        if (this.circuitcalculationdevicesdata_detailed.length > 0) {
                            this.getAllTotalP();
                        }
                    }
                });
            },

            getAllTotalP() {
                this.allTotal_P_1 = 0;
                this.allTotal_P_2 = 0;
                api.panels.getAllTotal_P_OutputCircuit(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.allTotal_P_1 = res.data[0];
                        this.allTotal_P_2 = res.data[1];
                    }
                });
            },

            getTotal_P_OutputCircuitCalcDevices() {
                this.total_P_OutputCircuitCalcDevices = 0
                api.panels.getTotal_P_OutputCircuitCalculationDevices(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_OutputCircuitCalcDevices = res.data;
                    }
                });
            },
            getPvCalculated(pv, rdf) {
                var pvCalc = Math.pow(rdf, 2) * pv;
                return this.truncate(pvCalc, 1);
            },
            addToOutputCircuitCalcDevices() {
                this.controlInputs();
                this.controlInputs_2();
                this.getCircuitDevice_Pv();
                if (this.canCalculate && this.canCalculate_2) {
                    const device = {
                        ProjectId: this.projectId,
                        Definition: this.$lang.OtherDialog.Device + this.definitionIndex,
                        Type: this.type,
                        Brand: this.brand,
                        Code: this.code,
                        In_Value: this.in_A,
                        Pole: this.poleNumber,
                        Sbt_Cekmeceli: this.sbt_cekmeceli,
                        Power: this.power,
                        Pv: this.Pv,
                        RDF: this.rdf,
                        P_CalcResult: this.get_P_Device_2(this.Pv, this.rdf),
                        CircuitIndex: this.circuitIndex,
                        CircuitDefinition: this.circuitDefinition,
                        NumOfCircuits: this.numberOfCircuits
                    };

                    this.circuitcalculationdevicesdata.push(device);
                    this.definitionIndex++;
                }
            },
            deleteFromOutputCircuitCalcDevices(row) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        for (let i = 0; i < this.circuitcalculationdevicesdata.length; i++) {
                            if (this.circuitcalculationdevicesdata[i].Definition == row.Definition) {
                                this.circuitcalculationdevicesdata.splice(i, 1);
                            }
                        }
                        this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                    }
                })
            },
            deleteFromOutputCircuitCalcDevices_2(item) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>
                        api.panels.deleteFromOutputCircuitCalcDevices_2(item.Id).then((res) => {
                            if (res.isError) {
                                console.log("error " + res.errorDecription);
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                                this.getOutputCircuitCalcDevicesTable_Detailed();
                            }
                        })
                })
            },
            copyFromOutputCircuitCalcDevices(device) {
                var newDevice = {
                    ProjectId: device.ProjectId,
                    Definition: device.Definition + " " + this.$lang.CopyDialog.Extension,
                    Type: device.Type,
                    Brand: device.Brand,
                    Code: device.Code,
                    In_Value: device.In_Value,
                    Pole: device.Pole,
                    Sbt_Cekmeceli: device.Sbt_Cekmeceli,
                    Power: device.Power,
                    Pv: device.Pv,
                    RDF: device.RDF,
                    P_CalcResult: device.P_CalcResult,
                    CircuitIndex: device.CircuitIndex,
                    CircuitDefinition: device.CircuitDefinition,
                    NumOfCircuits: device.NumOfCircuits
                };
                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => {
                        this.circuitcalculationdevicesdata.push(newDevice);
                        this.$buefy.toast.open(this.$lang.CopyDialog.Copied);
                    },
                    onCancel: () => {

                    }
                });
            },
            copyFromOutputCircuitCalcDevices_2(item, row) {
                var device2 = {
                    ProjectId: item.ProjectId,
                    Definition: item.Definition + " " + this.$lang.CopyDialog.Extension,
                    Type: item.Type,
                    Brand: item.Brand,
                    Code: item.Code,
                    In_Value: item.In_Value,
                    Pole: item.Pole,
                    Sbt_Cekmeceli: item.Sbt_Cekmeceli,
                    Power: item.Power,
                    Pv: item.Pv,
                    RDF: row.RDF,
                    P_CalcResult: this.get_P_Device_2(item.Pv, row.RDF),
                    CircuitIndex: row.CircuitIndex,
                    CircuitDefinition: row.Definition,
                    NumOfCircuits: row.NumOfCircuits
                }

                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.addToOutputCircuitCalcDevices(device2).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.CopyDialog.Copied);

                                this.getOutputCircuitCalcDevicesTable_Detailed();
                            }
                        }),
                })
            },
            openUpdateModal_OutputCircuitCalcDevices(device) {
                this.notDetailed = true;
                this.isDetailed = false;

                this.outputCircuitCalcDeviceId = device.Id;
                this.isCalcDevice_UpdateModalActive = true;
                this.device_definition = device.Definition;

                this.type = device.Type;
                this.brandsList = [];
                var langCode = this.$lang.Format.Lang;

                api.panels.getCircuitDevice_Brands(this.type, langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.brandsList.push(e);
                        });

                        this.brand = device.Brand;
                        this.codesList = [];
                        api.panels.getCircuitDevice_Codes(this.brand, this.type, langCode).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                res.data.forEach((e) => {
                                    this.codesList.push(e);
                                });

                                this.code = device.Code;
                                this.in_valList = [];
                                api.panels.getCircuitDevice_Ins(this.brand, this.type, this.code, langCode).then((res) => {
                                    if (res.isError) {
                                        console.log("error ");
                                    } else {
                                        res.data.forEach((e) => {
                                            this.in_valList.push(e);
                                        });
                                        if (this.in_valList.length > 0) {
                                            this.isIn_AVisible = true;
                                            if (this.in_valList.length == 1 && this.in_valList[0] == 'NA') {
                                                this.isIn_AVisible = false;
                                            }
                                        } else {
                                            this.isIn_AVisible = false;
                                        }

                                        this.in_A = device.In_Value;
                                        this.poleList = [];
                                        api.panels.getCircuitDevice_Pole(this.brand, this.type, this.code, this.in_A, langCode).then((res) => {
                                            if (res.isError) {
                                                console.log("error ");
                                            } else {
                                                res.data.forEach((e) => {
                                                    this.poleList.push(e);
                                                });
                                                if (this.poleList.length > 0) {
                                                    this.isPoleNumVisible = true;
                                                    if (this.poleList.length == 1 && this.poleList[0] == 'NA') {
                                                        this.isPoleNumVisible = false;
                                                    }
                                                } else {
                                                    this.isPoleNumVisible = false;
                                                }

                                                this.poleNumber = device.Pole;
                                                this.sbt_cekmeceliList = [];
                                                api.panels.getCircuitDevice_Sbt_Cekmeceli(this.brand, this.type, this.code, this.in_A, this.poleNumber, langCode).then((res) => {
                                                    if (res.isError) {
                                                        console.log("error ");
                                                    } else {
                                                        res.data.forEach((e) => {
                                                            this.sbt_cekmeceliList.push(e);
                                                        });
                                                        if (this.sbt_cekmeceliList.length > 0) {
                                                            this.is_sbt_cekmeceliVisible = true;
                                                            if (this.sbt_cekmeceliList.length == 1 && this.sbt_cekmeceliList[0] == 'NA') {
                                                                this.is_sbt_cekmeceliVisible = false;
                                                            }
                                                        } else {
                                                            this.is_sbt_cekmeceliVisible = false;
                                                        }

                                                        this.sbt_cekmeceli = device.Sbt_Cekmeceli;
                                                        this.powerList = [];
                                                        api.panels.getCircuitDevice_Power(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, langCode).then((res) => {
                                                            if (res.isError) {
                                                                console.log("error ");
                                                            } else {
                                                                res.data.forEach((e) => {
                                                                    this.powerList.push(e);
                                                                });
                                                                if (this.powerList.length > 0) {
                                                                    this.isPowerVisible = false;
                                                                    if (this.powerList.length == 1 && this.powerList[0] == 'NA') {
                                                                        this.isPowerVisible = true;
                                                                    }
                                                                } else {
                                                                    this.isPowerVisible = true;
                                                                }

                                                                this.power = device.Power;
                                                                this.pvList = [];
                                                                api.panels.getCircuitDevice_Pv(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, this.power, langCode).then((res) => {
                                                                    if (res.isError) {
                                                                        console.log("error ");
                                                                    } else {
                                                                        res.data.forEach((e) => {
                                                                            this.pvList.push(e);
                                                                        });

                                                                        this.Pv = device.Pv;
                                                                        this.isLoading = false;
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });

                this.rdf = device.RDF;
                this.circuitIndex = device.CircuitIndex;
                this.circuitDefinition = device.CircuitDefinition;
                this.numberOfCircuits = device.NumOfCircuits;
            },
            openUpdateModal_OutputCircuitCalcDevices_2(item, row) {
                this.notDetailed = false;
                this.isDetailed = true;

                this.outputCircuitCalcDeviceId = item.Id;
                this.isCalcDevice_UpdateModalActive = true;
                this.device_definition = item.Definition;

                this.type = item.Type;
                this.brandsList = [];
                api.panels.getCircuitDevice_Brands(this.type).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.brandsList.push(e);
                        });

                        this.brand = item.Brand;
                        this.codesList = [];
                        var langCode = this.$lang.Format.Lang;

                        api.panels.getCircuitDevice_Codes(this.brand, this.type).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                res.data.forEach((e) => {
                                    this.codesList.push(e);
                                });

                                this.code = item.Code;
                                this.in_valList = [];
                                api.panels.getCircuitDevice_Ins(this.brand, this.type, this.code).then((res) => {
                                    if (res.isError) {
                                        console.log("error ");
                                    } else {
                                        res.data.forEach((e) => {
                                            this.in_valList.push(e);
                                        });

                                        this.in_A = item.In_Value;
                                        this.poleList = [];
                                        api.panels.getCircuitDevice_Pole(this.brand, this.type, this.code, this.in_A).then((res) => {
                                            if (res.isError) {
                                                console.log("error ");
                                            } else {
                                                res.data.forEach((e) => {
                                                    this.poleList.push(e);
                                                });
                                                if (this.poleList.length > 0) {
                                                    this.isPoleNumVisible = true;
                                                    if (this.poleList.length == 1 && this.poleList[0] == 'NA') {
                                                        this.isPoleNumVisible = false;
                                                    }
                                                } else {
                                                    this.isPoleNumVisible = false;
                                                }

                                                this.poleNumber = item.Pole;
                                                this.sbt_cekmeceliList = [];
                                                api.panels.getCircuitDevice_Sbt_Cekmeceli(this.brand, this.type, this.code, this.in_A, this.poleNumber, langCode).then((res) => {
                                                    if (res.isError) {
                                                        console.log("error ");
                                                    } else {
                                                        res.data.forEach((e) => {
                                                            this.sbt_cekmeceliList.push(e);
                                                        });
                                                        if (this.sbt_cekmeceliList.length > 0) {
                                                            this.is_sbt_cekmeceliVisible = true;
                                                            if (this.sbt_cekmeceliList.length == 1 && this.sbt_cekmeceliList[0] == 'NA') {
                                                                this.is_sbt_cekmeceliVisible = false;
                                                            }
                                                        } else {
                                                            this.is_sbt_cekmeceliVisible = false;
                                                        }

                                                        this.sbt_cekmeceli = item.Sbt_Cekmeceli;
                                                        this.powerList = [];
                                                        api.panels.getCircuitDevice_Power(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli).then((res) => {
                                                            if (res.isError) {
                                                                console.log("error ");
                                                            } else {
                                                                res.data.forEach((e) => {
                                                                    this.powerList.push(e);
                                                                });
                                                                if (this.powerList.length > 0) {
                                                                    this.isPowerVisible = false;
                                                                    if (this.powerList.length == 1 && this.powerList[0] == 'NA') {
                                                                        this.isPowerVisible = true;
                                                                    }
                                                                } else {
                                                                    this.isPowerVisible = true;
                                                                }

                                                                this.power = item.Power;
                                                                this.pvList = [];
                                                                api.panels.getCircuitDevice_Pv(this.brand, this.type, this.code, this.in_A, this.poleNumber, this.sbt_cekmeceli, this.power, langCode).then((res) => {
                                                                    if (res.isError) {
                                                                        console.log("error ");
                                                                    } else {
                                                                        res.data.forEach((e) => {
                                                                            this.pvList.push(e);
                                                                        });

                                                                        this.Pv = item.Pv;
                                                                        this.isLoading = false;
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });

                this.rdf = row.RDF;
                this.circuitIndex = row.CircuitIndex;
                this.circuitDefinition = row.Definition;
                this.numberOfCircuits = row.NumOfCircuits;
            },
            update_OutputCircuitCalcDevices(definition) {
                var updated = {
                    ProjectId: this.projectId,
                    Definition: definition,
                    Type: this.type,
                    Brand: this.brand,
                    Code: this.code,
                    In_Value: this.in_A,
                    Pole: this.poleNumber,
                    Sbt_Cekmeceli: this.sbt_cekmeceli,
                    Power: this.power,
                    Pv: this.Pv,
                    RDF: this.rdf,
                    P_CalcResult: this.get_P_Device_2(this.Pv, this.rdf),
                    CircuitIndex: this.circuitIndex,
                    CircuitDefinition: this.circuitDefinition,
                    NumOfCircuits: this.numberOfCircuits
                };

                for (let i = 0; i < this.circuitcalculationdevicesdata.length; i++) {
                    if (this.circuitcalculationdevicesdata[i].Definition == definition) {
                        this.circuitcalculationdevicesdata.splice(i, 1, updated);
                    }
                }
                this.$buefy.toast.open(this.$lang.OtherDialog.Updated);
                this.isCalcDevice_UpdateModalActive = false;
            },
            update_OutputCircuitCalcDevices_2() {
                const device = {
                    Id: this.outputCircuitCalcDeviceId,
                    ProjectId: this.projectId,
                    Definition: this.device_definition,
                    Type: this.type,
                    Brand: this.brand,
                    Code: this.code,
                    In_Value: this.in_A,
                    Pole: this.poleNumber,
                    Sbt_Cekmeceli: this.sbt_cekmeceli,
                    Power: this.power,
                    Pv: this.Pv,
                    RDF: this.rdf,
                    P_CalcResult: this.get_P_Device_2(this.Pv, this.rdf),
                    CircuitIndex: this.circuitIndex,
                    CircuitDefinition: this.circuitDefinition,
                    NumOfCircuits: this.numberOfCircuits
                };
                api.panels.updateOutputCircuitCalcDevices(device).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isCalcDevice_UpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);

                        this.getOutputCircuitCalcDevicesTable_Detailed();
                    }
                });
            },

            getConnectionTypes() {
                this.connTypeList = [];
                var langCode = this.$lang.Format.Lang;
                api.panels.getConnectionTypes(langCode).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.connTypeList.push(e.typeName);
                        });

                        this.connType_2 = this.connTypeList[0];
                        this.getPhaseNumbers_2();
                        this.getConductorDimensions_2();
                    }
                });
            },
            getCablePositions() {
                this.cablePosList = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                if (connTypeIndex == 2) {
                    this.isCablePosVisible = true;
                    var langCode = this.$lang.Format.Lang;
                    api.panels.getCablePositions(langCode).then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            res.data.forEach((e) => {
                                this.cablePosList.push(e.positionName);
                            });
                        }
                    });
                } else {
                    this.isCablePosVisible = false;
                    this.getConductorDimensions();
                }
                this.getPhaseNumbers();
                this.resetFieldList_2(1);
            },
            getPhaseNumbers() {
                this.phaseNumber_conductor_List = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                api.panels.getPhaseNumbers(connTypeIndex).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.phaseNumber_conductor_List.push(e);
                        });
                    }
                });
                this.resetFieldList_2(2);
            },
            getConductorDimensions() {
                this.condDimensionList = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                var cablePosId = this.cablePosList.indexOf(this.cablePos) + 1;
                api.panels.getConductorDimensions(connTypeIndex, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.condDimensionList.push(e.DimensionInfo);
                        });
                    }
                });
                this.resetFieldList_2(3);
            },
            getConductor_Imax_and_Power() {
                this.getConductor_I_Max();
                this.getConductor_LostPower();
            },
            getConductor_I_Max() {
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                var dimensionId = this.condDimensionList.indexOf(this.conductorDimension) + 1;
                var cablePosId = this.cablePosList.indexOf(this.cablePos) + 1;
                api.panels.getConductor_I_Max(connTypeIndex, dimensionId, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.Imax_conductor = res.data;
                    }
                });
            },
            getConductor_LostPower() {
                var connTypeIndex = this.connTypeList.indexOf(this.connType) + 1;
                var dimensionId = this.condDimensionList.indexOf(this.conductorDimension) + 1;
                var cablePosId = this.cablePosList.indexOf(this.cablePos) + 1;
                api.panels.GetConductor_LostPower(connTypeIndex, dimensionId, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.lostPower_conductor = res.data;
                    }
                });
            },
            getOutputCircuitDeviceConductorsTable_Detailed() {
                this.circuitdeviceconductorsdata_detailed = [];
                api.panels.getOutputCircuitDeviceConductors_Detailed(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.circuitdeviceconductorsdata_detailed.push(e);
                        });
                        this.getTotal_P_OutputCircuitDeviceConductors();
                    }
                });
            },
            get_P_Conductor_2(_Pv, Rdf, condLen, phaseNum) {
                var deviceLostPower = 0;
                var connIndex = this.connTypeList.indexOf(this.connType) + 1;
                if (connIndex == 1) {
                    deviceLostPower = _Pv * Rdf * Rdf * condLen * phaseNum;
                }
                if (connIndex == 2) {
                    deviceLostPower = _Pv * Rdf * Rdf * condLen * phaseNum;
                }
                return deviceLostPower;
            },
            getTotal_P_OutputCircuitDeviceConductors() {
                this.total_P_OutputCircuitDeviceConductors = 0
                api.panels.getTotal_P_OutputCircuitDeviceConductors(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_OutputCircuitDeviceConductors = res.data;
                    }
                });
            },
            addToOutputCircuitDeviceConductors() {
                this.controlInputs();
                this.controlInputs_3();
                if (this.canCalculate && this.canCalculate_3) {
                    const device = {
                        ProjectId: this.projectId,
                        Definition: this.circuitDefinition + ' ' + this.$lang.OtherDialog.Conductor,
                        ConnectionType: this.connType,
                        CablePosition: this.cablePos,
                        PhaseNumber: this.phaseNumber_conductor,
                        DimensionInfo: this.conductorDimension,
                        ConductorLength: this.conductorLength,
                        ConductorTemperature: this.conductorTemp,
                        MaxTemperature: this.Tmax_inPanel,
                        I_Max: this.Imax_conductor,
                        LostPower: this.lostPower_conductor,
                        RDF: this.rdf,
                        P_CalcResult: this.get_P_Conductor_2(this.lostPower_conductor, this.rdf, this.conductorLength, this.phaseNumber_conductor),
                        CircuitIndex: this.circuitIndex,
                        CircuitDefinition: this.circuitDefinition,
                        NumOfCircuits: this.numberOfCircuits
                    };
                    this.circuitdeviceconductorsdata.push(device);
                }
            },
            deleteFromOutputCircuitDeviceConductors(row) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        for (let i = 0; i < this.circuitdeviceconductorsdata.length; i++) {
                            if (this.circuitdeviceconductorsdata[i].Definition == row.Definition) {
                                this.circuitdeviceconductorsdata.splice(i, 1);
                            }
                        }
                        this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                    }
                })
            },
            deleteFromOutputCircuitDeviceConductors_2(item) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>
                        api.panels.deleteFromOutputCircuitDeviceConductors_2(item.Id).then((res) => {
                            if (res.isError) {
                                console.log("error " + res.errorDecription);
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);

                                this.getOutputCircuitDeviceConductorsTable_Detailed();
                            }
                        })
                })
            },
            copyFromOutputCircuitDeviceConductors(device) {
                var newDevice = {
                    ProjectId: this.projectId,
                    Definition: device.Definition + " " + this.$lang.CopyDialog.Extension,
                    ConnectionType: device.ConnectionType,
                    CablePosition: device.CablePosition,
                    PhaseNumber: device.PhaseNumber,
                    DimensionInfo: device.DimensionInfo,
                    ConductorLength: device.ConductorLength,
                    ConductorTemperature: device.ConductorTemperature,
                    MaxTemperature: device.MaxTemperature,
                    I_Max: device.I_Max,
                    LostPower: device.LostPower,
                    RDF: device.RDF,
                    P_CalcResult: device.P_CalcResult,
                    CircuitIndex: device.CircuitIndex,
                    CircuitDefinition: device.CircuitDefinition,
                    NumOfCircuits: device.NumOfCircuits
                };
                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => {
                        this.circuitdeviceconductorsdata.push(newDevice);
                        this.$buefy.toast.open(this.$lang.CopyDialog.Copied);
                    },
                    onCancel: () => {

                    }
                });
            },
            copyFromOutputCircuitDeviceConductors_2(item, row) {
                var device2 = {
                    ProjectId: this.projectId,
                    Definition: item.Definition + " " + this.$lang.CopyDialog.Extension,
                    ConnectionType: item.ConnectionType,
                    CablePosition: item.CablePosition,
                    PhaseNumber: item.PhaseNumber,
                    DimensionInfo: item.DimensionInfo,
                    ConductorLength: item.ConductorLength,
                    ConductorTemperature: item.ConductorTemperature,
                    MaxTemperature: item.MaxTemperature,
                    I_Max: item.I_Max,
                    LostPower: item.LostPower,
                    RDF: row.RDF,
                    P_CalcResult: this.get_P_Conductor_2(item.LostPower, row.RDF, item.ConductorLength, item.PhaseNumber),
                    CircuitIndex: row.CircuitIndex,
                    CircuitDefinition: row.Definition,
                    NumOfCircuits: row.NumOfCircuits
                }

                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.addToOutputCircuitDeviceConductors(device2).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.CopyDialog.Copied);

                                this.getOutputCircuitDeviceConductorsTable_Detailed();
                            }
                        }),
                })
            },
            openUpdateModal_OutputCircuitDeviceConductors(device) {
                this.notDetailed = true;
                this.isDetailed = false;

                this.circuitDeviceConductorId = device.Id;
                this.isConductor_UpdateModalActive = true;
                this.device_definition_2 = device.Definition;
                this.connType = device.ConnectionType;
                this.getCablePositions();
                this.cablePos = device.CablePosition;
                this.phaseNumber_conductor = device.PhaseNumber;
                this.getConductorDimensions();
                this.conductorDimension = device.DimensionInfo;
                this.conductorLength = device.ConductorLength;
                this.Imax_conductor = device.I_Max;
                this.lostPower_conductor = device.LostPower;
                this.rdf = device.RDF;
                this.circuitIndex = device.CircuitIndex;
                this.circuitDefinition = device.CircuitDefinition;
                this.numberOfCircuits = device.NumOfCircuits;
            },
            openUpdateModal_OutputCircuitDeviceConductors_2(item, row) {
                this.notDetailed = false;
                this.isDetailed = true;

                this.circuitDeviceConductorId = item.Id;
                this.isConductor_UpdateModalActive = true;
                this.device_definition_2 = item.Definition;
                this.connType = item.ConnectionType;
                this.getCablePositions();
                this.cablePos = item.CablePosition;
                this.phaseNumber_conductor = item.PhaseNumber;
                this.getConductorDimensions();
                this.conductorDimension = item.DimensionInfo;
                this.conductorLength = item.ConductorLength;
                this.Imax_conductor = item.I_Max;
                this.lostPower_conductor = item.LostPower;
                this.rdf = row.RDF;
                this.circuitIndex = row.CircuitIndex;
                this.circuitDefinition = row.Definition;
                this.numberOfCircuits = row.NumOfCircuits;
            },
            update_OutputCircuitDeviceConductors(definition) {
                var updated = {
                    ProjectId: this.projectId,
                    Definition: definition,
                    ConnectionType: this.connType,
                    CablePosition: this.cablePos,
                    PhaseNumber: this.phaseNumber_conductor,
                    DimensionInfo: this.conductorDimension,
                    ConductorLength: this.conductorLength,
                    ConductorTemperature: this.conductorTemp,
                    MaxTemperature: this.Tmax_inPanel,
                    I_Max: this.Imax_conductor,
                    LostPower: this.lostPower_conductor,
                    RDF: this.rdf,
                    P_CalcResult: this.get_P_Conductor_2(this.lostPower_conductor, this.rdf, this.conductorLength, this.phaseNumber_conductor),
                    CircuitIndex: this.circuitIndex,
                    CircuitDefinition: this.circuitDefinition,
                    NumOfCircuits: this.numberOfCircuits
                };

                for (let i = 0; i < this.circuitdeviceconductorsdata.length; i++) {
                    if (this.circuitdeviceconductorsdata[i].Definition == definition) {
                        this.circuitdeviceconductorsdata.splice(i, 1, updated);
                    }
                }
                this.$buefy.toast.open(this.$lang.OtherDialog.Updated);
                this.isConductor_UpdateModalActive = false;
            },
            update_OutputCircuitDeviceConductors_2() {
                const device = {
                    ProjectId: this.projectId,
                    Id: this.circuitDeviceConductorId,
                    Definition: this.device_definition_2,
                    ConnectionType: this.connType,
                    CablePosition: this.cablePos,
                    PhaseNumber: this.phaseNumber_conductor,
                    DimensionInfo: this.conductorDimension,
                    ConductorLength: this.conductorLength,
                    ConductorTemperature: this.conductorTemp,
                    MaxTemperature: this.Tmax_inPanel,
                    I_Max: this.Imax_conductor,
                    LostPower: this.lostPower_conductor,
                    RDF: this.rdf,
                    P_CalcResult: this.get_P_Conductor_2(this.lostPower_conductor, this.rdf, this.conductorLength, this.phaseNumber_conductor),
                    CircuitIndex: this.circuitIndex,
                    CircuitDefinition: this.circuitDefinition,
                    NumOfCircuits: this.numberOfCircuits
                };

                api.panels.updateOutputCircuitDeviceConductors(device).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isConductor_UpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);

                        this.getOutputCircuitDeviceConductorsTable_Detailed();
                    }
                });
            },

            getCablePositions_2() {
                this.cablePosList_2 = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType_2) + 1;
                if (connTypeIndex == 2) {
                    this.isCablePosVisible_2 = true;
                    api.panels.getCablePositions().then((res) => {
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            res.data.forEach((e) => {
                                this.cablePosList_2.push(e.positionName);
                            });
                        }
                    });
                } else {
                    this.isCablePosVisible_2 = false;
                    this.getConductorDimensions_2();
                }
                this.getPhaseNumbers_2();
                this.resetFieldList_3(1);
            },
            getPhaseNumbers_2() {
                this.phaseNumber_conductor_List_2 = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType_2) + 1;
                api.panels.getPhaseNumbers(connTypeIndex).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.phaseNumber_conductor_List_2.push(e);
                        });
                    }
                });
                this.resetFieldList_3(2);
            },
            getConductorDimensions_2() {
                this.condDimensionList_2 = [];
                var connTypeIndex = this.connTypeList.indexOf(this.connType_2) + 1;
                var cablePosId = this.cablePosList_2.indexOf(this.cablePos_2) + 1;
                api.panels.getConductorDimensions(connTypeIndex, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.condDimensionList_2.push(e.DimensionInfo);
                        });
                    }
                });
                this.resetFieldList_3(3);
            },
            getConductor_Imax_and_Power_2() {
                this.getConductor_I_Max_2();
                this.getConductor_LostPower_2();
            },
            getConductor_I_Max_2() {
                var connTypeIndex = this.connTypeList.indexOf(this.connType_2) + 1;
                var dimensionId = this.condDimensionList_2.indexOf(this.conductorDimension_2) + 1;
                var cablePosId = this.cablePosList_2.indexOf(this.cablePos_2) + 1;
                api.panels.getConductor_I_Max(connTypeIndex, dimensionId, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.Imax_conductor_2 = res.data;
                    }
                });
            },
            getConductor_LostPower_2() {
                var connTypeIndex = this.connTypeList.indexOf(this.connType_2) + 1;
                var dimensionId = this.condDimensionList_2.indexOf(this.conductorDimension_2) + 1;
                var cablePosId = this.cablePosList_2.indexOf(this.cablePos_2) + 1;
                api.panels.GetConductor_LostPower(connTypeIndex, dimensionId, cablePosId, this.Tmax_inPanel).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.lostPower_conductor_2 = res.data;
                    }
                });
            },
            getOutputCircuit_SubDistributionBusbarsTable_Detailed() {
                this.subdistributionbusbarsdata_detailed = [];
                api.panels.get_OutputCircuit_SubDistributionBusbars_Detailed(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.subdistributionbusbarsdata_detailed.push(e);
                        });
                        this.getTotal_P_OutputCircuit_SubDistributionBusbars();
                    }
                });
            },
            get_P_Conductor(_Pv, condLen, phaseNum) {
                var deviceLostPower = 0;
                var connIndex = this.connTypeList.indexOf(this.connType) + 1;
                if (connIndex == 1) {
                    deviceLostPower = _Pv * condLen * phaseNum;
                }
                if (connIndex == 2) {
                    deviceLostPower = _Pv * condLen * phaseNum;
                }
                return deviceLostPower;
            },
            getTotal_P_OutputCircuit_SubDistributionBusbars() {
                this.total_P_OutputCircuit_SubDistributionBusbars = 0;
                api.panels.getTotal_P_OutputCircuit_SubDistributionBusbars(this.projectId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.total_P_OutputCircuit_SubDistributionBusbars = res.data;
                        this.saveResult();
                    }
                });
            },
            addToOutputCircuit_SubDistributionBusbars() {
                this.controlInputs();
                this.controlInputs_4();
                if (this.canCalculate && this.canCalculate_4) {
                    const device = {
                        ProjectId: this.projectId,
                        Definition: this.circuitDefinition + ' ' + this.$lang.OtherDialog.Busbar,
                        ConnectionType: this.connType_2,
                        CablePosition: this.cablePos_2,
                        PhaseNumber: this.phaseNumber_conductor_2,
                        DimensionInfo: this.conductorDimension_2,
                        ConductorLength: this.conductorLength_2,
                        ConductorTemperature: this.conductorTemp,
                        MaxTemperature: this.Tmax_inPanel,
                        I_Max: this.Imax_conductor_2,
                        LostPower: this.lostPower_conductor_2,
                        RDF: this.rdf,
                        P_CalcResult: this.get_P_Conductor(this.lostPower_conductor_2, this.conductorLength_2, this.phaseNumber_conductor_2),
                        CircuitIndex: this.circuitIndex,
                        CircuitDefinition: this.circuitDefinition,
                        NumOfCircuits: this.numberOfCircuits
                    };
                    this.subdistributionbusbarsdata.push(device);
                }
            },
            deleteFromOutputCircuit_SubDistributionBusbars(row) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        for (let i = 0; i < this.subdistributionbusbarsdata.length; i++) {
                            if (this.subdistributionbusbarsdata[i].Definition == row.Definition) {
                                this.subdistributionbusbarsdata.splice(i, 1);
                            }
                        }
                        this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                    }
                })
            },
            deleteFromOutputCircuit_SubDistributionBusbars_2(item) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>
                        api.panels.deleteFromOutputCircuit_SubDistributionBusbars_2(item.Id).then((res) => {
                            if (res.isError) {
                                console.log("error " + res.errorDecription);
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);

                                this.getOutputCircuit_SubDistributionBusbarsTable_Detailed();
                            }
                        })
                })
            },
            copyFromOutputCircuit_SubDistributionBusbars(device) {
                var newDevice = {
                    ProjectId: this.projectId,
                    Definition: device.Definition + " " + this.$lang.CopyDialog.Extension,
                    ConnectionType: device.ConnectionType,
                    CablePosition: device.CablePosition,
                    PhaseNumber: device.PhaseNumber,
                    DimensionInfo: device.DimensionInfo,
                    ConductorLength: device.ConductorLength,
                    ConductorTemperature: device.ConductorTemperature,
                    MaxTemperature: device.MaxTemperature,
                    I_Max: device.I_Max,
                    LostPower: device.LostPower,
                    RDF: device.RDF,
                    P_CalcResult: device.P_CalcResult,
                    CircuitIndex: device.CircuitIndex,
                    CircuitDefinition: device.CircuitDefinition,
                    NumOfCircuits: device.NumOfCircuits
                };
                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () => {
                        this.subdistributionbusbarsdata.push(newDevice);
                        this.$buefy.toast.open(this.$lang.CopyDialog.Copied);
                    },
                    onCancel: () => {

                    }
                });
            },
            copyFromOutputCircuit_SubDistributionBusbars_2(item, row) {
                var device2 = {
                    ProjectId: this.projectId,
                    Definition: item.Definition + " " + this.$lang.CopyDialog.Extension,
                    ConnectionType: item.ConnectionType,
                    CablePosition: item.CablePosition,
                    PhaseNumber: item.PhaseNumber,
                    DimensionInfo: item.DimensionInfo,
                    ConductorLength: item.ConductorLength,
                    ConductorTemperature: item.ConductorTemperature,
                    MaxTemperature: item.MaxTemperature,
                    I_Max: item.I_Max,
                    LostPower: item.LostPower,
                    RDF: row.RDF,
                    P_CalcResult: this.get_P_Conductor(item.LostPower, item.ConductorLength, item.PhaseNumber),
                    CircuitIndex: row.CircuitIndex,
                    CircuitDefinition: row.Definition,
                    NumOfCircuits: row.NumOfCircuits
                }

                this.$buefy.dialog.confirm({
                    title: this.$lang.CopyDialog.Title,
                    message: this.$lang.CopyDialog.Message,
                    confirmText: this.$lang.CopyDialog.Confirm,
                    cancelText: this.$lang.CopyDialog.Cancel,
                    type: 'is-warning',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.addToOutputCircuit_SubDistributionBusbars(device2).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.CopyDialog.Copied);

                                this.getOutputCircuit_SubDistributionBusbarsTable_Detailed();
                            }
                        }),
                })
            },
            openUpdateModal_OutputCircuit_SubDistributionBusbars(device) {
                this.subDistributionBusbarsId = device.Id;
                this.isSubDistributionBusbars_UpdateModalActive = true;
                this.device_definition_3 = device.Definition;
                this.connType_2 = device.ConnectionType;
                this.getCablePositions_2();
                this.cablePos_2 = device.CablePosition;
                this.phaseNumber_conductor_2 = device.PhaseNumber;
                this.getConductorDimensions_2();
                this.conductorDimension_2 = device.DimensionInfo;
                this.conductorLength_2 = device.ConductorLength;
                this.Imax_conductor_2 = device.I_Max;
                this.lostPower_conductor_2 = device.LostPower;
                this.rdf = device.RDF;
                this.circuitIndex = device.CircuitIndex;
                this.circuitDefinition = device.CircuitDefinition;
                this.numberOfCircuits = device.NumOfCircuits;
            },
            openUpdateModal_OutputCircuit_SubDistributionBusbars_2(item, row) {
                this.subDistributionBusbarsId = item.Id;
                this.isSubDistributionBusbars_UpdateModalActive = true;
                this.device_definition_3 = item.Definition;
                this.connType_2 = item.ConnectionType;
                this.getCablePositions_2();
                this.cablePos_2 = item.CablePosition;
                this.phaseNumber_conductor_2 = item.PhaseNumber;
                this.getConductorDimensions_2();
                this.conductorDimension_2 = item.DimensionInfo;
                this.conductorLength_2 = item.ConductorLength;
                this.Imax_conductor_2 = item.I_Max;
                this.lostPower_conductor_2 = item.LostPower;
                this.rdf = row.RDF;
                this.circuitIndex = row.CircuitIndex;
                this.circuitDefinition = row.Definition;
                this.numberOfCircuits = row.NumOfCircuits;
            },
            update_OutputCircuit_SubDistributionBusbars(definition) {
                const updated = {
                    ProjectId: this.projectId,
                    Definition: definition,
                    ConnectionType: this.connType_2,
                    CablePosition: this.cablePos_2,
                    PhaseNumber: this.phaseNumber_conductor_2,
                    DimensionInfo: this.conductorDimension_2,
                    ConductorLength: this.conductorLength_2,
                    ConductorTemperature: this.conductorTemp,
                    MaxTemperature: this.Tmax_inPanel,
                    I_Max: this.Imax_conductor_2,
                    LostPower: this.lostPower_conductor_2,
                    RDF: this.rdf,
                    P_CalcResult: this.get_P_Conductor(this.lostPower_conductor_2, this.conductorLength_2, this.phaseNumber_conductor_2),
                    CircuitIndex: this.circuitIndex,
                    CircuitDefinition: this.circuitDefinition,
                    NumOfCircuits: this.numberOfCircuits
                };

                for (let i = 0; i < this.subdistributionbusbarsdata.length; i++) {
                    if (this.subdistributionbusbarsdata[i].Definition == definition) {
                        this.subdistributionbusbarsdata.splice(i, 1, updated);
                    }
                }
                this.$buefy.toast.open(this.$lang.OtherDialog.Updated);
                this.isSubDistributionBusbars_UpdateModalActive = false;
            },
            update_OutputCircuit_SubDistributionBusbars_2() {
                const device = {
                    Id: this.subDistributionBusbarsId,
                    ProjectId: this.projectId,
                    Definition: this.device_definition_3,
                    ConnectionType: this.connType_2,
                    CablePosition: this.cablePos_2,
                    PhaseNumber: this.phaseNumber_conductor_2,
                    DimensionInfo: this.conductorDimension_2,
                    ConductorLength: this.conductorLength_2,
                    ConductorTemperature: this.conductorTemp,
                    MaxTemperature: this.Tmax_inPanel,
                    I_Max: this.Imax_conductor_2,
                    LostPower: this.lostPower_conductor_2,
                    RDF: this.rdf,
                    P_CalcResult: this.get_P_Conductor(this.lostPower_conductor_2, this.conductorLength_2, this.phaseNumber_conductor_2),
                    CircuitIndex: this.circuitIndex,
                    CircuitDefinition: this.circuitDefinition,
                    NumOfCircuits: this.numberOfCircuits
                };
                api.panels.updateOutputCircuit_SubDistributionBusbars(device).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isSubDistributionBusbars_UpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);

                        this.getOutputCircuit_SubDistributionBusbarsTable_Detailed();
                    }
                });
            },

            deleteFrom_OutputCircuits(row) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    cancelText: this.$lang.DeleteDialog.Cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>
                        api.panels.deleteFrom_OutputCircuits(row.CircuitIndex, this.projectId).then((res) => {
                            if (res.isError) {
                                console.log("error " + res.errorDecription);
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);

                                this.getOutputCircuitCalcDevicesTable_Detailed();
                                this.getOutputCircuitDeviceConductorsTable_Detailed();
                                this.getOutputCircuit_SubDistributionBusbarsTable_Detailed();
                            }
                        })
                })
            },
            openUpdateModal_OutputCircuits(row, modalType) {
                this.isOutputCircuit_UpdateModalActive = true;
                this.circuitIndex = row.CircuitIndex;
                this.circuitDefinition = row.Definition;
                this.numberOfCircuits = row.NumOfCircuits;

                if (modalType == 1) {
                    this.isSubDistrBusbar = false;
                    this.isDeviceConductor = false;
                } else if (modalType == 2) {
                    this.isDeviceConductor = true;
                    this.isSubDistrBusbar = false;
                } else if (modalType == 3) {
                    this.isSubDistrBusbar = true;
                    this.isDeviceConductor = false;
                }
            },
            update_OutputCircuits() {
                var data = {
                    ProjectId: this.projectId,
                    CircuitIndex: this.circuitIndex,
                    CircuitDefinition: this.circuitDefinition,
                    NumOfCircuits: this.numberOfCircuits,
                };

                api.panels.update_OutputCircuits(data).then((res) => {
                    if (res.isError) {
                        console.log("error " + res.errorDecription);
                    } else {
                        this.isOutputCircuit_UpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);

                        this.getOutputCircuitCalcDevicesTable_Detailed();
                        this.getOutputCircuitDeviceConductorsTable_Detailed();
                        this.getOutputCircuit_SubDistributionBusbarsTable_Detailed();
                        if (this.circuitcalculationdevicesdata_detailed.length > 0) {
                            this.getAllTotalP();
                        }
                    }
                })
            },

            setForNewCircuit() {
                this.Tmax_inPanel = this.$store.state.maxTemperature;

                this.getCircuitIndex();
                this.getRatedCurrentList();
                this.getCircuitDevice_Brands();
                this.setIng();
                this.getConnectionTypes();

                this.rdf = 0.8;
                this.circuitDefinition = '';
                this.numberOfCircuits = 1;
                this.ratedCurrent = 0;
                this.isOutputCircuit_UpdateModalActive = false;

                this.circuitcalculationdevicesdata = [];
                this.total_P_OutputCircuitCalcDevices = 0;
                this.definitionIndex = 1;
                this.outputCircuitCalcDeviceId = 0;
                this.device_definition = '';
                this.brand = '';
                this.type = '';
                this.code = '';
                this.in_A = '';
                this.poleNumber = 0;
                this.sbt_cekmeceli = '';
                this.power = '';
                this.Pv = 0;
                this.isPoleNumVisible = false;
                this.is_sbt_cekmeceliVisible = false;
                this.isPowerVisible = false;
                this.isPv_Visible = false;
                this.isCalcDevice_UpdateModalActive = false;

                this.circuitDeviceConductorId = 0;
                this.circuitdeviceconductorsdata = [];
                this.total_P_OutputCircuitDeviceConductors = 0;
                this.device_definition_2 = '';
                this.connType = '';
                this.cablePos = '';
                this.phaseNumber_conductor = '';
                this.conductorDimension = '';
                this.conductorLength = 0;
                this.conductorTemp = 70;
                this.Imax_conductor = 0;
                this.lostPower_conductor = 0;
                this.isCablePosVisible = false;
                this.isConductor_UpdateModalActive = false;

                this.subdistributionbusbarsdata = [];
                this.subDistributionBusbarId = 0;
                this.total_P_OutputCircuit_SubDistributionBusbars = 0;
                this.subDistributionBusbarsId = 1;
                this.device_definition_3 = '';
                this.connType_2 = '';
                this.cablePos_2 = '';
                this.connType_2 = '';
                this.phaseNumber_conductor_2 = '';
                this.conductorDimension_2 = '';
                this.conductorLength_2 = 0;
                this.conductorTemp_2 = 70;
                this.Imax_conductor_2 = 0;
                this.lostPower_conductor_2 = 0;
                this.isCablePosVisible_2 = false;
                this.isSubDistributionBusbars_UpdateModalActive = false;
            },

            setPositiveNumber() {
                if (this.conductorLength < 1) {
                    this.conductorLength = 1;
                }

                if (this.conductorLength_2 < 1) {
                    this.conductorLength_2 = 1;
                }
            },
            truncate(numToBeTruncated, numOfDecimals) {
                var theNumber = numToBeTruncated.toString();
                if (theNumber.includes('.')) {
                    var pointIndex = theNumber.indexOf('.');
                    var _result = +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
                    var _resultString = _result.toString();
                    if (!_resultString.includes('.')) {
                        return _resultString + '.0';
                    } else {
                        return _result;
                    }
                } else {
                    return theNumber + '.0';
                }
            },
            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },
            controlInputs() {
                if (this.circuitDefinition == '') {
                    this.hasError_circuitDefinition = true;
                } else if (this.ratedCurrent == 0) {
                    this.hasError_ratedCurrent = true;
                } else if (this.rdf == 0) {
                    this.hasError_rdf = true;
                } else if (this.numberOfCircuits == 0) {
                    this.hasError_numberOfCircuits = true;
                } else {
                    this.canCalculate = true;
                }

                if (this.circuitDefinition != '') {
                    this.hasError_circuitDefinition = false;
                }
                if (this.ratedCurrent != 0) {
                    this.hasError_ratedCurrent = false;
                }
                if (this.rdf != '') {
                    this.hasError_rdf = false;
                }
                if (this.numberOfCircuits != 0) {
                    this.hasError_numberOfCircuits = false;
                }
            },
            controlInputs_2() {
                if (this.brand == '') {
                    this.hasError_brand = true;
                } else if (this.type == '') {
                    this.hasError_type = true;
                } else if (this.code == '') {
                    this.hasError_code = true;
                } else if (this.in_A == '') {
                    this.hasError_in_A = true;
                } else if (this.poleNumber == '') {
                    if (this.isPoleNumVisible) {
                        this.hasError_poleNumber = true;
                        this.emptyWarning_poleNumber = this.$lang.Menus.EmptyWarning;
                    }
                } else if (this.sbt_cekmeceli == '') {
                    if (this.is_sbt_cekmeceliVisible) {
                        this.hasError_sbt_cekmeceli = true;
                        this.emptyWarning_sbt_cekmeceli = this.$lang.Menus.EmptyWarning;
                    }
                } else if (this.power == '') {
                    if (this.isPowerVisible) {
                        this.hasError_power = true;
                        this.emptyWarning_power = this.$lang.Menus.EmptyWarning;
                    }
                } else {
                    this.canCalculate_2 = true;
                }

                if (this.type != '') {
                    this.hasError_type = false;
                }
                if (this.brand != '') {
                    this.hasError_brand = false;
                }
                if (this.code != '') {
                    this.hasError_code = false;
                }
                if (this.in_A != '') {
                    this.hasError_in_A = false;
                }
                if (this.isPoleNumVisible && this.poleNumber != '') {
                    this.hasError_poleNumber = false;
                    this.emptyWarning_poleNumber = '';
                }
                if (this.is_sbt_cekmeceliVisible && this.sbt_cekmeceli != '') {
                    this.hasError_sbt_cekmeceli = false;
                    this.emptyWarning_sbt_cekmeceli = '';
                }
                if (this.isPowerVisible && this.power != '') {
                    this.hasError_power = false;
                    this.emptyWarning_power = '';
                }
            },
            controlInputs_3() {
                if (this.connType == '') {
                    this.hasError_connType = true;
                } else if (this.phaseNumber_conductor == '') {
                    this.hasError_phaseNumber_conductor = true;
                } else if (this.conductorDimension == '') {
                    this.hasError_conductorDimension = true;
                } else if (this.conductorLength == '') {
                    this.hasError_conductorLength = true;
                } else {
                    this.canCalculate_3 = true;
                }

                if (this.isCablePosVisible) {
                    if (this.cablePos == '') {
                        this.hasError_cablePos = true;
                    }
                }

                if (this.connType != '') {
                    this.hasError_connType = false;
                }
                if (this.cablePos != '') {
                    this.hasError_cablePos = false;
                }
                if (this.phaseNumber_conductor != '') {
                    this.hasError_phaseNumber_conductor = false;
                }
                if (this.conductorDimension != '') {
                    this.hasError_conductorDimension = false;
                }
                if (this.conductorLength != '') {
                    this.hasError_conductorLength = false;
                }
            },
            controlInputs_4() {
                if (this.connType_2 == '') {
                    this.hasError_connType_2 = true;
                } else if (this.phaseNumber_conductor_2 == '') {
                    this.hasError_phaseNumber_conductor_2 = true;
                } else if (this.conductorDimension_2 == '') {
                    this.hasError_conductorDimension_2 = true;
                } else if (this.conductorLength_2 == '') {
                    this.hasError_conductorLength_2 = true;
                } else {
                    this.canCalculate_4 = true;
                }

                if (this.isCablePosVisible_2) {
                    if (this.cablePos_2 == '') {
                        this.hasError_cablePos_2 = true;
                    }
                }

                if (this.connType_2 != '') {
                    this.hasError_connType_2 = false;
                }
                if (this.cablePos_2 != '') {
                    this.hasError_cablePos_2 = false;
                }
                if (this.phaseNumber_conductor_2 != '') {
                    this.hasError_phaseNumber_conductor_2 = false;
                }
                if (this.conductorDimension_2 != '') {
                    this.hasError_conductorDimension_2 = false;
                }
                if (this.conductorLength_2 != '') {
                    this.hasError_conductorLength_2 = false;
                }
            },
            openCircuitDevices() {
                this.$store.state.previousRoute = this.$route.name;
                this.$store.state.isCircDevicesFromCalculation = true;
                if (this.$store.state.memberId != 'TestMember' && this.$store.state.isAdmin == 1) {
                    router.push('/circuitDevices_Admin');
                } else {
                    router.push('/circuitDevices');
                }
            },
            openInfo() {
                if (!this.isInfoOpened) {
                    this.isInfoOpened = true;
                } else {
                    this.isInfoOpened = false;
                }
            }
        },

    };
</script>
<style>
    .test > .table-wrapper .table td {
        padding: 4px;
    }

    .material-symbols-outlined {
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;
    }

    .customDetailField {
        color: #3F48CC;
        cursor: pointer;
        text-decoration: none;
    }
</style>