<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{ this.$lang.CircuitDevices.Title }} &nbsp; <span style="font-size:1vw">{{ this.$lang.CircuitDevices.Title_2 }}</span>

            <p v-if="this.backBtnActive == true || this.$store.state.isCircDevicesFromCalculation" class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
        </hero-bar>
        <section>
            <div class="columns" style="padding-right: 2rem">
                <div class="column is-1" style="padding-left: 2rem; background-color: #FFFFFF; width: 10%; height: 100%; min-height: 100vh; display: flex; flex-direction: column; ">
                    <b-field :v-if="this.$store.state.isCircDevicesFromCalculation">
                        <verticalStep-bar />
                    </b-field>
                </div>
                <div class="column">
                    <form @submit.prevent="submit">
                        <div class="is-flex is-justify-content-center">
                            <b-upload v-model="dropFiles"
                                      multiple
                                      drag-drop
                                      accept=".xlsx, .xls"
                                      :validationMessage="this.$lang.CircuitDevices.Validation"
                                      :loading="isSendLoading">
                                <section class="section">
                                    <div class="content has-text-centered">
                                        <p>
                                            <b-icon icon="upload" size="is-large"> </b-icon>
                                        </p>
                                        <p>
                                            {{ this.$lang.CircuitDevices.Warning_1 }} <br />
                                            {{ this.$lang.CircuitDevices.Warning_2 }}
                                        </p>
                                    </div>
                                </section>
                            </b-upload>
                        </div>
                        <div class="tags">
                            <div v-for="(file, index) in dropFiles" :key="index">
                                <span class="tag is-info">
                                    {{ file.name }} - {{ calcSize(file) }} - {{ $lang.CircuitDevices.Delete }}
                                    <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
                                </span>
                            </div>
                        </div>
                        <b-progress v-if="loadPercentage"
                                    type="is-primary"
                                    size="is-small"
                                    :max="100"
                                    :value="loadPercentage"
                                    :show-value="true"
                                    format="percent"></b-progress>
                        <b-field horizontal>

                        </b-field>
                        <b-button @click="downloadExcel" icon-right="download" :is-loading="isLoading">
                            {{ this.$lang.CircuitDevices.DownloadBtn }}
                        </b-button>
                        <b-button @click="uploadExcel" type="is-primary" class="is-pulled-right" icon-right="content-save" :loading="isSendLoading">
                            {{ this.$lang.CircuitDevices.UploadBtnAdmin }}
                        </b-button>
                    </form>

                    <div v-if="checkedRows.length > 0" style="padding-top: 2rem">
                        <b-field grouped>
                            <b-field>
                                <b>{{ this.$lang.CircuitDevices.TotalChecked }}</b>: {{ checkedRows.length }}
                            </b-field>
                            <b-field>
                                <button class="button is-small is-danger" @click="deleteSelectedRows">
                                    <b-icon icon="delete" size="is-small"></b-icon>
                                </button>
                            </b-field>
                        </b-field>
                    </div>
                    <b-table :data="devicesdata"
                             :checked-rows.sync="checkedRows"
                             :checkbox-position="checkboxPosition"
                             :checkbox-type="checkboxType"
                             checkable
                             :paginated="isPaginated"
                             :per-page="perPage"
                             v-if="this.$store.state.memberId != 'TestMember'">

                        <b-table-column field="Id" label="Id" :td-attrs="columnTdAttrs" v-slot="props" width="100">
                            {{ props.row.Id }}
                        </b-table-column>
                        <b-table-column field="Type" :label="this.$lang.CalcCircInfos.Type" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" width="150" centered>
                            {{ props.row.Type }}
                        </b-table-column>
                        <b-table-column field="Brand" :label="this.$lang.CalcCircInfos.Brand" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" width="150" centered>
                            {{ props.row.Brand }}
                        </b-table-column>
                        <b-table-column field="Code" :label="this.$lang.CalcCircInfos.Code" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" width="200" centered>
                            {{ props.row.Code }}
                        </b-table-column>
                        <b-table-column field="In_Value" :td-attrs="columnTdAttrs" :searchable="true" width="100" centered>
                            <template v-slot:header="">
                                <span v-html="$lang.CalcCircInfos.In_A"></span>
                            </template>
                            <template v-slot="props">
                                {{ props.row.In_Value }}
                            </template>
                        </b-table-column>
                        <b-table-column field="Pole" :label="this.$lang.CalcCircInfos.PoleNumber" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" centered>
                            {{ props.row.Pole }}
                        </b-table-column>
                        <b-table-column field="Sbt_Cekmeceli" :label="this.$lang.CalcCircInfos.Sbt_cekmeceli" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" centered>
                            {{ props.row.Sbt_Cekmeceli }}
                        </b-table-column>
                        <b-table-column field="Power" :label="this.$lang.CalcCircInfos.Power" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" centered>
                            {{ props.row.Power }}
                        </b-table-column>
                        <b-table-column field="Pv" :label="this.$lang.CircuitDevices.Pv" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" centered>
                            {{ props.row.Pv }}
                        </b-table-column>
                        <b-table-column field="CreateDate" :label="this.$lang.CalcCircInfos.CreateDate" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" centered>
                            {{ props.row.CreateDate }}
                        </b-table-column>
                        <b-table-column field="UpdateDate" :label="this.$lang.CalcCircInfos.UpdateDate" :td-attrs="columnTdAttrs" v-slot="props" :searchable="true" centered>
                            {{ props.row.UpdateDate }}
                        </b-table-column>

                        <b-table-column field="Action" v-slot="props">
                            <button class="button is-small is-light" @click="openUpdateModal(props.row)">
                                <b-icon icon="pen" size="is-small"></b-icon>
                            </button>
                            <button class="button is-small is-danger" @click="deleteRow(props.row)">
                                <b-icon icon="delete" size="is-small"></b-icon>
                            </button>
                        </b-table-column>
                    </b-table>
                </div>
            </div>

            <b-modal v-model="isUpdateModalActive" :width="960">
                <div class="card">

                    <div class="card-content">
                        <div class="media">
                            <div class="media-content">
                                <p class="title is-4">{{ this.$lang.CircuitDevices.UpdateDevice }}</p>

                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.Type">
                                        <b-input v-model="deviceType" style="width: 65%;"></b-input>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.Brand">
                                        <b-input v-model="deviceBrand" style="width: 65%;"></b-input>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.Code">
                                        <b-input v-model="deviceCode" style="width: 65%;"></b-input>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.In_A">
                                        <b-input v-model="deviceIn" style="width: 65%;"></b-input>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.PoleNumber">
                                        <b-input v-model="devicePole" style="width: 65%;"></b-input>
                                    </b-field>
                                    <b-field :label="this.$lang.CalcCircInfos.Sbt_cekmeceli">
                                        <b-input v-model="sbt_cekmeceli" style="width: 65%;"></b-input>
                                    </b-field>
                                </b-field>
                                <b-field horizontal>
                                    <b-field :label="this.$lang.CalcCircInfos.Power">
                                        <b-input v-model="devicePower" type="number" style="width: 65%;"></b-input>
                                    </b-field>
                                    <b-field :label="this.$lang.CircuitDevices.Pv">
                                        <b-input v-model="devicePv" type="number" style="width: 32%;"></b-input>
                                    </b-field>
                                </b-field>
                            </div>
                        </div>

                        <div class="content">
                            <footer class="modal-card-foot">
                                <b-button :label="this.$lang.Menus.ModalCloseBtn"
                                          @click="isUpdateModalActive = false" />
                                <b-button :label="this.$lang.Menus.ModalUpdtBtn"
                                          type="is-primary"
                                          @click="updateRow()" />
                            </footer>
                        </div>
                    </div>

                </div>
            </b-modal>

        </section>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';

    export default {
        components: {
            VerticalStepBar
        },
        data() {
            return {
                name: 'CircuitDevices',
                isLoading: false,
                isPaginated: true,
                perPage: 10,
                currentPage: 1,
                checkboxPosition: 'left',
                checkboxType: 'is-primary',
                checkedRows: [],
                devicesdata: [],
                dropFiles: [],
                formData: [],
                isSendLoading: false,
                loadPercentage: 0,
                isUpdateModalActive: false,
                deviceId: 0,
                deviceType: '',
                deviceBrand: '',
                deviceCode: '',
                deviceIn: '',
                devicePole: '',
                sbt_cekmeceli: '',
                devicePower: 0,
                devicePv: 0,
                backBtnActive: false
            };
        },
        computed: {
            newDataMsg() {
                return this.$lang.CircuitDevices.ExcelLoaded;
            }
        },
        watch: {
            dropFiles(newVal, oldVal) {
                if (newVal.at(-1)?.size > 3145728) {
                    this.$buefy.toast.open({
                        duration: 2500,
                        message: this.$lang.CircuitDevices.MbWarning_1 + " <b>3MB</b>" + this.$lang.CircuitDevices.MbWarning_2,
                        type: 'is-warning'
                    });
                    this.dropFiles.pop();
                }

                this.dropFiles.forEach((e, i) => {
                    if (!('previewUrl' in e)) {
                        e.previewUrl = URL.createObjectURL(e);
                    }
                });

                this.formData = [];
                this.dropFiles.forEach((e, i) => {
                    const objectModel = {
                        Id: i,
                        ItemId: this.$route.params.id,
                        Base64String: ''
                    };

                    const reader = new FileReader();
                    reader.readAsDataURL(e);
                    reader.onloadend = function () {
                        const base64data = reader.result.split(',')[1];
                        objectModel.Base64String = base64data;
                    };

                    this.formData.push(objectModel);
                });
            }
        },
        mounted() {
            if (this.$store.state.previousRoute == 'projectInfos' || this.$store.state.previousRoute == 'panelInfos' || this.$store.state.previousRoute == 'envConditions' ||
                this.$store.state.previousRoute == 'calc_Busbar' || this.$store.state.previousRoute == 'calc_InputCircuit' || this.$store.state.previousRoute == 'calc_OutputCircuit' ||
                this.$store.state.previousRoute == 'calc_Manuel' || this.$store.state.previousRoute == 'calc_Summary' || this.$store.state.previousRoute == 'results' ||
                this.$store.state.previousRoute == 'reportPdfPage' || this.$store.state.previousRoute == 'calculation') {
                this.backBtnActive = true;
            } else {
                this.backBtnActive = false;
            }

            this.$store.state.isFooterFixed = true;
            this.getTable();
        },
        destroyed() {
            this.$store.state.isFooterFixed = false;
        },
        methods: {
            getTable() {
                this.devicesdata = [];
                var langCode = this.$lang.Format.Lang;
                api.panels.getCircuitCalculationDevices(langCode)
                    .then((res) => {
                        console.log(res);
                        if (res.isError) {
                            console.log("error ");
                        } else {
                            res.data.forEach((e) => {
                                this.devicesdata.push(e);
                            });
                        }
                    })
                    .catch((error) => {
                        console.error("Error fetching data:", error);
                    });
            },
            downloadExcel() {
                this.isLoading = true;
                api.reportApi.getExcel_CircuitDevices('Panel', null, null, this.$lang.CircuitDevices.CircuitReport, this.$lang.Format.Lang, 'ExcelAdmin').then((res) => {
                    this.isLoading = false;
                }).catch((error) => {
                    this.isLoading = false;
                    this.$buefy.dialog.alert('An error occurred: ' + (error.response ? error.response.data.message : error.message));
                });
            },
            deleteDropFile(index) {
                this.dropFiles.splice(index, 1);
                this.formData.splice(index, 1);
            },
            calcSize(file) {
                const fileSize = Math.trunc(file.size / 1024);
                if (fileSize > 1024) {
                    const fileSizeMb = fileSize / 1024;
                    return `${fileSizeMb.toFixed(2)}MB`;
                } else {
                    return `${fileSize}kb`;
                }
            },
            uploadExcel() {
                const config = {
                    onUploadProgress: (progressEvent) => {
                        this.loadPercentage = Math.round((progressEvent.loaded * 50) / progressEvent.total);
                    },
                    onDownloadProgress: (progressEvent) => {
                        this.loadPercentage = Math.round((progressEvent.loaded * 50) / progressEvent.total) + 50;
                    }
                };
                this.isSendLoading = true;
                if (this.formData != null) {
                    var memberId = this.$store.state.memberId;
                    api.reportApi.uploadExcel_CircuitDevices('Panel', this.formData, memberId, config, 'UploadExcel_CircuitDevices_Admin').then((res) => {
                        this.isSendLoading = false;
                        if (res.isError) {
                            this.$buefy.dialog.alert(this.$errorModal(res));
                        } else {
                            this.formData = res.data;
                            this.$buefy.dialog.alert(this.$successModal(this.newDataMsg));
                            this.dropFiles = [];
                            this.formData = [];
                            this.loadPercentage = 0;
                            this.getTable();
                        }
                    });
                }
            },
            columnTdAttrs(row, column) {
                if (row.id === 'Total') {
                    if (column.label === 'ID') {
                        return {
                            colspan: 4,
                            class: 'has-text-weight-bold',
                            style: {
                                'text-align': 'left !important'
                            }
                        }
                    } else if (column.label === 'Gender') {
                        return {
                            class: 'has-text-weight-semibold'
                        }
                    } else {
                        return {
                            style: { display: 'none' }
                        }
                    }
                }
                return null
            },
            openUpdateModal(row) {
                this.isUpdateModalActive = true;
                this.deviceId = row.Id;
                this.deviceType = row.Type;
                this.deviceBrand = row.Brand;
                this.deviceCode = row.Code;

                if (row.In_Value != 'NA') {
                    this.deviceIn = row.In_Value;
                } else {
                    this.deviceIn = '';
                }

                if (row.Pole != 'NA') {
                    this.devicePole = row.Pole;
                } else {
                    this.devicePole = '';
                }

                if (row.Sbt_Cekmeceli != 'NA') {
                    this.sbt_cekmeceli = row.Sbt_Cekmeceli;
                } else {
                    this.sbt_cekmeceli = '';
                }

                if (row.Power != 'NA') {
                    this.devicePower = row.Power;
                } else {
                    this.devicePower = '';
                }

                if (row.Pv != 'NA') {
                    this.devicePv = row.Pv;
                } else {
                    this.devicePv = '';
                }
            },
            updateRow() {
                const row = {
                    Id: this.deviceId,
                    Type: this.deviceType,
                    Brand: this.deviceBrand,
                    Code: this.deviceCode,
                    In_Value: this.deviceIn,
                    Pole: this.devicePole,
                    Sbt_Cekmeceli: this.sbt_cekmeceli,
                    Power: this.devicePower,
                    Pv: this.devicePv,
                    CreateDate: null,
                    UpdateDate: null
                };

                var memberId = this.$store.state.memberId;
                api.panels.updateCircuitCalculationDevices(row, memberId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.isUpdateModalActive = false;
                        this.$buefy.toast.open(this.$lang.OtherDialog.Updated);
                        this.getTable();
                    }
                });
            },
            deleteRow(row) {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message,
                    confirmText: this.$lang.DeleteDialog.Confirm,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () =>

                        api.panels.deleteFromCircuitCalculationDevices(row.Id).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                                this.getTable();
                            }
                        })
                })
            },
            deleteSelectedRows() {
                this.$buefy.dialog.confirm({
                    title: this.$lang.DeleteDialog.Title,
                    message: this.$lang.DeleteDialog.Message_3,
                    confirmText: this.$lang.DeleteDialog.Confirm_3,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        api.panels.deleteFromCircuitCalculationDevices_2(this.checkedRows).then((res) => {
                            if (res.isError) {
                                console.log("error ");
                            } else {
                                this.$buefy.toast.open(this.$lang.DeleteDialog.Deleted);
                                this.getTable();
                                this.checkedRows = [];
                            }
                        })
                    }
                })
            },
            previous() {
                router.push('/' + this.$store.state.previousRoute);
            },
            formatDate(date) {
                if (date == null) {
                    return '-';
                } else {
                    var yil = date.getFullYear();
                    var ay = ('0' + (date.getMonth() + 1)).slice(-2);
                    var gun = ('0' + date.getDate()).slice(-2);
                    var saat = ('0' + date.getHours()).slice(-2);
                    var dakika = ('0' + date.getMinutes()).slice(-2);
                    var saniye = ('0' + date.getSeconds()).slice(-2);

                    var formatliTarih = yil + '-' + ay + '-' + gun + ' ' + saat + ':' + dakika + ':' + saniye;

                    return formatliTarih;
                }
            },
        }
    };
</script>
