import { axios, getToken } from '@/api/common';

function handleResponse(response) {
	if (response.status === 200 || response.status === 204) {
		return {
			isError: false,
			data: response.data
		};
	}
}

function handleError(error) {
	return {
		isError: true,
		errorDecription: error.response.data.error_description
	};
}

export default {
	get(pathName, queryName, queryValue, optionalPath = '') {
		return axios
			.post(`/${pathName}/${optionalPath}`, null, {
				params: { [`${queryName}`]: queryValue },
				headers: { Authorization: 'Bearer ' + getToken() }
			})
			.then((response) => handleResponse(response))
			.catch((error) => handleError(error));
	},
	getExcel_CircuitDevices(pathName, queryName, queryValue, fileName, langCode, optionalPath = '') {
		console.log("getExcel " + pathName + " " + optionalPath);
		console.log("getExcel2 " + queryName + " " + queryValue + " " + fileName);
		return axios
			.post(`/${pathName}/${optionalPath}`, null, {
				responseType: 'blob',
				params: { [`${queryName}`]: queryValue, langCode: langCode },
				headers: { Authorization: 'Bearer ' + getToken() }
			})
			.then((response) => {
            const link = document.createElement('a');
				link.href = window.URL.createObjectURL(
					new Blob([response.data])
				);
				 link.setAttribute('download', fileName + '.xlsx');
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => handleError(error));
	},
	getExcel_Projects(pathName, queryName, queryValue, fileName, optionalPath = '') {
		return axios
			.post(`/${pathName}/${optionalPath}`, null, {
				responseType: 'blob',
				params: { [`${queryName}`]: queryValue },
				headers: { Authorization: 'Bearer ' + getToken() }
			})
			.then((response) => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(
					new Blob([response.data])
				);
				link.setAttribute('download', fileName + '.xlsx');
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => handleError(error));
	},
	uploadExcel_CircuitDevices(pathName, payload, memberId, config, optionalPath = '') {
		return axios
			.post(`/${pathName}/Add${optionalPath}?memberId=${memberId}`, payload, memberId, {
				...config,
				headers: { Authorization: 'Bearer ' + getToken() }
			})
			.then((response) => handleResponse(response))
			.catch((error) => handleError(error));
	},
	uploadExcel_CircuitDevices_Admin(pathName, payload, memberId, config, optionalPath = '') {
		return axios
			.post(`/${pathName}/Add${optionalPath}?memberId=${memberId}`, payload, memberId, {
				...config,
				headers: { Authorization: 'Bearer ' + getToken() }
			})
			.then((response) => handleResponse(response))
			.catch((error) => handleError(error));
	},
	downloadMembers(fileName, optionalPath = '') {
		return axios
			.post(`/Panel/${optionalPath}`, null, {
				responseType: 'blob',
				headers: { Authorization: 'Bearer ' + getToken() }
			})
			.then((response) => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(
					new Blob([response.data])
				);
				link.setAttribute('download', fileName + '.xlsx');
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => handleError(error));
	}
};
