<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac">
            {{this.$lang.FanCalculation.Title}}
            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="openFanPdf" style="float: right;">{{this.$lang.FanCalculation.ReportBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 1.5rem; padding-top: 1.4rem;">
            <div class="columns">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-2" style="background-color: #FFFFFF; height: 100vh; display: flex; flex-direction: column;">
                        <verticalStep-bar />
                    </div>
                    <div class="column is-9">
                        <div class="columns">
                            <div class="column is-6">
                                <section>
                                    <div class="field is-grouped">
                                        <b-field>
                                            <template #label>
                                                <span v-html="$lang.ResultsInfos.Ta"></span>
                                            </template>
                                        </b-field>
                                        <b-field> {{ envTemperature }} </b-field>
                                        <b-field label="°C"></b-field>
                                    </div>
                                    <div class="field is-grouped">
                                        <b-field>
                                            <template #label>
                                                <span v-html="$lang.ResultsInfos.Tmax"></span>
                                            </template>
                                        </b-field>
                                        <b-field> {{ maxTemperature }} </b-field>
                                        <b-field label="°C"></b-field>
                                    </div>
                                    <div class="field is-grouped">
                                        <b-field :label="this.$lang.ResultsInfos.P890"></b-field>
                                        <b-field> {{ truncate(P_890Fan, 1) }} </b-field>
                                        <b-field label="W"></b-field>
                                    </div>
                                    <div class="field is-grouped">
                                        <b-field :label="this.$lang.ResultsInfos.Title_2"></b-field>
                                        <b-field> {{ truncate(P_Total, 1) }} </b-field>
                                        <b-field label="W"></b-field>
                                    </div>
                                </section>
                            </div>
                            <div class="column is-4">
                                <div class="box" style="width: 550px; height: 90px; background-color: #878787;">
                                    <b-field class="box" style="padding: 15px">
                                        <b-field class="field is-grouped">
                                            <template #label>
                                                <span v-html="$lang.FanCalculation.Title_2"></span>
                                            </template>
                                            <b-field style="margin-left: 4px"> {{ truncate(fanResult, 1) }} </b-field>
                                        </b-field>
                                        <span style="margin-left: 4px" v-html="$lang.FanCalculation.NumFormat"></span>
                                    </b-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';

    export default {
        name: 'PanelInfos',
        components: {
            VerticalStepBar
        },
        data() {
            return {
                isActive: true,

                virtualSurfaceIndex: 0,
                y: 0,
                g: 0,
                d: 0,
                envTemperature: 0,
                maxTemperature: 0,
                P_890: 0,
                P_Total: 0,
                fanResult: 0,
                P_890Fan: 0,
            }
        },
        mounted() {
            this.envTemperature = this.$store.state.envTemperature;
            this.maxTemperature = this.$store.state.maxTemperature;

            if (this.$store.state.P_890Fan != null && this.$store.state.P_890Fan != undefined) {
                this.P_890Fan = this.$store.state.P_890Fan;
            }
            this.P_890 = this.$store.state.P_890;

            this.P_Total = this.$store.state.P_Total;

            this.calculate_FanResult();
            this.$store.state.activeStep = 5;

            this.$store.state.isFooterFixed = true;
        },
        watch: {
            $route() {
                this.envTemperature = this.$store.state.envTemperature;
                this.maxTemperature = this.$store.state.maxTemperature;
                this.P_890 = this.$store.state.P_890;
                this.P_890Fan = this.$store.state.P_890Fan;
                this.P_Total = this.$store.state.P_Total;
            }
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/reportPdfPage');
            },
            openFanPdf() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/fanPdfPage');
            },
            calculate_FanResult() {
                var altitude = this.$store.state.altitude;

                api.panels.get_Fan_V_Min(this.envTemperature, this.maxTemperature, this.P_890Fan, this.P_Total, altitude).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.fanResult = res.data;
                        console.log(this.fanResult);
                    }
                });
            },
            truncate(numToBeTruncated, numOfDecimals) {
                var theNumber = numToBeTruncated.toString();
                if (theNumber.includes('.')) {
                    var pointIndex = theNumber.indexOf('.');
                    var _result = +(theNumber.slice(0, pointIndex > -1 ? ++numOfDecimals + pointIndex : undefined));
                    var _resultString = _result.toString();
                    if (!_resultString.includes('.')) {
                        return _resultString + '.0';
                    } else {
                        return _result;
                    }
                } else {
                    return theNumber + '.0';
                }
            }
        },

    };
</script>
