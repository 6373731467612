import { axios } from '@/api/common';


export default {
    getAcceptLogs() {
        return axios.post('/Panel/GetAcceptLogs').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getAcceptLogs2(first, second) {
        return axios.get('/Panel/GetAcceptLogs2?First=' + first + "&Second=" + second).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getProjects(memberCode) {
        return axios.get('/Panel/GetProjects?MemberCode=' + memberCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getAllProjects(first, second) {
        return axios.get('/Panel/GetAllProjects?First=' + first + "&Second=" + second).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addNewProject(project) {
        return axios.post('/Panel/AddNewProject', project).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addNewProjectManuel(project) {
        return axios.post('/Panel/AddNewProject_Manuel', project).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateProject(project) {
        return axios.post('/Panel/UpdateProject', project).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateProjectMemberId(projectId, memberId) {
        return axios.get('/Panel/UpdateProjectMemberId?ProjectId=' + projectId + '&MemberId=' + memberId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addNewProjectLog(log) {
        return axios.post('/Panel/AddNewProjectLog', log).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateAcceptLog(log) {
        return axios.post('/Panel/UpdateAcceptLog', log).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteProject(projectId) {
        return axios.get('/Panel/DeleteProject?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitCalculationDevices(langCode) {
        return axios.post('/Panel/GetCircuitCalculationDevices?langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateCircuitCalculationDevices(device, memberId) {
        return axios.post('/Panel/UpdateCircuitCalculationDevices?memberId=' + memberId, device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromCircuitCalculationDevices(deviceId) {
        return axios.get('/Panel/DeleteFromCircuitCalculationDevices?DeviceId=' + deviceId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromCircuitCalculationDevices_2(devices) {
        return axios.post('/Panel/DeleteFromCircuitCalculationDevices_2', devices).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getRatedCurrentList() {
        return axios.post('/Panel/GetRatedCurrentList').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getRatedCurrentOptions(langCode) {
        return axios.get('/Panel/GetRatedCurrentOptions?LangCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getPanelTypes(selectedRatedCurrent, langCode) {
        return axios.get('/Panel/GetPanelTypes?SelectedRatedCurrent=' + selectedRatedCurrent + '&LangCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getPanelDimensions(panelTypeId, ip) {
        return axios.get('/Panel/GetPanelDimensions?PanelTypeId=' + panelTypeId + '&Ip=' + ip).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getPanelProtectionClassOptions(panelTypeId) {
        return axios.get('/Panel/GetPanelProtectionClassOptions?PanelTypeId=' + panelTypeId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getS_airValue(selectedRatedCurrent, selectedPanelProtectionClass, panelWidth) {
        return axios.get('/Panel/GetS_airValue?SelectedRatedCurrent=' + selectedRatedCurrent + '&SelectedPanelProtectionClass=' + selectedPanelProtectionClass + '&PanelWidth=' + panelWidth).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getLocationVirtualSurfaceOptions(panelTypeIndex, langCode) {
        return axios.get('/Panel/GetLocationVirtualSurfaceOptions?PanelTypeIndex=' + panelTypeIndex + '&LangCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getHorizontalSeperationNumbers(selectedRatedCurrent) {
        return axios.get('/Panel/getHorizontalSeperationNumbers?SelectedRatedCurrent=' + selectedRatedCurrent).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getAeValue(ratedCurrentIndex, selectedSurfaceLocationId, dimension) {
        return axios.get('/Panel/getAeValue?RatedCurrentIndex=' + ratedCurrentIndex + '&SelectedSurfaceLocationId=' + selectedSurfaceLocationId + '&Dimension=' + dimension).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getMaximumPanelTemperatures(environmentTemperature) {
        return axios.get('/Panel/getMaximumPanelTemperatures?EnvironmentTemperature=' + environmentTemperature).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getAltitudes() {
        return axios.post('/Panel/GetAltitudes').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getBusbarDimensions() {
        return axios.post('/Panel/GetBusbarDimensions').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getBusbarCalculationDevices(projectId) {
        return axios.get('/Panel/GetBusbarCalculationDevices?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToBusbarCalculationDevices(device) {
        return axios.post('/Panel/AddToBusbarCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromBusbarCalculationDevices(device) {
        return axios.post('/Panel/DeleteFromBusbarCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateBusbarCalculationDevice(device) {
        return axios.post('/Panel/UpdateBusbarCalculationDevice', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getTotal_P_BusbarCalculationDevices(projectId) {
        return axios.get('/Panel/getTotal_P_BusbarCalculationDevice?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getBusbarCalculationDevice(busbarId, inPanelMaxTemp) {
        return axios.get('/Panel/GetBusbarCalculationDevice?BusbarId=' + busbarId + '&InPanelMaxTemp=' + inPanelMaxTemp).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Types_InputCircs(langCode) {
        return axios.post('/Panel/GetCircuitDevice_Types_InputCircs?langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Types(langCode) {
        return axios.post('/Panel/GetCircuitDevice_Types?langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Brands(type, langCode) {
        return axios.get('/Panel/GetCircuitDevice_Brands?Type=' + type + '&langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Codes(brand, type, langCode) {
        return axios.get('/Panel/GetCircuitDevice_Codes?Brand=' + brand + '&Type=' + type + '&langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Ins(brand, type, code, langCode) {
        return axios.get('/Panel/GetCircuitDevice_Ins?Brand=' + brand + '&Type=' + type + '&Code=' + code + '&langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Pole(brand, type, code, inValue, langCode) {
        if (inValue == null) {
            inValue = '';
        }

        return axios.get('/Panel/GetCircuitDevice_Pole?Brand=' + brand + '&Type=' + type + '&Code=' + code + '&InValue=' + inValue + '&langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Sbt_Cekmeceli(brand, type, code, inValue, pole, langCode) {
        if (inValue == null) {
            inValue = '';
        }
        if (pole == null) {
            pole = '';
        }

        return axios.get('/Panel/GetCircuitDevice_Sbt_Cekmeceli?Brand=' + brand + '&Type=' + type + '&Code=' + code + '&InValue=' + inValue + '&Pole=' + pole + '&langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Power(brand, type, code, inValue, pole, sbtCkmc, langCode) {
        if (inValue == null) {
            inValue = '';
        }
        if (pole == null) {
            pole = '';
        }
        if (sbtCkmc == null) {
            sbtCkmc = '';
        }

        return axios.get('/Panel/GetCircuitDevice_Power?Brand=' + brand + '&Type=' + type + '&Code=' + code + '&InValue=' + inValue + '&Pole=' + pole + '&SbtCkmc=' + sbtCkmc + '&langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCircuitDevice_Pv(brand, type, code, inValue, pole, sbtCkmc, power, langCode) {
        if (inValue == null) {
            inValue = '';
        }
        if (pole == null) {
            pole = '';
        }
        if (sbtCkmc == null) {
            sbtCkmc = '';
        }
        if (power == null) {
            power = '';
        }

        return axios.get('/Panel/GetCircuitDevice_Pv?Brand=' + brand + '&Type=' + type + '&Code=' + code + '&InValue=' + inValue + '&Pole=' + pole + '&SbtCkmc=' + sbtCkmc + '&Power=' + power + '&langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getInputCircuitCalcDevices(projectId) {
        return axios.get('/Panel/Get_InputCircuitCalculationDevices?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToInputCircuitCalcDevices(device) {
        return axios.post('/Panel/AddTo_InputCircuitCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromInputCircuitCalcDevices(device) {
        return axios.post('/Panel/DeleteFrom_InputCircuitCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateInputCircuitCalcDevices(device) {
        return axios.post('/Panel/Update_InputCircuitCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getTotal_P_InputCircuitCalculationDevices(projectId) {
        return axios.get('/Panel/getTotal_P_InputCircuitCalculationDevices?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getConnectionTypes(langCode) {
        return axios.post('/Panel/GetConnectionTypes?langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getCablePositions(langCode) {
        return axios.post('/Panel/GetCablePositions?langCode=' + langCode).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getPhaseNumbers(id) {
        return axios.get('/Panel/GetPhaseNumbers?ConnectionTypeId=' + id).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getConductorDimensions(connTypeId, cablePosId, temperature) {
        return axios.get('/Panel/getConductorDimensions?ConnTypeId=' + connTypeId + '&CablePosId=' + cablePosId + '&Temperature=' + temperature).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getConductor_I_Max(connTypeId, selectedDimensionId, cablePosId, temperature) {
        return axios.get('/Panel/GetConductor_I_Max?ConnTypeId=' + connTypeId + '&SelectedDimensionId=' + selectedDimensionId + '&CablePosId=' + cablePosId + '&Temperature=' + temperature).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    GetConductor_LostPower(connTypeId, selectedDimensionId, cablePosId, temperature) {
        return axios.get('/Panel/GetConductor_LostPower?ConnTypeId=' + connTypeId + '&DimensionId=' + selectedDimensionId + '&CablePosId=' + cablePosId + '&Temperature=' + temperature).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getInputCircuitDeviceConductors(projectId) {
        return axios.get('/Panel/Get_InputCircuitDeviceConductors?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToInputCircuitDeviceConductors(device) {
        return axios.post('/Panel/AddTo_InputCircuitDeviceConductors', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromInputCircuitDeviceConductors(device) {
        return axios.post('/Panel/RemoveFrom_InputCircuitDeviceConductors', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateInputCircuitDeviceConductors(device) {
        return axios.post('/Panel/Update_InputCircuitDeviceConductors', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    calculate_P_Conductor(connTypeId, conductorLength, lostPower, phaseNumber) {
        return axios.get('/Panel/Calculate_P_Conductor?ConnTypeId=' + connTypeId + '&ConductorLength=' + conductorLength + '&LostPower=' + lostPower + '&PhaseNumber=' + phaseNumber).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getTotal_P_InputCircuitDeviceConductors(projectId) {
        return axios.get('/Panel/GetTotal_P_InputCircuitDeviceConductors?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },

    getMaxCircuitIndex_OutputCircuitCalculationDevices(projectId) {
        return axios.get('/Panel/GetMaxCircuitIndex_OutputCircuitCalculationDevices?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getOutputCircuitCalcDevices_Detailed(projectId) {
        return axios.get('/Panel/Get_OutputCircuitCalculationDevices_Detailed?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToOutputCircuitCalcDevices(device) {
        return axios.post('/Panel/AddTo_OutputCircuitCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToOutputCircuitCalcDevices_2(devices) {
        return axios.post('/Panel/AddTo_OutputCircuitCalculationDevices_2', devices).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromOutputCircuitCalcDevices(device) {
        return axios.post('/Panel/DeleteFrom_OutputCircuitCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromOutputCircuitCalcDevices_2(id) {
        return axios.get('/Panel/DeleteFrom_OutputCircuitCalculationDevices_2?Id=' + id).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateOutputCircuitCalcDevices(device) {
        return axios.post('/Panel/Update_OutputCircuitCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getOutputCircuitDeviceConductors_Detailed(projectId) {
        return axios.get('/Panel/Get_OutputCircuitDeviceConductors_Detailed?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToOutputCircuitDeviceConductors(device) {
        return axios.post('/Panel/AddTo_OutputCircuitDeviceConductors', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToOutputCircuitDeviceConductors_2(devices) {
        return axios.post('/Panel/AddTo_OutputCircuitDeviceConductors_2', devices).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromOutputCircuitDeviceConductors(device) {
        return axios.post('/Panel/DeleteFrom_OutputCircuitDeviceConductors', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromOutputCircuitDeviceConductors_2(id) {
        return axios.get('/Panel/DeleteFrom_OutputCircuitDeviceConductors_2?Id=' + id).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateOutputCircuitDeviceConductors(device) {
        return axios.post('/Panel/Update_OutputCircuitDeviceConductors', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },

    get_OutputCircuit_SubDistributionBusbars_Detailed(projectId) {
        return axios.get('/Panel/Get_OutputCircuit_SubDistributionBusbars_Detailed?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToOutputCircuit_SubDistributionBusbars(device) {
        return axios.post('/Panel/AddTo_OutputCircuit_SubDistributionBusbars', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToOutputCircuit_SubDistributionBusbars_2(devices) {
        return axios.post('/Panel/AddTo_OutputCircuit_SubDistributionBusbars_2', devices).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromOutputCircuit_SubDistributionBusbars(device) {
        return axios.post('/Panel/RemoveFrom_OutputCircuit_SubDistributionBusbars', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromOutputCircuit_SubDistributionBusbars_2(id) {
        return axios.get('/Panel/RemoveFrom_OutputCircuit_SubDistributionBusbars_2?Id=' + id).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateOutputCircuit_SubDistributionBusbars(device) {
        return axios.post('/Panel/Update_OutputCircuit_SubDistributionBusbars', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },

    deleteFrom_OutputCircuits(id, projectId) {
        return axios.get('/Panel/DeleteFrom_OutputCircuits?Id=' + id + '&ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    update_OutputCircuits(device) {
        return axios.post('/Panel/Update_OutputCircuits', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },

    getTotal_P_OutputCircuitCalculationDevices(projectId) {
        return axios.get('/Panel/GetTotal_P_OutputCircuitCalculationDevices?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getTotal_P_OutputCircuitDeviceConductors(projectId) {
        return axios.get('/Panel/GetTotal_P_OutputCircuitDeviceConductors?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getTotal_P_OutputCircuit_SubDistributionBusbars(projectId) {
        return axios.get('/Panel/GetTotal_P_OutputCircuit_SubDistributionBusbars?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getAllTotal_P_OutputCircuit(projectId) {
        return axios.get('/Panel/GetAllTotal_P_OutputCircuit?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getManuelCalcDevices(projectId) {
        return axios.get('/Panel/Get_OtherPowerCalculationDevices?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    addToManuelCalcDevices(device) {
        return axios.post('/Panel/AddTo_OtherPowerCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromManuelCalcDevices(device) {
        return axios.post('/Panel/DeleteFrom_OtherPowerCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateManuelCalcDevices(device) {
        return axios.post('/Panel/Update_OtherPowerCalculationDevices', device).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    getTotal_P_OtherPowerCalculationDevices(projectId) {
        return axios.get('/Panel/GetTotal_P_OtherPowerCalculationDevices?ProjectId=' + projectId).then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },

    deleteFromCalcTables_1(projectId) {
        return axios.get('/Panel/DeleteFromCalculationTables?ProjectId=' + projectId + '&TableName=BusbarCalculationDevices').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromCalcTables_2(projectId) {
        return axios.get('/Panel/DeleteFromCalculationTables?ProjectId=' + projectId + '&TableName=InputCircuitCalculationDevices').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromCalcTables_3(projectId) {
        return axios.get('/Panel/DeleteFromCalculationTables?ProjectId=' + projectId + '&TableName=InputCircuitDeviceConductors').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromCalcTables_4(projectId) {
        return axios.get('/Panel/DeleteFromCalculationTables?ProjectId=' + projectId + '&TableName=OutputCircuitCalculationDevices').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromCalcTables_5(projectId) {
        return axios.get('/Panel/DeleteFromCalculationTables?ProjectId=' + projectId + '&TableName=OutputCircuitDeviceConductors').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromCalcTables_6(projectId) {
        return axios.get('/Panel/DeleteFromCalculationTables?ProjectId=' + projectId + '&TableName=OutputCircuit_SubDistributionBusbars').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    deleteFromCalcTables_7(projectId) {
        return axios.get('/Panel/DeleteFromCalculationTables?ProjectId=' + projectId + '&TableName=OtherPowerCalculationDevices').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateCalcTables_1(oldProjectId, newProjectId) {
        return axios.get('/Panel/UpdateCalculationTableProjectIds?OldProjectId=' + oldProjectId + '&NewProjectId=' + newProjectId + '&TableName=BusbarCalculationDevices').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateCalcTables_2(oldProjectId, newProjectId) {
        return axios.get('/Panel/UpdateCalculationTableProjectIds?OldProjectId=' + oldProjectId + '&NewProjectId=' + newProjectId + '&TableName=InputCircuitCalculationDevices').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateCalcTables_3(oldProjectId, newProjectId) {
        return axios.get('/Panel/UpdateCalculationTableProjectIds?OldProjectId=' + oldProjectId + '&NewProjectId=' + newProjectId + '&TableName=InputCircuitDeviceConductors').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateCalcTables_4(oldProjectId, newProjectId) {
        return axios.get('/Panel/UpdateCalculationTableProjectIds?OldProjectId=' + oldProjectId + '&NewProjectId=' + newProjectId + '&TableName=OutputCircuitCalculationDevices').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateCalcTables_5(oldProjectId, newProjectId) {
        return axios.get('/Panel/UpdateCalculationTableProjectIds?OldProjectId=' + oldProjectId + '&NewProjectId=' + newProjectId + '&TableName=OutputCircuitDeviceConductors').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateCalcTables_6(oldProjectId, newProjectId) {
        return axios.get('/Panel/UpdateCalculationTableProjectIds?OldProjectId=' + oldProjectId + '&NewProjectId=' + newProjectId + '&TableName=OutputCircuit_SubDistributionBusbars').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },
    updateCalcTables_7(oldProjectId, newProjectId) {
        return axios.get('/Panel/UpdateCalculationTableProjectIds?OldProjectId=' + oldProjectId + '&NewProjectId=' + newProjectId + '&TableName=OtherPowerCalculationDevices').then((response) => {
            if (response.status == 200) {
                return {
                    isError: false,
                    data: response.data
                };
            }
        }).catch((error) => {
            return {
                isError: true,
                errorDecription: error.response.data.error_description
            }
        });
    },

	get_P_890(locationIndex, height, width, depth, tmax, tenvironment, ip) {
		return axios.get('/Panel/Get_P_890?LocationIndex=' + locationIndex + "&Height=" + height + "&Width=" + width + "&Depth=" + depth + "&Tmax=" + tmax + "&Tenvironment=" + tenvironment + "&Ip=" + ip).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
    get_P_890_Fan(locationIndex, height, width, depth, tmax, tenvironment, ip) {
		return axios.get('/Panel/Get_P_890?LocationIndex=' + locationIndex + "&Height=" + height + "&Width=" + width + "&Depth=" + depth + "&Tmax=" + tmax + "&Tenvironment=" + tenvironment + "&Ip=" + ip).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	getResult_630A(p890, pLostPower, langCode) {
		return axios.get('/Panel/GetResult_630A?P_890=' + p890 + "&P_LostPower=" + pLostPower + "&LangCode=" + langCode).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},

	get_k_Value(aeVal, isIP31, sAir) {
		return axios.get('/Panel/Get_k_Value?Ae=' + aeVal + "&Is_IP31=" + isIP31 + "&S_air=" + sAir).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	get_d_Value(aeVal, isIP31, n) {
		return axios.get('/Panel/Get_d_Value?Ae=' + aeVal + "&Is_IP31=" + isIP31 + "&N=" + n).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	get_x_Value(aeVal, isIP31) {
		return axios.get('/Panel/GetFactorX_Value?Ae=' + aeVal + "&Is_IP31=" + isIP31).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	get_DeltaT_0_5(k, d, totalP, x) {
		return axios.get('/Panel/Calculate_Delta_T_0_5?_K=' + k + "&_D=" + d + "&Total_P=" + totalP + "&_X=" + x).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	get_f_Value(height, width, depth) {
		return axios.get('/Panel/Get_f_Value?Height=' + height + "&Width=" + width + "&Depth=" + depth).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	get_g_Value(height, width) {
		return axios.get('/Panel/Get_g_Value?Height=' + height + "&Width=" + width).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	get_c_Value(aeVal, isIP31, panelTypeId, surfaceId, fValue, gValue, sAir) {
		return axios.get('/Panel/Get_c_Value?Ae=' + aeVal + "&Is_IP31=" + isIP31 + "&PanelTypeId=" + panelTypeId + "&SurfaceId=" + surfaceId +
			             "&F_Value=" + fValue + "&G_Value=" + gValue + "&S_air=" + sAir).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	getResult_1600A(deltaT1, maxTemp, environmentTemp, langCode) {
		return axios.get('/Panel/GetResult_1600A?Delta_T_1=' + deltaT1 + "&MaxTemp=" + maxTemp + "&EnvironmentTemp=" + environmentTemp + "&LangCode=" + langCode).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	getReportPdf(langCode, memberId, projectId) {
		return axios.get('/Report/DownloadReportPdf?culture=' + langCode + '&memberId=' + memberId + "&projectId=" + projectId).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
    get_Fan_V_Min(envTemp, maxTemp, p890, pLostPower, altidude) {
		return axios.get('/Panel/Calculate_Fan_V_Min?EnvTemp=' + envTemp + "&MaxTemp=" + maxTemp + "&P_890=" + p890 + "&P_LostPower=" + pLostPower + "&Altidude=" + altidude).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	getFanPdf(langCode, memberId, projectId) {
		return axios.get('/Report/DownloadFanPdf?culture=' + langCode + '&memberId=' + memberId + "&projectId=" + projectId).then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	getMembersList() {
		return axios.post('/Panel/GetMembersList').then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	},
	downloadMembersList() {
		return axios.get('/Report/DownloadMembersList').then((response) => {
			if (response.status == 200) {
				return {
					isError: false,
					data: response.data
				};
			}
		}).catch((error) => {
			return {
				isError: true,
				errorDecription: error.response.data.error_description
			}
		});
	}
}