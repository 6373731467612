<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac" horizontal>
            {{this.$lang.EnvConditionInfos.Title}} &nbsp; <span class="fillText" style="font-size:16px">{{this.$lang.Menus.FillFields}}</span>

            <p class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
            <p class="control" slot="right">
                <b-button class="button is-primary" @click="submit" style="float: right;">{{this.$lang.Menus.ContinueBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" style="padding-left: 1.75rem; padding-top: 1.4rem">
            <div class="columns">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-2" style="background-color: #FFFFFF; width: 10%; height: 100vh; display: flex; flex-direction: column;">
                        <verticalStep-bar />
                    </div>
                    <div class="column is-10">
                        <div class="columns">
                            <div class="column is-6">
                                <b-field class="envRow" style="width: 300%" horizontal :type="{ 'is-danger': hasError_Ta, 'is-danger': hasError_Ta2 }"
                                         :message="{  'Alan boş bırakılamaz!': hasError_Ta,
                                                                             '10 ile 50 arasında bir değer giriniz!': hasError_Ta2 }">
                                    <template #label>
                                        <span class="rowItem1" v-html="$lang.EnvConditionInfos.Ta"></span>
                                    </template>
                                    <b-field class="field is-grouped">
                                        <b-input class="envTemp" type="number" v-model="envTemperature" step="1" :isInput="false" @input="getMaximumPanelTemperatures(); enableManuelSave();" style="width: 7%"></b-input>
                                        <b-field label="°C" style="padding-left: 0.5rem; padding-top: 0.5rem;"></b-field>
                                    </b-field>
                                </b-field>

                                <b-field class="envRow" style="width: 300%" horizontal :type="{ 'is-danger': hasError_Tmax }" :message="this.emptyWarning_Tmax">
                                    <template #label>
                                        <span class="rowItem1" v-html="$lang.EnvConditionInfos.Tmax"></span>
                                    </template>
                                    <b-field class="field is-grouped">
                                        <b-select v-if="temperaturelist" v-model="temperature" @input="enableManuelSave()">
                                            <option v-for="option in temperaturelist"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                        <b-field label="°C" style="padding-top: 0.5rem; padding-left: 0.5rem;"></b-field>
                                    </b-field>
                                </b-field>

                                <b-field class="envRow" style="width: 300%" horizontal>
                                    <template #label>
                                        <span class="rowItem1" v-html="$lang.EnvConditionInfos.Humidity"></span>
                                    </template>
                                    <b-field class="field is-grouped">
                                        <b-input class="envHumidity" v-model="humidity" style="width: 10%" disabled></b-input>
                                    </b-field>
                                </b-field>

                                <b-field class="envRow" style="width: 300%" horizontal>
                                    <template #label>
                                        <span class="rowItem1" v-html="$lang.EnvConditionInfos.Altitude"></span>
                                    </template>
                                    <b-field class="field is-grouped">
                                        <b-select v-if="altitudelist" v-model="altitude" @input="enableManuelSave()">
                                            <option v-for="option in altitudelist"
                                                    :value="option"
                                                    :key="option.id">
                                                {{ option }}
                                            </option>
                                        </b-select>
                                        <b-field label="m" style="padding-top: 0.5rem; padding-left: 0.5rem;"></b-field>
                                    </b-field>
                                </b-field>

                                <div class="card-content">
                                    {{ this.$lang.EnvConditionInfos.Info }}
                                </div>
                            </div>

                            <div class="column is-6 is-three-quarters-mobile envImg">
                                <b-field v-if="!isLangEn">
                                    <b-image src="/img/Oda_TR.png"
                                             alt="Oda_TR">
                                    </b-image>
                                </b-field>
                                <b-field v-if="isLangEn">
                                    <b-image src="/img/Oda_EN.png"
                                             alt="Oda_EN">
                                    </b-image>
                                </b-field>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import router from '@/router';
    import api from '@/api';
    import VerticalStepBar from '@/components/VerticalStepBar.vue';

    export default {
        name: 'PanelInfos',
        components: {
            VerticalStepBar,
        },
        data() {
            return {
                isActive: true,
                isLangEn: false,
                isInfoOpened: false,
                envTemperature: 35,
                temperaturelist: [],
                temperature: 0,
                humidity: '50%',
                altitudelist: [],
                altitude: 0,
                canGoNextPage: false,
                hasError_Ta: false,
                hasError_Ta2: false,
                emptyWarning_Ta: '',
                hasError_Tmax: false,
                emptyWarning_Tmax: ''
            }
        },
        mounted() {
            if (this.$lang.Format.Lang == 'en-EN') {
                this.isLangEn = true;
            } else {
                this.isLangEn = false;
            }

            this.getMaximumPanelTemperatures();
            this.getAltitudes();

            this.temperature = this.$store.state.maxTemperature;
            if (this.$store.state.envTemperature != 0) {
                this.envTemperature = this.$store.state.envTemperature;
            }
            this.altitude = this.$store.state.altitude;
            this.$store.state.activeStep = 2;
            this.$store.state.isFooterFixed = true;
        },
        methods: {
            previous() {
                this.$store.isPageOpenedFromStepBar = false;

                router.push('/panelInfos');
            },
            submit() {
                this.controlInputs();

                if (this.canGoNextPage) {
                    this.setSavePayload();

                    var payload = { envTemperature: this.envTemperature, maxTemperature: this.temperature, humidity: this.humidity, altitude: this.altitude };
                    this.$store.commit('setEnvConditionDatas', payload);

                    this.$store.isPageOpenedFromStepBar = false;
                    this.$store.canCalculationOpen = true;

                    if (this.$store.state.calcIndex == 3) {
                        router.push('/calc_Summary');
                    } else {
                        router.push('/calculation');
                    }
                }
            },
            enableManuelSave() {
                this.$store.state.canManuelSave = true;
            },
            setSavePayload() {
                var payload = { envTemperature: this.envTemperature, maxTemperature: this.temperature, humidity: this.humidity, altitude: this.altitude };
                this.$store.commit('setEnvConditionDatas', payload);
            },
            controlInputs() {
                if (this.envTemperature == 0) {
                    this.hasError_Ta = true;
                    this.emptyWarning_Ta = this.$lang.Menus.EmptyWarning;
                } else if (this.envTemperature < 10 || this.envTemperature > 50) {
                    this.hasError_Ta2 = true;
                } else if (this.temperature == 0) {
                    this.hasError_Tmax = true;
                    this.emptyWarning_Tmax = this.$lang.Menus.EmptyWarning;
                } else {
                    this.canGoNextPage = true;
                }

                if (this.envTemperature != 0) {
                    this.hasError_Ta = false;
                    this.emptyWarning_Ta = "";
                }
                if (this.envTemperature >= 10 && this.envTemperature <= 50) {
                    this.hasError_Ta2 = false;
                }
                if (this.temperature != 0) {
                    this.hasError_Tmax = false;
                    this.emptyWarning_Tmax = "";
                }
            },
            getMaximumPanelTemperatures() {
                var tempEnvTemperature = this.envTemperature + '';
                tempEnvTemperature = tempEnvTemperature.replace(/[^0-9]/g, '');
                this.envTemperature = tempEnvTemperature;

                if (this.envTemperature < 10 || this.envTemperature > 50) {
                    this.hasError_Ta2 = true;
                } else {
                    this.hasError_Ta2 = false;
                }

                if (this.envTemperature == 0) {
                    this.hasError_Ta = true;
                } else {
                    this.hasError_Ta = false;
                }

                this.temperaturelist = [];
                api.panels.getMaximumPanelTemperatures(this.envTemperature).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.temperaturelist.push(e);
                        });
                    }
                });
            },
            getAltitudes() {
                this.altitudelist = [];
                api.panels.getAltitudes().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.altitudelist.push(e);
                        });
                    }
                });
            },
            openInfo() {
                if (!this.isInfoOpened) {
                    this.isInfoOpened = true;
                } else {
                    this.isInfoOpened = false;
                }
            }
        },

    };
</script>

<style>

    @media only screen and (max-width: 623px) {
        .fillText {
            font-size: 14px !important;
        }
    }

    .envRow {
        display: flex;
    }

    @media screen and (max-width: 769px) {
        .rowItem1 {
            margin-right: 1.5rem;
        }

        .envTemp {
            width: 30% !important;
        }

        .envHumidity {
            width: 30% !important;
            padding-bottom: 10px;
        }
    }

    @media screen and (min-width: 1335px) {
    }

    .envImg {
        margin-right: auto;
        margin-left: auto;
    }
</style>
