<template>
    <div>
        <section class="section is-step-bar" v-if="isStepBarActive">
            <b-steps v-model="activeStep" @input="openNewPage()" :has-navigation="hasNavigation">
                <b-step-item step="1" :label="this.$lang.StepBar.ProjectInfos" :clickable="true">

                </b-step-item>

                <b-step-item step="2" :label="this.$lang.StepBar.PanelInfos" :clickable="canPanelInfosOpen">

                </b-step-item>

                <b-step-item step="3" :label="this.$lang.StepBar.EnvConds" :clickable="canEnvConditionsOpen">

                </b-step-item>

                <b-step-item :step="4" :label="this.$lang.StepBar.LostPower" :clickable="canCalculationOpen">

                </b-step-item>

                <b-step-item :step="4.1" label="4.1" :visible="areStepsVisible" :clickable="canCalculationOpen">

                </b-step-item>

                <b-step-item :step="4.2" label="4.2" :visible="areStepsVisible" :clickable="canCalculationOpen">

                </b-step-item>

                <b-step-item :step="4.3" label="4.3" :visible="areStepsVisible" :clickable="canCalculationOpen">

                </b-step-item>

                <b-step-item :step="5" :label="this.$lang.StepBar.Result" :clickable="canResultOpen">

                </b-step-item>

                <b-step-item :step="6" :label="this.$lang.StepBar.Report" :clickable="canReportPageOpen">

                </b-step-item>

                <b-step-item :step="7" :label="this.$lang.StepBar.FanCalc" :clickable="canFanCalcOpen">

                </b-step-item>

                <template v-if="customNavigation"
                          #navigation="{previous, next}">
                    <b-button outlined
                              type="is-danger"
                              icon-pack="fas"
                              icon-left="backward"
                              :disabled="previous.disabled"
                              @click.prevent="previous.action">
                        Previous
                    </b-button>
                    <b-button outlined
                              type="is-success"
                              icon-pack="fas"
                              icon-right="forward"
                              :disabled="next.disabled"
                              @click.prevent="next.action">
                        Next
                    </b-button>
                </template>

            </b-steps>
        </section>

        <nav-bar />
        <aside-menu :menu="menu" />
        <transition name="fade" mode="out-in">
            <slot />
        </transition>
        <footer-bar />
    </div>
</template>

<script>
    // import { computed } from '@vue/composition-api';
    // import { useStore } from '@/store';
    import Vue from 'vue';
    import router from '@/router';
    import NavBar from '@/components/NavBar.vue';
    import AsideMenu from '@/components/AsideMenu.vue';
    import FooterBar from '@/components/FooterBar.vue';

    export default {
        name: 'Home',
        components: {
            FooterBar,
            AsideMenu,
            NavBar
        },
        data() {
            return {
                isStepBarActive: false,
                hasNavigation: false,
                activeStep: 0,
                canPanelInfosOpen: false,
                canEnvConditionsOpen: false,
                canCalculationOpen: false,
                canResultOpen: false,
                canReportPageOpen: false,
                canFanCalcOpen: false,
                areStepsVisible: false
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            }
        },
        mounted() {
            this.setCurrentPage();
        },
        watch: {
            $route() {
                this.setCurrentPage();
            }
        },
        methods: {
            setCurrentPage() {
                if (!this.isPageOpenedFromStepBar) {
                    this.activeStep = this.$store.activeStep;
                }
                if (this.$route.name == "projectInfos" || this.$route.name == "panelInfos" || this.$route.name == "envConditions" || this.$route.name == "calculation" ||
                    this.$route.name == "calc_InputCircuit" || this.$route.name == "calc_OutputCircuit" || this.$route.name == "calc_Manuel" ||
                    this.$route.name == "results" || this.$route.name == "reportPage" || this.$route.name == "fan_Calc") {
                    this.isStepBarActive = true;                  
                } else if (this.$route.name == "mainMenu" || this.$route.name == "circuitDevices" || this.$route.name == "myProjects" || this.$route.name == "allProjects" || this.$route.name == "newProject" ||
                    this.$route.name == "addMember" || this.$route.name == "updateMember" || this.$route.name == "loginMember") {
                    this.isStepBarActive = false;                   
                }

                this.canPanelInfosOpen = this.$store.canPanelInfosOpen;
                this.canEnvConditionsOpen = this.$store.canEnvConditionsOpen;
                this.canCalculationOpen = this.$store.canCalculationOpen;
                this.canResultOpen = this.$store.canResultOpen;
                this.canReportPageOpen = this.$store.canReportPageOpen;
                this.canFanCalcOpen = this.$store.canFanCalcOpen;

                this.areStepsVisible = this.$store.state.areHiddenStepsVisible;
            },
            openNewPage() {   
                this.$store.activeStep = this.activeStep;
                this.$store.isPageOpenedFromStepBar = true;

                if (this.activeStep == 0) {
                    router.push('/ProjectInfos');
                } else if (this.activeStep == 1) {
                    router.push('/PanelInfos');
                } else if (this.activeStep == 2) {
                    router.push('/EnvConditions');
                } else if (this.activeStep == 3) {
                    router.push('/Calc_Busbar');
                } else if (this.activeStep == 4) {
                    router.push('/Calc_InputCircuit');
                } else if (this.activeStep == 5) {
                    router.push('/Calc_OutputCircuit');
                } else if (this.activeStep == 6) {
                    router.push('/Calc_Manuel');
                } else if (this.activeStep == 7) {
                    router.push('/Results');
                } else if (this.activeStep == 8) {
                    router.push('/ReportPdfPage');
                } else if (this.activeStep == 9) {
                    router.push('/Fan_Calc');
                }
            }
        },
        setup() {
            // const store = useStore();

            const parentMenu = [
                Vue.prototype.$lang.Menus.General + "|1",
                Vue.prototype.$lang.Menus.Project + "|2",
                Vue.prototype.$lang.Menus.Devices + "|3"
            ]
            const childrenMenu = [
                [
                    {
                        updateMark: false,
                        to: "/",
                        label: Vue.prototype.$lang.Menus.MainPage,
                        icon: "desktop-mac",
                        menuCode: "MainMenu",
                        id: 1,
                        parentMenuId: 1
                    }
                ],  
                [
                    {
                        updateMark: false,
                        to: "/NewProject",
                        label: Vue.prototype.$lang.Menus.NewProject,                     
                        icon: "desktop-mac",
                        menuCode: "NewProject",
                        id: 1,
                        parentMenuId: 2
                    },
                    {
                        updateMark: false,
                        to: "/MyProjects",
                        label: Vue.prototype.$lang.Menus.MyProjects,                        
                        icon: "link",
                        menuCode: "MyProjects",
                        id: 2,
                        parentMenuId: 2
                    },
                    {
                        updateMark: false,
                        to: "/AllProjects",
                        label: Vue.prototype.$lang.Menus.AllProjects,
                        icon: "link",
                        menuCode: "AllProjects",
                        id: 3,
                        parentMenuId: 2
                    }
                ],
                [
                    {
                        updateMark: false,
                        to: "/CircuitDevices",
                        label: Vue.prototype.$lang.Menus.CircDevices,
                        icon: "desktop-mac",
                        menuCode: "CircuitDevices",
                        id: 1,
                        parentMenuId: 3
                    }
                ],
                [
                    {
                        updateMark: false,
                        to: "/AddMember",
                        label: Vue.prototype.$lang.Menus.Users,
                        icon: "desktop-mac",
                        menuCode: "AddMember",
                        id: 1,
                        parentMenuId: 4
                    },
                    {
                        updateMark: false,
                        to: "/UpdateMember",
                        label: Vue.prototype.$lang.Menus.Users,
                        icon: "desktop-mac",
                        menuCode: "UpdateMember",
                        id: 1,
                        parentMenuId: 4
                    }
                ]
            ]

            const newMenu = [];
            parentMenu.forEach((e) => {
                newMenu.push({ label: e.split('|')[0], parentMenuId: +e.split('|')[1], icon: '', menu: [] });
            });

            for (const parent of newMenu) {
                childrenMenu.forEach((e) => {
                    e.forEach((e) => {
                        if (e.parentMenuId === parent.parentMenuId) {
                            parent.menu.push(e);
                        }
                    });
                });
            }

            const menu = [];
            for (var i = 0; i < newMenu.length; i++) {
                menu.push(newMenu[i]);
            }
            return {
                menu
            };
        }
    };
</script>
