<template>
    <div>
        <hero-bar :has-right-visible="true" menu-icon="desktop-mac"> {{this.$lang.Member.Update}} 
        
            <p v-if="this.backBtnActive == true" class="control" slot="right" style="margin-right: 16px">
                <b-button class="button is-gray" @click="previous">{{this.$lang.Menus.BackBtn}}</b-button>
            </p>
        </hero-bar>

        <div id="app" class="section is-main-section">
            <div class="columns">
                <div class="column is-4">
                    <b-field horizontal :label="this.$lang.Member.Name" :type="{ 'is-danger': hasError_memberName }" :message="this.emptyWarning_memberName">
                        <b-input v-model="memberName" maxlength="40" style="width: 65%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Surname" :type="{ 'is-danger': hasError_memberSurname }" :message="this.emptyWarning_memberSurname">
                        <b-input v-model="memberSurname" maxlength="40" style="width: 65%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Email">
                        <b-input v-model="email" type="email" maxlength="30" style="width: 65%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Password" :type="{ 'is-danger': hasError_password }" :message="this.emptyWarning_password">
                        <b-input v-model="password" type="password" @input="password_check" password-reveal style="width: 65%;"></b-input>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : password.length > 7}"><i class="frmIcon fas" :class="password.length > 7 ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_1}}</p>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' :has_uppercase }"><i class="frmIcon fas" :class="has_uppercase ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_2}}</p>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' :has_lowercase }"><i class="frmIcon fas" :class="has_lowercase ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_3}}</p>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : has_number }"><i class="frmIcon fas" :class="has_number ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_4}}</p>
                    </b-field>
                    <b-field v-if="isSaveBtnClicked" style="padding-left:8.5rem">
                        <p class="frmValidation" :class="{'frmValidation--passed' : has_special }"><i class="frmIcon fas" :class="has_special ? 'fa-check' : 'fa-times'"></i> {{this.$lang.Password.Password_5}}</p>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Password_2" :type="{ 'is-danger': hasError_password_2 }" :message="this.emptyWarning_password_2">
                        <b-input v-model="password_2" type="password" password-reveal style="width: 65%;"></b-input>
                    </b-field>
                </div>

                <div class="column is-4">
                    <b-field horizontal :label="this.$lang.Member.Title" :type="{ 'is-danger': hasError_title }" :message="this.emptyWarning_title">
                        <b-input v-model="title" maxlength="80" style="width: 65%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Country">
                        <b-select v-if="countryNameList" v-model="country" @input="getCityList()">
                            <option v-for="option in countryNameList"
                                    :value="option"
                                    :key="option.id">
                                {{ option }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field horizontal v-if="isCityListVisible" :label="this.$lang.Member.City" :type="{ 'is-danger': hasError_city }" :message="this.emptyWarning_city">
                        <b-select v-if="cityNameList" v-model="city" @input="setCityId()">
                            <option v-for="option in cityNameList"
                                    :value="option"
                                    :key="option.id">
                                {{ option }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Town" :type="{ 'is-danger': hasError_town }" :message="this.emptyWarning_town">
                        <b-input v-model="town" maxlength="40" style="width: 65%;"></b-input>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.Phone" :type="{ 'is-danger': hasError_phone }" :message="this.emptyWarning_phone">
                        <div class="field is-grouped">
                            <p class="control" v-if="!this.phonePrefixDisabled">
                                <span class="button is-static">+905</span>
                            </p>
                            <b-input v-model="phone" :maxlength="this.phoneLength" @input="controlPhoneInput()" style="width: 40%;"></b-input>
                        </div>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.PhoneBusiness" :type="{ 'is-danger': hasError_phoneBusiness }" :message="this.emptyWarning_phoneBusiness">
                        <div class="field is-grouped">
                            <p class="control" v-if="!this.phonePrefixDisabled">
                                <span class="button is-static">+90</span>
                            </p>
                            <b-input v-model="phoneBusiness" :maxlength="this.phone_2Length" @input="controlPhoneInput()" style="width: 40%;"></b-input>
                        </div>
                    </b-field>

                    <b-field horizontal :label="this.$lang.Member.CommLanguage" :type="{ 'is-danger': hasError_commLanguage }" :message="this.emptyWarning_commLanguage">
                        <b-select v-if="langNameList" v-model="commLanguage" @input="setWarnings()" disabled>
                            <option v-for="option in langNameList"
                                    :value="option"
                                    :key="option.id">
                                {{ option }}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field>
                        <p class="control">
                            <b-button class="button is-primary" @click="updateMember">{{this.$lang.Member.UpdateBtn}}</b-button>
                        </p>
                    </b-field>
                </div>

            </div>

        </div>
    </div>
</template>

<script type="text/javascript" src="path/to/zxcvbn.js"></script>
<script>
    import router from '@/router';
    import api from '@/api';

    export default {
        name: 'ProjectInfos',
        data() {
            return {
                isActive: true,
                backBtnActive: false,
                isSaveBtnClicked: false,
                canMemberSave: false,
                isWarningsVisible: false,
                memberName: '',
                memberSurname: '',
                email: '',
                password: '',
                password_2: '',
                has_number: false,
                has_lowercase: false,
                has_uppercase: false,
                has_special: false,
                title: '',
                country: '',
                countryId: '',
                countryNameList: [],
                countryList: [],
                city: '',
                cityId: '',
                cityNameList: [],
                cityList: [],
                isCityListVisible: false,
                town: '',
                phone: '',
                phoneBusiness: '',
                langList: [],
                langId: '',
                langNameList: [],
                commLanguage: '',
                phoneLength: 9,
                phone_2Length: 10,
                phonePrefixDisabled: false,
                hasError_memberName: false,
                emptyWarning_memberName: '',
                hasError_memberSurname: false,
                emptyWarning_memberSurname: '',
                hasError_email: false,
                hasError_password: false,
                emptyWarning_password: '',
                hasError_password_2: false,
                emptyWarning_password_2: '',
                hasError_title: false,
                emptyWarning_title: '',
                hasError_country: false,
                emptyWarning_country: '',
                hasError_city: false,
                emptyWarning_city: '',
                hasError_town: false,
                emptyWarning_town: '',
                hasError_phone: false,
                emptyWarning_phone: '',
                hasError_phoneBusiness: false,
                emptyWarning_phoneBusiness: '',
                hasError_commLanguage: false,
                emptyWarning_commLanguage: '',
            }
        },
        mounted() {
            if (this.$store.state.previousRoute == 'projectInfos' || this.$store.state.previousRoute == 'panelInfos' || this.$store.state.previousRoute == 'envConditions' ||
                this.$store.state.previousRoute == 'calc_Busbar' || this.$store.state.previousRoute == 'calc_InputCircuit' || this.$store.state.previousRoute == 'calc_OutputCircuit' ||
                this.$store.state.previousRoute == 'calc_Manuel' || this.$store.state.previousRoute == 'calc_Summary' || this.$store.state.previousRoute == 'results' ||
                this.$store.state.previousRoute == 'reportPdfPage' || this.$store.state.previousRoute == 'calculation') {
                this.backBtnActive = true;
            } else {
                this.backBtnActive = false;
            }

            this.$store.state.isFooterFixed = true;

            this.getMember();
        },
        destroyed() {
            this.$store.state.isFooterFixed = false;
        },
        methods: {
            password_check() {
                this.has_number = /\d/.test(this.password);
                this.has_lowercase = /[a-z]/.test(this.password);
                this.has_uppercase = /[A-Z]/.test(this.password);
                this.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password);
            },

            getMember() {
                api.members.getMember_2(this.$store.state.memberId).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        this.memberName = res.data.Name;
                        this.memberSurname = res.data.SurName;
                        this.email = res.data.Email;
                        this.password = res.data.Passwordd;
                        this.password_2 = this.password;
                        this.title = res.data.Title;
                        this.country = res.data.Country;
                        this.city = res.data.City;
                        this.town = res.data.Town;

                        api.members.getCommLanguages().then((res2) => {
                            if (res2.isError) {
                                console.log("error ");
                            } else {
                                res2.data.forEach((e) => {
                                    this.langNameList.push(e.name);
                                    this.langList.push(e);
                                });
                                
                                var langIndex = this.langNameList.indexOf(this.commLanguage);
                                this.langId = this.langList[langIndex].id;
                            }
                        });

                        if (res.data.Country == 'Türkiye') {
                            this.isCityListVisible = true;
                        }
                        if (this.$lang.Format.Lang == 'tr-TR') {
                            this.commLanguage = 'Türkçe';
                            this.isWarningsVisible = true;
                        } else {
                            this.commLanguage = 'İngilizce';
                        }

                        var num1 = "";
                        num1 = res.data.Phone;
                        this.phone = num1.substring(4);

                        var num2 = "";
                        num2 = res.data.PhoneBusiness;
                        this.phoneBusiness = num2.substring(3);
                        
                        this.getCountryList();
                    }
                });
            },

            showWarning(type) {
                var _message = '';
                if (type == 'Email') {
                    _message = this.$lang.Member.EmailError;
                } else if (type == 'Phone') {
                    _message = this.$lang.Member.PhoneError;
                }

                this.$buefy.dialog.confirm({
                    title: this.$lang.Member.ErrorTitle,
                    message: _message,
                    confirmText: this.$lang.Member.Confirm_2,
                    type: 'is-warning',
                    hasIcon: true
                })
            },
            updateMember() {
                this.isSaveBtnClicked = true;
                this.password_check();
                this.controlInputs();

                if (this.canMemberSave) {
                    const member = {
                        Id: this.$store.state.memberId,
                        Name: this.memberName,
                        SurName: this.memberSurname,
                        Email: this.email,
                        Passwordd: this.password,
                        Title: this.title,
                        Country: this.country,
                        CountryId: this.countryId,
                        City: this.city,
                        CityId: this.cityId,
                        Town: this.town,
                        Phone: '+905' + this.phone,
                        PhoneBusiness: '+90' + this.phoneBusiness,
                        CommLanguage: this.commLanguage,
                        CommLangId: this.langId
                    };

                    api.members.encryptMember(member).then((resCrypto) => {
                        if (resCrypto.isError) {
                            console.log("error ");
                        } else {
                            api.members.IsMailUnique_ForUpdate(this.$store.state.memberId, resCrypto.data.Email).then((retMail) => {
                                if (retMail.isError) {
                                    console.log("error ");
                                } else {
                                    if (retMail.data == false) {
                                        this.showWarning('Email');
                                    } else {
                                        api.members.IsPhoneNumberUnique_ForUpdate(this.$store.state.memberId, resCrypto.data.PhoneBusiness).then((retPhone) => {
                                            if (retPhone.isError) {
                                                console.log("error ");
                                            } else {
                                                if (retPhone.data == false) {
                                                    this.showWarning('Phone');
                                                } else {
                                                    const memberEncrypted = {
                                                        Id: this.$store.state.memberId,
                                                        CrmMemberId: resCrypto.data.CrmMemberId,
                                                        Name: resCrypto.data.Name,
                                                        SurName: resCrypto.data.SurName,
                                                        Email: resCrypto.data.Email,
                                                        Passwordd: resCrypto.data.Passwordd,
                                                        Title: resCrypto.data.Title,
                                                        Country: resCrypto.data.Country,
                                                        CountryId: resCrypto.data.CountryId,
                                                        City: resCrypto.data.City,
                                                        CityId: resCrypto.data.CityId,
                                                        Town: resCrypto.data.Town,
                                                        Phone: resCrypto.data.Phone,
                                                        PhoneBusiness: resCrypto.data.PhoneBusiness,
                                                        CommLanguage: resCrypto.data.CommLanguage,
                                                        CommLangId: resCrypto.data.CommLangId
                                                    };

                                                    api.members.updateMember(memberEncrypted).then((res) => {
                                                        if (res.isError) {
                                                            console.log("error ");
                                                        } else {
                                                            this.$buefy.toast.open(this.$lang.Member.MemberUpdated);
                                                        }
                                                    });
                                                }
                                            }
                                        });
                                    }                                  
                                }
                            });
                        }
                    });                   
                }
            },
            getCountryList() {
                this.countryNameList = [];
                this.countryList = [];
                api.members.getCountries().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.countryNameList.push(e.name);
                            this.countryList.push(e);
                        });

                        if (this.country != null) {
                            var countryIndex = this.countryNameList.indexOf(this.country);
                            this.countryId = this.countryList[countryIndex].id;
                        }
                        
                        this.getCityList();
                    }
                });
            },
            getCityList() {
                if (this.country.localeCompare("Türkiye") == 0) {
                    this.isCityListVisible = true;
                } else {
                    this.isCityListVisible = false;
                    return;
                }

                var countryIndex = this.countryNameList.indexOf(this.country);
                this.countryId = this.countryList[countryIndex].id;
                this.cityNameList = [];
                this.cityList = [];
                api.members.getCities(this.countryList[countryIndex].id).then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.cityNameList.push(e.name);
                            this.cityList.push(e);
                        });

                        this.setCityId();
                        this.getLangList(); 
                    }
                });
            },
            setCityId() {
                var cityIndex = this.cityNameList.indexOf(this.city);
                this.cityId = this.cityList[cityIndex].id;
            },
            getLangList() {
                this.langNameList = [];
                this.langList = [];
                api.members.getCommLanguages().then((res) => {
                    if (res.isError) {
                        console.log("error ");
                    } else {
                        res.data.forEach((e) => {
                            this.langNameList.push(e.name);
                            this.langList.push(e);
                        });

                        var langIndex = this.langNameList.indexOf(this.commLanguage);
                        this.langId = this.langList[langIndex].id; 
                    }
                });
            },

            setWarnings() {
                var langIndex = this.langNameList.indexOf(this.commLanguage);
                this.langId = this.langList[langIndex].id;  

                if (this.commLanguage == 'Türkçe') {
                    this.isWarningsVisible = true;
                    this.phonePrefixDisabled = false;
                    this.phoneLength = 9;
                    this.phone_2Length = 10;
                    this.country = 'Türkiye';
                } else {
                    this.isCityListVisible = false;
                    this.isWarningsVisible = false;
                    this.phonePrefixDisabled = true;
                    this.phoneLength = 15;
                    this.phone_2Length = 15;
                    this.country = '';
                }
            },
            controlPhoneInput() {
                for (let i = 0; i < this.phone.length; i++) {
                    if (this.phone.charCodeAt(i) < 48 || this.phone.charCodeAt(i) > 57) {
                        this.phone = this.phone.replace(this.phone[i], '');
                        return;
                    }
                }

                for (let i = 0; i < this.phoneBusiness.length; i++) {
                    if (this.phoneBusiness.charCodeAt(i) < 48 || this.phoneBusiness.charCodeAt(i) > 57) {
                        this.phoneBusiness = this.phoneBusiness.replace(this.phoneBusiness[i], '');
                        return;
                    }
                }
            },
            isPhoneNumberValid(num, buss) {
                if (!buss) {
                    if (num.length != 9) {
                        return false;
                    }
                } else {
                    if (num.length != 10) {
                        return false;
                    }
                }

                for (let i = 0; i < num.length; i++) {
                    if (num.charCodeAt(i) < 48 || num.charCodeAt(i) > 57) {
                        return false;
                    }
                }
                return true;
            },

            controlPassword() {
                if (this.password.length <= 7 || !this.has_number || !this.has_lowercase || !this.has_uppercase || !this.has_special) {
                    this.hasError_password = true;
                    this.canMemberSave = false;
                } else {
                    this.hasError_password = false;

                    if (this.password_2 != this.password) {
                        this.hasError_password_2 = true;
                        this.canMemberSave = false;
                    } else {
                        this.hasError_password_2 = false;
                        this.canMemberSave = true;
                    }
                }
            },
            controlInputs() {
                if (this.memberName == '') {
                    this.hasError_memberName = true;
                    this.emptyWarning_memberName = this.$lang.Menus.EmptyWarning;
                } else if (this.memberSurname == '') {
                    this.hasError_memberSurname = true;
                    this.emptyWarning_memberSurname = this.$lang.Menus.EmptyWarning;
                } else if (this.email == '') {
                    this.hasError_email = true;
                } else if (this.password == '') {
                    this.hasError_password = true;
                    this.emptyWarning_password = this.$lang.Menus.EmptyWarning;
                } else if (this.password_2 == '') {
                    this.hasError_password_2 = true;
                    this.emptyWarning_password_2 = this.$lang.Menus.EmptyWarning;
                } else if (this.title == '') {
                    this.hasError_title = true;
                    this.emptyWarning_title = this.$lang.Menus.EmptyWarning;
                } else if (this.city == '' && this.isCityListVisible) {
                    this.hasError_city = true;
                    this.emptyWarning_city = this.$lang.Menus.EmptyWarning;
                } else if (this.town == '') {
                    this.hasError_town = true;
                    this.emptyWarning_town = this.$lang.Menus.EmptyWarning;
                } else if (!this.isPhoneNumberValid(this.phone, false) && this.phone != '') {
                    this.hasError_phone = true;
                    this.emptyWarning_phone = this.$lang.Menus.EmptyWarning;
                } else if (!this.isPhoneNumberValid(this.phoneBusiness, true)) {
                    this.hasError_phoneBusiness = true;
                    this.emptyWarning_phoneBusiness = this.$lang.Menus.EmptyWarning;
                } else if (this.commLanguage == '') {
                    this.hasError_commLanguage = true;
                    this.emptyWarning_commLanguage = this.$lang.Menus.EmptyWarning;
                } else if (this.isAccepted_1 == false) {
                    this.hasError_isAccepted_1 = true;
                    this.emptyWarning_isAccepted_1 = this.$lang.Menus.EmptyWarning;
                } else if (this.isAccepted_2 == false) {
                    this.hasError_isAccepted_2 = true;
                    this.emptyWarning_isAccepted_2 = this.$lang.Menus.EmptyWarning;
                } else {
                    this.controlPassword();
                    if (this.password == this.password_2) {
                        this.hasError_password_2 = false;
                        this.canMemberSave = true;
                    } else {
                        this.hasError_password_2 = true;
                        this.emptyWarning_password_2 = this.$lang.Menus.EmptyWarning;
                    }
                }

                if (this.memberName != '') {
                    this.hasError_memberName = false;
                }
                if (this.memberSurname != '') {
                    this.hasError_memberSurname = false;
                }
                if (this.email != '') {
                    this.hasError_email = false;
                }
                if (this.password != '') {
                    this.hasError_password = false;
                }
                if (this.title != '') {
                    this.hasError_title = false;
                }
                if (this.city != '') {
                    this.hasError_city = false;
                }
                if (this.town != '') {
                    this.hasError_town = false;
                }
                if (this.isPhoneNumberValid(this.phone, false)) {
                    this.hasError_phone = false;
                }
                if (this.isPhoneNumberValid(this.phoneBusiness, true)) {
                    this.hasError_phoneBusiness = false;
                }
                if (this.commLanguage != '') {
                    this.hasError_commLanguage = false;
                }

                if (this.isAccepted_1 == false) {
                    this.hasError_isAccepted_1 = true;
                }
                if (this.isAccepted_1 == true) {
                    this.hasError_isAccepted_1 = false;
                }
                if (this.isAccepted_2 == false) {
                    this.hasError_isAccepted_2 = true;
                }
                if (this.isAccepted_2 == true) {
                    this.hasError_isAccepted_2 = false;
                }

                this.controlPassword();
            },
            previous() {
                router.push('/' + this.$store.state.previousRoute);
            }
        }
    };
</script>

<style>
    .container {
        width: 400px;
        margin: 50px auto;
        background: white;
        padding: 10px;
        font-family: Arial, Helvetica, sans-serif, sans-serif;
        border-radius: 3px;
    }

    h1 {
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
    }

    .frmField {
        ;
        color: #495057;
        line-height: 1.25;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
        border: 0;
        padding: 10px;
        border: 1px solid #dbdbdb;
        border-radius: 3px;
        width: 90%;
    }

    .frmLabel {
        display: block;
        margin-bottom: 10px;
        font-weight: bold;
    }

    .frmValidation {
        font-size: 13px;
    }

    .frmValidation--passed {
        color: #8fce00;
    }

    .frmIcon {
        color: #EB0029;
    }

    .frmValidation--passed .frmIcon {
        color: #8fce00;
    }

    .howToBuild {
        text-align: center;
        color: purple;
    }

        .howToBuild a {
            color: grey;
            font-weight: bold;
            text-decoration: none;
            text-transform: uppercase;
        }
</style>